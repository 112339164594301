import React, { useId } from 'react'
import {
  Select as MaterialSelect,
  MenuItem,
  SelectProps as SelectMaterialProps,
  styled
} from '@mui/material'
import { StyledExpandMore, StyledInputLabel } from 'components'
import { UseFormRegisterReturn } from 'react-hook-form'

export interface option {
  label: string | React.ReactElement
  value: string | number
}

interface SelectProps {
  options: option[]
  register?: UseFormRegisterReturn
  inputlabel?: string
}

const options = {
  shouldForwardProp: (prop) => prop !== 'inputlabel'
}
const StyledMaterialSelect = styled(
  MaterialSelect,
  options
)(() => ({
  '& .MuiInput-input:focus': {
    outline: 'none',
    backgroundColor: 'transparent'
  }
}))

export const Select = ({ options, ...props }: SelectProps & SelectMaterialProps) => {
  const id = useId()

  return (
    <>
      <StyledInputLabel sx={{ fontSize: 14 }} htmlFor={id}>
        {props.inputlabel}
        {props.required && '*'}
      </StyledInputLabel>
      <StyledMaterialSelect
        variant="standard"
        IconComponent={() => <StyledExpandMore />}
        {...props}
        {...props.register}>
        {options.map((opt, i) => (
          <MenuItem key={i} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </StyledMaterialSelect>
    </>
  )
}
