import React, { useState } from 'react'
import {
  Container,
  Loading,
  NoDataFoundMessage,
  Table,
  TableContainer,
  UnexpectedErrorMessage
} from 'components'
import { Box, Chip } from '@mui/material'
import theme from 'theme'
import { Dealer, useGetDealers, getSessionUser } from 'hooks'
import { EnrollmentSummaryFilters } from './Filters'
import { ROLES } from 'constants/Roles'
import { renderRegion, valueGetter } from 'utils'

const dealerStatusColumn = (params) => {
  const isEnrolled = params?.row?.dls?.isEnrolled
  return (
    <Chip
      sx={{ borderRadius: 1, py: 0 }}
      label={isEnrolled ? 'Active' : 'Inactive'}
      color={isEnrolled ? 'success' : 'error'}
    />
  )
}

const renderEnrollmentDate = (params) => {
  const enrollmentDate = params?.row?.dls?.enrollmentDate
  return enrollmentDate ? new Date(enrollmentDate).toLocaleDateString() : '-'
}

const adminDealersSummaryColumns = [
  {
    flex: 1,
    field: 'dls.isEnrolled',
    valueGetter,
    headerName: 'Status',
    headerClassName: 'dealersHeaderRow',
    renderCell: dealerStatusColumn
  },
  {
    flex: 2,
    field: 'name',
    valueGetter,
    headerName: 'Dealer Name',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'id',
    valueGetter,
    headerName: 'Dealer Code',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'divisionCode',
    valueGetter,
    headerName: 'Region',
    headerClassName: 'dealersHeaderRow',
    renderCell: (params) => renderRegion(params.value)
  },
  {
    flex: 1,
    field: 'districtID',
    valueGetter,
    headerName: 'District',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'dls.enrollmentDate',
    valueGetter,
    headerName: 'Enrollment Date',
    headerClassName: 'dealersHeaderRow',
    renderCell: renderEnrollmentDate
  }
]

const managerDealersSummaryColumns = [
  {
    flex: 0.5,
    field: 'dls.isEnrolled',
    valueGetter,
    headerName: 'Status',
    headerClassName: 'dealersHeaderRow',
    renderCell: dealerStatusColumn
  },
  {
    flex: 1,
    field: 'name',
    valueGetter,
    headerName: 'Dealer Name',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'id',
    valueGetter,
    headerName: 'Dealer Code',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'dls.enrollmentDate',
    valueGetter,
    headerName: 'Enrollment Date',
    headerClassName: 'dealersHeaderRow',
    renderCell: renderEnrollmentDate
  }
]

export function DealersEnrollment() {
  const user = getSessionUser()
  const [filteredData, setFilteredData] = useState<Dealer[]>()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [selectedRows, setSelectedRows] = useState<Dealer[]>([])

  const { data, isLoading, isError } = useGetDealers({
    pageSize: 0,
    region: user?.role === ROLES.MANAGER ? user?.region?.toString() : null
  })

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    setSelectedRows(data.foi.filter((row) => newRowSelectionModel.includes(row.id)))
  }

  function displayColumns() {
    if (user?.role == ROLES.ADMIN) {
      return adminDealersSummaryColumns
    } else if (user?.role == ROLES.MANAGER) {
      return managerDealersSummaryColumns
    } else {
      return []
    }
  }

  return (
    <Container fullWidth title="Enrollment Summary">
      <TableContainer>
        <EnrollmentSummaryFilters
          selectedRows={selectedRows}
          setFilteredData={setFilteredData}
          data={data?.foi}
          disabled={isLoading}
        />

        {isLoading && !data && <Loading />}
        {!isLoading && !data && !isError && <NoDataFoundMessage />}
        {!isLoading && data && (
          <Box sx={{ border: `1px solid ${theme.palette.primary.light}` }}>
            <Table
              onRowSelectionModelChange={handleRowSelectionModelChange}
              isLoading={isLoading}
              rowSelectionModel={rowSelectionModel}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 }
                }
              }}
              columns={displayColumns()}
              rows={filteredData ? filteredData : data?.foi}
            />
          </Box>
        )}
        {!isLoading && isError && <UnexpectedErrorMessage />}
      </TableContainer>
    </Container>
  )
}
