import { AccessTimeFilled, CancelRounded, CheckCircle, LockClockRounded } from '@mui/icons-material'
import { Box, LinearProgress, Typography } from '@mui/material'
import { RequestStatus } from 'hooks'
import React from 'react'
import { formatTime } from 'utils'

function progressBarValue(
  sentStatus: RequestStatus,
  managerStatus: RequestStatus,
  adminStatus: RequestStatus
) {
  if (adminStatus) return 100
  if (managerStatus && !adminStatus) return 50
  return 25
}

function renderStatusIcons(managerStatus: string, adminStatus: string) {
  return (
    <>
      <CheckCircle />
      {managerStatus ? (
        managerStatus === 'managerApproved' ? (
          <CheckCircle />
        ) : (
          <CancelRounded />
        )
      ) : (
        <AccessTimeFilled />
      )}
      {adminStatus ? (
        adminStatus === 'adminApproved' ? (
          <CheckCircle />
        ) : (
          <CancelRounded />
        )
      ) : managerStatus === 'managerDenied' ? (
        <CancelRounded />
      ) : !managerStatus ? (
        <LockClockRounded />
      ) : (
        <AccessTimeFilled />
      )}
    </>
  )
}

function renderStatusLabels(
  sentStatus: RequestStatus,
  managerStatus: RequestStatus,
  adminStatus: RequestStatus
) {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }} width="100%">
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="body1" fontWeight="bold">
          Request Sent
        </Typography>
        <Typography variant="body2">Sent on {formatTime(sentStatus.statusTime)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          {!managerStatus
            ? 'Manager Reviewing'
            : managerStatus.status === 'managerApproved'
            ? 'Manager Approved'
            : 'Manager Denied'}
        </Typography>
        <Typography variant="body2">
          {!managerStatus
            ? 'Waiting Review'
            : managerStatus.status === 'managerApproved'
            ? `Approved on ${formatTime(managerStatus.statusTime)}`
            : `Denied on ${formatTime(managerStatus.statusTime)}`}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="end">
        <Typography variant="body1" fontWeight="bold">
          {!adminStatus
            ? !managerStatus
              ? 'Admin Waiting'
              : managerStatus.status === 'managerDenied'
              ? 'Admin Blocked'
              : 'Admin Reviewing'
            : adminStatus.status === 'adminApproved'
            ? 'Admin Approved'
            : 'Admin Denied'}
        </Typography>
        <Typography variant="body2">
          {!adminStatus
            ? !managerStatus
              ? 'Waiting Manager Review'
              : managerStatus.status === 'managerDenied'
              ? 'Admin blocked by Manager denial'
              : 'Waiting Review'
            : adminStatus.status === 'adminApproved'
            ? `Approved on ${formatTime(adminStatus.statusTime)}`
            : `Denied on ${formatTime(adminStatus.statusTime)}`}
        </Typography>
      </Box>
    </Box>
  )
}

interface StatusProp {
  statuses: RequestStatus[]
}

export function LinearReview({ statuses }: StatusProp) {
  const sentStatus = statuses.find(({ status }) => status === 'pending')

  const managerStatus = statuses.find(
    ({ status }) => status === 'managerApproved' || status === 'managerDenied'
  )

  const adminStatus = statuses.find(
    ({ status }) => status === 'adminApproved' || status === 'adminDenied'
  )

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" alignItems="end" gap={1}>
        <Typography variant="h6">Review Workflow:</Typography>
        <Typography variant="body1" fontSize={18}>
          The request is successful after getting Admin approval.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" justifyContent="space-between" width="100%">
          {renderStatusIcons(managerStatus?.status, adminStatus?.status)}
        </Box>
        <LinearProgress
          sx={{ height: 7, borderRadius: 2 }}
          color="inherit"
          variant="determinate"
          value={progressBarValue(sentStatus, managerStatus, adminStatus)}
        />
        <Box>{renderStatusLabels(sentStatus, managerStatus, adminStatus)}</Box>
      </Box>
    </Box>
  )
}
