import { useQuery } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { GetDealersResponse } from './GetDealersResponse'

interface GetDealers {
  region?: string
  district?: string
  dealerName?: string
  dealerCode?: string
  page?: number
  pageSize?: number
}

export function useGetDealers(params?: GetDealers) {
  const client = useClient()

  if (!params?.region) delete params.region
  if (!params?.district) delete params.district
  if (!params?.dealerName) delete params.dealerName
  if (!params?.dealerCode) delete params.dealerCode
  if (!params?.page) delete params.page

  return useQuery(['dealers', { params }], async () => {
    try {
      return (await client.get('/dealers', { params })) as GetDealersResponse
    } catch (error) {
      throw new Error(error)
    }
  })
}
