import React from 'react'
import { useTheme } from '@mui/material'
import { TimePicker as XTimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker'
import { UseFormRegisterReturn } from 'react-hook-form'
import { TimeField } from './TimeField'

interface InputLabelProps {
  inputLabel?: string
  required?: boolean
  register?: UseFormRegisterReturn
  placeholder?: string
  helperText?: string
}
interface TimeFieldInputProps {
  ownerState?: InputLabelProps
}

const CustomTimeField = (props: TimeFieldInputProps & TimePickerProps<unknown>) => (
  <TimeField
    inputLabel={props.ownerState?.inputLabel}
    required={props.ownerState?.required}
    register={props.ownerState?.register}
    placeholder={props.ownerState?.placeholder || 'Time'}
    helperText={props.ownerState?.helperText || 'Fill using AM/PM clock'}
    {...props}
  />
)

export const TimePicker = (props: InputLabelProps & TimePickerProps<unknown>) => {
  const theme = useTheme()
  return (
    <XTimePicker
      {...props}
      slots={{ field: CustomTimeField, ...props }}
      sx={{ svg: { color: theme.palette.primary.contrastText } }}
    />
  )
}
