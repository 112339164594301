import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Typography
} from '@mui/material'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import theme from 'theme'
import { ROUTE_PATHS } from 'constants/Routing'

export function SubmitRequestDropdown() {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleExportClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }
  return (
    <Stack direction="row" spacing={2}>
      <div style={{ width: '100%' }}>
        <Button
          ref={anchorRef}
          id="composition-button"
          variant="contained"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          sx={{
            py: 1,
            borderRadius: 0,
            margin: 0,
            backgroundColor: theme.palette.secondary.dark,
            color: 'white'
          }}
          endIcon={open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          onClick={handleToggle}>
          SUBMIT A REQUEST
        </Button>
        <Popper
          sx={{ width: 200, zIndex: 1000 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top'
              }}>
              <Box
                sx={{
                  backgroundColor: 'white',
                  border: `1px solid ${theme.palette.secondary.dark}`
                }}>
                <ClickAwayListener onClickAway={handleExportClose}>
                  <MenuList
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}>
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      to={ROUTE_PATHS.SUBSIDY_SUBMIT + '?type=addition'}>
                      <MenuItem>
                        <Typography variant="body1">Add new vehicle</Typography>
                      </MenuItem>
                    </NavLink>
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      to={ROUTE_PATHS.SUBSIDY_SUBMIT + '?type=replacement'}>
                      <MenuItem>
                        <Typography variant="body1">Replace vehicle</Typography>
                      </MenuItem>
                    </NavLink>
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      to={ROUTE_PATHS.SUBSIDY_SUBMIT + '?type=renewal'}>
                      <MenuItem>
                        <Typography variant="body1">Renew vehicle</Typography>
                      </MenuItem>
                    </NavLink>
                    <NavLink
                      style={{ textDecoration: 'none' }}
                      to={ROUTE_PATHS.SUBSIDY_SUBMIT + '?type=removal'}>
                      <MenuItem>
                        <Typography variant="body1">Remove vehicle</Typography>
                      </MenuItem>
                    </NavLink>
                  </MenuList>
                </ClickAwayListener>
              </Box>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}
