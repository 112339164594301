import React, { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { Breadcrumb, Container, Select, TableContainer } from 'components'
import { ROUTE_PATHS } from 'constants/Routing'
import theme from 'theme'

import { blobUrlToBase64 } from 'utils'
import { VehicleForm, validateVehicle } from 'pages/SubsidyProgram/Vehicles/VehicleForm'
import { ArrowForwardIosOutlined, ErrorOutline } from '@mui/icons-material'
import { Vehicle, getSessionUser, useGetDealers, useGetVehicles } from 'hooks'
import { useSubmitRequest } from 'hooks/useSubmitRequest'
import { Vehicle as CreateVehicle } from 'hooks/useSubmitRequest/SubmitRequestRequest'
import { VehiclePhotoDialog } from 'pages/SubsidyProgram/Vehicles/VehiclePhotoDialog'
import { format } from 'date-fns'
import { ErrorDialog } from 'pages/Reports/ErrorDialog'

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 1,
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.dark}`
    }
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.primary.contrastText
  }
}))

export function SubmitRenewal() {
  const user = getSessionUser()
  const history = useHistory()
  const [openImage, setOpenImage] = useState(false)
  const [vehicleToRenew, setVehicleToRenew] = useState<Vehicle | null>(null)
  const [renewedVehicle, setRenewedVehicle] = useState<CreateVehicle | null>(null)
  const [step, setStep] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)
  const [comments, setComments] = useState('')
  const { data } = useGetDealers({ dealerCode: user?.dealerCode.toString() })
  const dealer = data?.foi[0]
  const { data: vehicles } = useGetVehicles({
    active: true,
    dealerCode: user?.dealerCode.toString()
  })
  const { mutateAsync: submitRequest, isLoading } = useSubmitRequest()
  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))

  const vehicleOptions = vehicles?.data
    ? vehicles?.data.length
      ? vehicles?.data?.map((car) => ({
          label: `${car.make} ${car.model}, ${car.year}  `,
          value: car.id
        }))
      : [{ label: 'No vehicles found.', value: null }]
    : [{ label: 'Loading...', value: null }]

  async function handleVehicleRenewal() {
    const valid = validateVehicle(renewedVehicle, setError)
    if (valid) {
      if (renewedVehicle?.image) {
        await blobUrlToBase64(renewedVehicle?.image)
          .then((base64String) => {
            renewedVehicle.image = base64String as string
          })
          .catch((error) => {
            console.error('Conversion failed:', error)
          })
      }
      try {
        const submitResponse = await submitRequest({
          type: 'renewal',
          comments,
          mainVehicle: {
            ...renewedVehicle
          },
          mainVehicleId: vehicleToRenew?.id,
          dealerCode: user?.dealerCode?.toString(),
          dealerName: dealer?.name,
          region: dealer?.divisionCode,
          district: dealer?.districtID
        })

        if (submitResponse?.requestId) {
          history.push(ROUTE_PATHS.SUBSIDY_REQUESTS + `/${submitResponse.requestId}`)
        }
      } catch (error) {
        setRequestError(error?.response?.data?.message)
      }
    }
  }

  function advanceStep() {
    switch (step) {
      case 0:
        if (!vehicleToRenew) {
          setError('Please select a vehicle to renew.')
        } else {
          setError(null)
          setStep(1)
        }
        break
    }
  }

  return (
    <Container
      title={
        <Box>
          <Breadcrumb
            paths={[
              { label: 'Submit a Request', path: ROUTE_PATHS.SUBSIDY_SUBMIT },
              { label: 'Vehicle Renewal', path: ROUTE_PATHS.CASE_DETAILS + '?type=renewal' }
            ]}
          />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Vehicle Renewal
          </Typography>
        </Box>
      }>
      <TableContainer>
        {step === 0 ? (
          <>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 0.5 }}>
              Which vehicle is this request for?
            </Typography>

            <Typography
              fontWeight={400}
              fontSize={16}
              color={theme.palette.primary.dark}
              sx={{
                mb: 3
              }}>
              Choose a vehicle to proceed with the renewal.
            </Typography>
            <Box flex={1} marginBottom={3}>
              <Select
                sx={{ width: '100%' }}
                inputlabel="Choose"
                value={vehicleToRenew?.id || ''}
                options={vehicleOptions}
                onChange={(e) => {
                  setVehicleToRenew(vehicles?.data?.find((car) => car.id === e.target.value))
                  setError(null)
                }}
              />
            </Box>
            {vehicleToRenew && (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={isDownMediumWidth ? 'column' : 'row'}
                  sx={{
                    border: `1px solid ${theme.palette.primary.light}`,
                    p: 2,
                    borderRadius: 1,
                    gap: 4,
                    backgroundColor: theme.palette.secondary.light
                  }}>
                  <VehiclePhotoDialog
                    DialogToggler={
                      <Box
                        onClick={() => setOpenImage(true)}
                        height={200}
                        width={200}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: `1px solid ${theme.palette.primary.light}`,
                          backgroundColor: theme.palette.secondary.light,
                          backgroundImage: `url(${vehicleToRenew?.imageUrl})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          cursor: 'pointer'
                        }}
                      />
                    }
                    setOpenImage={setOpenImage}
                    openImage={openImage}
                    image={vehicleToRenew?.imageUrl}
                  />
                  <Box flex={1} marginLeft={4} display="flex" flexDirection="column" gap={1.5}>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">VIN:</Typography>
                      <Typography>{vehicleToRenew.vin}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Make:</Typography>
                      <Typography>{vehicleToRenew.make}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Model:</Typography>
                      <Typography>{vehicleToRenew.model}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Year:</Typography>
                      <Typography>{vehicleToRenew.year}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Miles:</Typography>
                      <Typography>{vehicleToRenew.miles}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">DOFU:</Typography>
                      <Typography>{format(new Date(vehicleToRenew.dofu), 'MM-dd-yyyy')}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {error && (
              <Typography display="flex" alignItems="center" marginTop={2} color="red">
                <ErrorOutline sx={{ mr: 1 }} />
                {error}
              </Typography>
            )}

            <Box marginTop={5} display="flex" justifyContent="space-between">
              <NavLink
                to={ROUTE_PATHS.SUBSIDY_SUBMIT}
                style={{
                  color: theme.palette.primary.dark,
                  fontSize: 16,
                  textDecorationLine: 'underline',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                BACK
              </NavLink>
              <Button
                sx={{
                  px: 2,
                  borderRadius: 0
                }}
                color="secondary"
                variant="contained"
                onClick={advanceStep}>
                Continue
              </Button>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
              <Typography
                fontWeight={400}
                fontSize={16}
                color={theme.palette.primary.dark}
                sx={{
                  mb: 3
                }}>
                If your vehicle is not listed above, add a new vehicle first.
              </Typography>

              <NavLink
                to={ROUTE_PATHS.SUBSIDY_SUBMIT + '?type=addition'}
                style={{
                  color: theme.palette.primary.dark,
                  fontSize: 16,
                  textDecorationLine: 'underline',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  my: 0.5
                }}>
                ADD VEHICLE
                <ArrowForwardIosOutlined sx={{ fontSize: 14, ml: 0.5 }} />
              </NavLink>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 0.5 }}>
              Confirm Vehicle Information
            </Typography>

            <Typography
              fontWeight={400}
              fontSize={16}
              color={theme.palette.primary.dark}
              sx={{
                mb: 3
              }}>
              * Required fields
            </Typography>
            <VehicleForm
              renewal
              currentVehicle={vehicleToRenew}
              setFormData={setRenewedVehicle}
              error={error}
            />
            <Box mt={4} flex={1}>
              <StyledTextField
                onChange={(e) => setComments(e.target.value)}
                value={comments}
                type="text"
                multiline
                rows={2}
                fullWidth
                label="Additional Comments"
                id="comments"
              />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box marginTop={4} display="flex" justifyContent="space-between" alignItems="center">
              <Button
                onClick={() => history.push(ROUTE_PATHS.SUBSIDY_SUBMIT)}
                style={{
                  color: theme.palette.primary.dark,
                  fontSize: 16,
                  textDecorationLine: 'underline'
                }}>
                CANCEL
              </Button>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                <Button
                  onClick={() => setStep(0)}
                  sx={{
                    backgroundColor: 'white',
                    px: 2,
                    border: '1px solid black',
                    borderRadius: 0
                  }}
                  color="secondary"
                  variant="outlined">
                  BACK
                </Button>
                <Button
                  sx={{
                    px: 2,
                    borderRadius: 0
                  }}
                  color="secondary"
                  variant="contained"
                  onClick={handleVehicleRenewal}>
                  {isLoading ? <CircularProgress size={22} color="primary" /> : 'Submit'}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </TableContainer>
      <ErrorDialog
        content={requestError}
        open={!!requestError}
        onClose={() => setRequestError(null)}
      />
    </Container>
  )
}
