import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { CheckEligibilityResponse } from './CheckEligibilityResponse'

export function useCheckEligibility() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: { vin: string; odometer: number }) => {
      const response = (await client.get('/eligibility', {
        params: {
          vin: data.vin,
          odometer: data.odometer
        }
      })) as CheckEligibilityResponse

      return response
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['is-eligible'])
        queryClient.setQueryData(['is-eligible'], result)
        return result
      },
      onError: () => {
        queryClient.setQueryData(['is-eligible'], false)
        return false
      }
    }
  )
}
