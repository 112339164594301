import React, { useCallback, useState } from 'react'
import { ImageOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import theme from 'theme'

interface VehiclePhotoUploadProps {
  DialogToggler: React.ReactNode
  dialogState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  setNewImage: React.Dispatch<React.SetStateAction<string>>
}

export function VehiclePhotoUpload({
  DialogToggler,
  dialogState,
  setNewImage
}: VehiclePhotoUploadProps) {
  const [upload, setUpload] = dialogState
  const [previewSrc, setPreviewSrc] = useState(null)
  const [myFile, setMyFile] = useState([])

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0].size > 5000000)
        return alert('File size is too large. Please upload a file less than 5MB.')
      if (myFile.length > 0) return
      setMyFile([...myFile, ...acceptedFiles])
      setPreviewSrc(URL.createObjectURL(acceptedFiles[0]))
    },
    [myFile]
  )

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/gif': []
    },
    onDrop
  })

  const cancelUpload = () => {
    setUpload(false)
    setTimeout(() => setMyFile([]), 200)
  }

  function handleUploadImage() {
    if (myFile.length) {
      setNewImage(previewSrc)
    }
    setUpload(false)
  }

  return (
    <Box>
      {DialogToggler}
      <Dialog maxWidth="sm" fullWidth onClose={cancelUpload} open={upload}>
        <DialogTitle>Upload a new photo</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={2}>
            Upload a clear image of your vehicle fully visible.
          </DialogContentText>
          <Box>
            {myFile.length ? (
              <Box display="flex" justifyContent="center">
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    border: `2px solid ${theme.palette.primary.light}`
                  }}
                  src={previewSrc}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: 'secondary.light',
                  height: 200,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  border: '1px solid gray'
                }}
                display="flex"
                {...getRootProps()}>
                <input {...getInputProps()} />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ImageOutlined sx={{ fontSize: 50 }} />
                  <Typography>Drop your image here or click to select it.</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: 'white',
              px: 2,
              border: '1px solid black'
            }}
            color="secondary"
            variant="outlined"
            onClick={cancelUpload}>
            Cancel
          </Button>
          <Button
            disabled={!myFile.length}
            color="secondary"
            variant="contained"
            onClick={handleUploadImage}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
