import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress, Grid, useTheme, Typography } from '@mui/material'
import { Button, Container, Steps } from 'components'
import {
  CreateCaseRequest,
  useCheckEligibility,
  useCreateCase,
  useGetDealers,
  useStep,
  getSessionUser
} from 'hooks'
import {
  CustomerForm,
  EligibilityData,
  EligibilityForm,
  VehicleForm,
  BreakdownForm,
  BreakDownData,
  VehicleData,
  NotEligibleDialog,
  OpenCaseDialog,
  CustomerDataWithDate
} from 'pages/Cases'
import { ROUTE_PATHS } from 'constants/Routing'

type CaseData = Partial<
  CustomerDataWithDate &
    BreakDownData &
    VehicleData & {
      vin: string
      odometer: number
      orgCode: string
    }
>

export function NewCase() {
  const {
    mutateAsync: checkEligibility,
    isLoading: isLoadingEligibility,
    isError: isErrorEligibility
  } = useCheckEligibility()
  const { mutateAsync: createCase, isLoading: isLoadingCreateCase } = useCreateCase()
  const [finalFormData, setFinalFormData] = useState<CaseData>()
  const [showNotEligibleDialog, setShowNotEligibleDialog] = useState(false)
  const [showOpenCaseDialog, setShowOpenCaseDialog] = useState(false)
  const [error, setError] = useState('')
  const theme = useTheme()

  const user = getSessionUser()
  const dealerCode = String(user?.dealerCode)
  const { data: dealerInfo } = useGetDealers({ dealerCode })

  const handleEligibilitySubmit = useCallback(async (data: EligibilityData) => {
    const checkEligibilityResponse = await checkEligibility({
      odometer: data.odometer,
      vin: data.vin
    }).catch((err) => {
      console.log(err)
    })

    if (!checkEligibilityResponse) {
      return setShowNotEligibleDialog(true)
    }

    const { eligible } = checkEligibilityResponse

    if (!eligible) {
      return setShowNotEligibleDialog(true)
    }

    setFinalFormData((prev) => ({
      ...prev,
      orgCode: checkEligibilityResponse.contractIdentity.receivingClubCode,
      vin: data.vin,
      odometer: data.odometer
    }))

    stepActions.goToNextStep()
  }, [])

  const handleCancel = useCallback(() => {
    setFinalFormData(undefined)
    stepActions.reset()
  }, [])

  const handleCreateCase = useCallback(
    async (params: Partial<CaseData>) => {
      setError('')

      const parameters = {
        customer: {
          name: finalFormData.name,
          address: {
            city: params.city,
            country: 'USA',
            zip: params.zip,
            street: params.address,
            state: params.state
          },
          phone: finalFormData.phone
        },
        vehicle: {
          color: finalFormData.color,
          model: finalFormData.model,
          odometer: finalFormData.odometer,
          vin: finalFormData.vin,
          year: Number(finalFormData.year),
          make: 'Lexus'
        },
        orgCode: finalFormData.orgCode,
        service: {
          date: finalFormData.dispatched, //time is date plus time
          code: finalFormData.service
        },
        dealer: {
          code: dealerCode,
          name: dealerInfo?.foi[0].name,
          districtId: dealerInfo?.foi[0].districtID,
          region: dealerInfo?.foi[0].divisionCode
        },
        creatorName: params.creator
      } as CreateCaseRequest

      const createCaseResponse = await createCase(parameters).catch((err) => {
        console.log(err)
      })

      if (!createCaseResponse) {
        setError('There was a problem creating the case, please try again later')
        return
      }

      const { status } = createCaseResponse

      if (!status || status !== 'ok') {
        return
      }

      setShowOpenCaseDialog(true)
    },
    [finalFormData]
  )

  const steps = [
    {
      title: 'Check Vehicle Eligibility',
      subtitle: 'Fill in the information to check the eligibility of the vehicle.',
      content: (
        <EligibilityForm
          onSubmit={handleEligibilitySubmit}
          actions={
            <Grid mt={4} display="flex" justifyContent="flex-end">
              <Button type="submit" variant="contained" color="secondary">
                {isLoadingEligibility && !isErrorEligibility ? (
                  <CircularProgress size={22} color="primary" />
                ) : (
                  'CHECK'
                )}
              </Button>
            </Grid>
          }
        />
      )
    },
    {
      title: 'Vehicle Information',
      content: (
        <VehicleForm
          onSubmit={(data) => {
            setFinalFormData((prev) => ({ ...prev, ...data }))
            stepActions.goToNextStep()
          }}
          actions={
            <Grid display="flex" justifyContent="space-between" mt={4}>
              <Button onClick={handleCancel} variant="text" color="secondary">
                CANCEL
              </Button>
              <div>
                <Box mr={1} display="inline-block">
                  <Button
                    onClick={() => {
                      stepActions.goToPrevStep()
                    }}
                    variant="outlined"
                    color="secondary">
                    BACK
                  </Button>
                </Box>
                <Button type="submit" variant="contained" color="secondary">
                  CONTINUE
                </Button>
              </div>
            </Grid>
          }
        />
      )
    },
    {
      title: 'Guest Information',
      content: (
        <CustomerForm
          onSubmit={(data) => {
            setFinalFormData((prev) => ({ ...prev, ...data }))
            stepActions.goToNextStep()
          }}
          actions={
            <Grid display="flex" justifyContent="space-between" mt={4}>
              <Button onClick={handleCancel} variant="text" color="secondary">
                CANCEL
              </Button>
              <div>
                <Box mr={1} display="inline-block">
                  <Button
                    onClick={() => {
                      stepActions.goToPrevStep()
                    }}
                    variant="outlined"
                    color="secondary">
                    BACK
                  </Button>
                </Box>
                <Button type="submit" variant="contained" color="secondary">
                  CONTINUE
                </Button>
              </div>
            </Grid>
          }
        />
      )
    },
    {
      title: 'Breakdown Location',
      content: (
        <BreakdownForm
          onSubmit={(data) => {
            setFinalFormData((prev) => ({ ...prev, ...data }))
            handleCreateCase(data)
          }}
          actions={
            <Grid display="flex" justifyContent="space-between" mt={4}>
              <Button onClick={handleCancel} variant="text" color="secondary">
                CANCEL
              </Button>
              <div>
                <Box mr={1} display="inline-block">
                  <Button
                    onClick={() => {
                      stepActions.goToPrevStep()
                    }}
                    variant="outlined"
                    color="secondary">
                    BACK
                  </Button>
                </Box>
                <Button
                  disabled={isLoadingCreateCase}
                  type="submit"
                  variant="contained"
                  color="secondary">
                  {isLoadingCreateCase ? <CircularProgress size={22} color="primary" /> : 'CREATE'}
                </Button>
              </div>
            </Grid>
          }
        />
      )
    }
  ]

  const [currentStepNumber, stepActions] = useStep(steps.length)
  const currentStep = steps[currentStepNumber - 1]
  const history = useHistory()

  return (
    <Container title="New Case">
      <Steps
        title={currentStep.title}
        subtitle={currentStep.subtitle}
        currentStep={currentStepNumber}
        stepCount={steps.length}
        content={currentStep.content}
      />
      <NotEligibleDialog
        open={showNotEligibleDialog}
        onClose={() => setShowNotEligibleDialog(false)}
      />
      <OpenCaseDialog
        open={showOpenCaseDialog}
        onConfirm={() => {
          setShowOpenCaseDialog(false)
          history.push(ROUTE_PATHS.CASES)
        }}
        onClose={() => setShowOpenCaseDialog(false)}
      />
      <Typography sx={{ color: theme.palette.error.main }}>{error}</Typography>
    </Container>
  )
}
