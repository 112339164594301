import React from 'react'
import { Button } from 'components'
import {
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  styled,
  DialogProps
} from '@mui/material'
import { CheckCircleOutline, HighlightOffRounded } from '@mui/icons-material'

interface ConfirmationDialogProps {
  open: boolean
  title: string
  content: string
  onConfirmLabel: string
  onConfirm: () => void
  icon?: 'success' | 'error'
}

const StyledConfirmationDialog = styled(MaterialDialog)(({ theme }) => ({
  zIndex: 1401,
  '.MuiDialog-paper': {
    alignItems: 'center',
    height: 'fit-content',
    padding: theme.spacing(2.5)
  }
}))

const StyledErrorIcon = styled(HighlightOffRounded)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.error.main,
  borderRadius: '50%',
  padding: 12,
  width: 52,
  height: 52,
  marginBottom: theme.spacing(2)
}))

const StyledSuccessIcon = styled(CheckCircleOutline)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.success.main,
  borderRadius: '50%',
  padding: 12,
  width: 52,
  height: 52,
  marginBottom: theme.spacing(2)
}))

export const ConfirmationDialog = ({
  title,
  content,
  onConfirm,
  onConfirmLabel,
  icon,
  ...props
}: ConfirmationDialogProps & DialogProps) => {
  const Icon = () => {
    switch (icon) {
      case 'error':
        return <StyledErrorIcon />
      case 'success':
        return <StyledSuccessIcon />
      default:
        return null
    }
  }

  return (
    <StyledConfirmationDialog fullWidth {...props}>
      <Icon />
      <DialogTitle fontWeight={700} fontSize={20}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center" fontWeight={400} fontSize={16} lineHeight={1.5}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          {onConfirmLabel}
        </Button>
      </DialogActions>
    </StyledConfirmationDialog>
  )
}
