import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { FolderOpen, Movie, PersonOutline, Settings } from '@mui/icons-material'
import { Container, GenericCardContainer } from 'components'
import { useGetDocuments } from 'hooks'

const TRAINING_SESSION_VIDEO_LINK = 'DLS-Training-Session-3-20240117_092831-Meeting-Recording.mp4'
const TRAINING_DECK_LINK = 'Lexus-DLS-training-deck.pdf'
const OPERATIONS_MANUAL_LINK = 'Lexus-Light-Service-Manual.pdf'

export function GetHelpContent() {
  const { data, isLoading, isError } = useGetDocuments()

  return (
    <GenericCardContainer>
      <Typography variant="h6" fontWeight={800}>
        Get Help
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, fontWeight: 'bold' }}>
        Below you will find contact information related to the Dealer Light Service portal or
        process inquiries.
      </Typography>
      <Grid container sx={{ alignItems: 'center', mb: 1 }}>
        <FolderOpen sx={{ mr: 1 }} />
        <Typography variant="h6">Light Service Program</Typography>
      </Grid>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" component="span">
          <ul>
            <li>Non-Dealer Light Service Roadside Assistance: (800) 255-3987, prompt 1</li>
            <li>Lexus Light Service Email: lexuslightservice@aaanortheast.com</li>
            <li>
              Lexus Light Service Coordinator: (401) 868-6200 Ext #88804 (Mon-Fri, 7am-3:30pm EST.)
            </li>
          </ul>
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container sx={{ alignItems: 'center', mb: 1 }}>
        <Settings sx={{ mr: 1 }} />
        <Typography variant="h6">Roadside Assistence Portal</Typography>
      </Grid>
      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" component="span">
          <ul>
            <li>For any password related concerns, please contact the Toyota Help Desk.</li>
          </ul>
        </Typography>
      </Box>
      {!isLoading && !isError && (
        <>
          <Divider sx={{ mb: 3 }} />
          <Grid container sx={{ mb: 3, justifyContent: 'space-between' }}>
            <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
              <PersonOutline sx={{ mr: 1 }} />
              <Typography variant="h6">Operations Manual/User Guide</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{ textDecoration: 'underline', cursor: 'pointer', a: { color: 'inherit' } }}>
                <a target="_blank" rel="noreferrer" href={data[OPERATIONS_MANUAL_LINK]}>
                  DOWNLOAD NOW
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 3 }} />
          <Grid container sx={{ mb: 3, justifyContent: 'space-between' }}>
            <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
              <PersonOutline sx={{ mr: 1 }} />
              <Typography variant="h6">Training Deck</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{ textDecoration: 'underline', cursor: 'pointer', a: { color: 'inherit' } }}>
                <a target="_blank" rel="noreferrer" href={data[TRAINING_DECK_LINK]}>
                  DOWNLOAD NOW
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 3 }} />
          <Grid container sx={{ mb: 3, justifyContent: 'space-between' }}>
            <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
              <Movie sx={{ mr: 1 }} />
              <Typography variant="h6">Training Session</Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{ textDecoration: 'underline', cursor: 'pointer', a: { color: 'inherit' } }}>
                <a target="_blank" rel="noreferrer" href={data[TRAINING_SESSION_VIDEO_LINK]}>
                  DOWNLOAD NOW
                </a>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </GenericCardContainer>
  )
}

export function GetHelp() {
  return (
    <>
      <Container title="Get Help" subtitle="Contacts and information to help you">
        <GetHelpContent />
      </Container>
    </>
  )
}
