import React, { useId } from 'react'
import { styled, useTheme } from '@mui/material'
import { DateField as XDateField } from '@mui/x-date-pickers/DateField'
import { DateTimeInputLabel, TextFieldInputProps } from 'components'

const options = {
  shouldForwardProp: (prop) => prop !== 'inputLabel'
}
const StyledDateField = styled(XDateField, options)(() => ({}))

export const DateField = (props: TextFieldInputProps) => {
  const id = useId()
  const theme = useTheme()

  return (
    <>
      <DateTimeInputLabel htmlFor={id}>
        {props.inputLabel}
        {props.required && '*'}
      </DateTimeInputLabel>
      <StyledDateField
        id={id}
        style={{ width: '100%' }}
        variant="standard"
        inputProps={{
          sx: {
            '&::placeholder': {
              color: theme.palette.primary.dark,
              opacity: 1
            }
          }
        }}
        {...props}
        {...props.register}
      />
    </>
  )
}
