import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { UpdateCaseResponse } from './UpdateCaseResponse'
import { UpdateCaseRequest } from './UpdateCaseRequest'

export function useUpdateCase() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: UpdateCaseRequest) => {
      return (await client.put('/case', data)) as UpdateCaseResponse
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['update-case-case'])
        queryClient.setQueryData(['update-case-case'], result)
        return result
      },
      onError: () => {
        queryClient.setQueryData(['update-case-case'], false)
        return false
      }
    }
  )
}
