import React, { useState } from 'react'
import { Box, Drawer, IconButton, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { useUser } from 'hooks'
import { SIDEBAR_WIDTH } from 'constants/Utils'
import { SidebarLogout, SidebarRoleOptions } from './Options'
import { Close, Menu } from '@mui/icons-material'

const StyledSidebar = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paperAnchorLeft': {
    marginTop: theme.spacing(8),
    width: SIDEBAR_WIDTH,
    borderRight: `1px solid ${theme.palette.primary.light}`
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText
  },
  '.MuiAccordionDetails-root:has(a.active)': {
    borderLeftColor: theme.palette.primary.contrastText,
    'a.active': {
      color: theme.palette.primary.contrastText
    }
  }
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  zIndex: 1201,
  position: 'fixed',
  right: 0,
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}))

export const Sidebar = (props) => {
  //this hook loads the user info for the session
  const [user] = useUser()

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const theme = useTheme()
  const isUpLargeWidth = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box>
      <StyledToolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          edge="start">
          {isSidebarOpen ? <Close /> : <Menu />}
        </IconButton>
      </StyledToolbar>
      <StyledSidebar
        variant="persistent"
        anchor="left"
        open={isSidebarOpen || isUpLargeWidth}
        {...props}>
        <SidebarRoleOptions role={user?.role} />
        <SidebarLogout />
      </StyledSidebar>
    </Box>
  )
}
