import { Box, Chip, Typography, useTheme, useMediaQuery } from '@mui/material'
import { Vehicle } from 'hooks/useGetVehicles/GetVehiclesResponse'
import React, { useState } from 'react'
import { VehiclePhotoDialog } from './VehiclePhotoDialog'
import { format } from 'date-fns'
import { ROUTE_PATHS } from 'constants/Routing'
import { Link } from 'react-router-dom'

interface VehicleCardProps {
  vehicle: Vehicle
  title?: string | React.ReactNode
  imageDistributionUrl?: string
}

export default function VehicleCard({ vehicle, title, imageDistributionUrl }: VehicleCardProps) {
  const [openImage, setOpenImage] = useState(false)
  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box
      display="inline-block"
      width="100%"
      sx={{
        border: `1px solid ${theme.palette.primary.light}`,
        backgroundColor: theme.palette.secondary.light
      }}
      my={2}
      p={3}
      pt={1}>
      <Box mb={2} display="flex" alignItems="center" gap={1.5}>
        <Typography variant="h6" sx={{ textDecoration: 'underline', textUnderlineOffset: 3 }}>
          {title}
        </Typography>
        {vehicle.active ? (
          <Chip
            sx={{
              borderRadius: 10,
              pb: 0,
              fontSize: 16,
              backgroundColor: theme.palette.success.dark,
              color: theme.palette.primary.main
            }}
            label="Active"
            size="small"
          />
        ) : (
          <Chip
            sx={{
              borderRadius: 10,
              pb: 0,
              fontSize: 16,
              backgroundColor: theme.palette.error.dark,
              color: theme.palette.primary.main
            }}
            label="Inactive"
            size="small"
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ gap: 2 }}
        flexDirection={isDownMediumWidth ? 'column' : 'row'}>
        <VehiclePhotoDialog
          DialogToggler={
            <Box
              onClick={() => setOpenImage(true)}
              height={200}
              width={200}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                backgroundColor: theme.palette.secondary.light,
                backgroundImage: `url(${`${imageDistributionUrl}/${
                  vehicle?.imagePath.split('/')[1]
                }`})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                cursor: 'pointer'
              }}
            />
          }
          setOpenImage={setOpenImage}
          openImage={openImage}
          image={`${imageDistributionUrl}/${vehicle?.imagePath.split('/')[1]}`}
        />
        <Box flex={1} display="flex" flexDirection="column" gap={1.5}>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">VIN:</Typography>
            <Link
              style={{ color: 'inherit' }}
              to={ROUTE_PATHS.VEHICLE_DETAILS.replace(':id', vehicle.id.toString())}>
              {vehicle.vin}
            </Link>
          </Box>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">Make:</Typography>
            <Typography>{vehicle.make}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">Model:</Typography>
            <Typography>{vehicle.model}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">Year:</Typography>
            <Typography>{vehicle.year}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">Miles:</Typography>
            <Typography>{vehicle.miles}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">DOFU:</Typography>
            <Typography>{format(new Date(vehicle.dofu), 'MM-dd-yyyy')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
