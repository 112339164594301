import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js/types/lib/oidc/types/UserClaims'
import { GenericCardContainer } from 'components'
import { GetDealersResponse, getSessionUser, UserData } from 'hooks'
import { renderEmails, renderRegion, renderTelephones } from 'utils'
import theme from 'theme'
import { REGION_MAP } from 'constants/Region'

interface UserInformationProps {
  title?: string
  data?: UserData
}

interface DealerInformationProps {
  data?: GetDealersResponse
}

const StyledTableCell = styled(TableCell)(() => ({
  padding: 4
}))

const PasswordChangeSection = () => (
  <Grid container sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
    <Grid item sx={{ mb: 2 }}>
      <Typography variant="h6">Roadside Assistence Portal</Typography>
    </Grid>
    <Grid>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Phone: (213) 524-1575
      </Typography>
    </Grid>
  </Grid>
)

export function UserInformation({ title, data }: UserInformationProps) {
  const { oktaAuth } = useOktaAuth()
  const [userInfo, setUserInfo] = useState({} as UserClaims<CustomUserClaims>)
  const user = getSessionUser()

  useEffect(() => {
    if (Boolean(data) && Object.keys(data).length > 0) {
      oktaAuth.token.getUserInfo().then((info) => {
        setUserInfo(info)
      })
    }
  }, [data])
  return (
    <GenericCardContainer>
      <Typography variant="h6" fontWeight={800} sx={{ mb: 3 }}>
        {title || 'User Information'}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }} component="div">
        <p>Name: {userInfo?.given_name && `${userInfo?.given_name} ${userInfo?.family_name}`}</p>
        <p>Area: {REGION_MAP[String(user.region)]}</p>
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <PasswordChangeSection />
    </GenericCardContainer>
  )
}

export function DealerInformation({ data }: DealerInformationProps) {
  return (
    <GenericCardContainer>
      <Typography variant="h6" fontWeight={800} sx={{ mb: 3 }}>
        Dealer Information, Address and Enrollment Status
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }} component="div">
        <p>Name: {data?.foi[0].name}</p>
        <p>Dealer Code: {data?.foi[0].id}</p>
        <p>Region: {renderRegion(data?.foi[0].divisionCode)}</p>
        <p>District: {data?.foi[0].districtID}</p>
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
        <Grid item sx={{ mb: 2 }}>
          <Typography variant="h6">Enrollment Status</Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" sx={{ mb: 3 }} component="div">
            <p>Status: {data?.foi[0].dls.isEnrolled ? 'Active' : 'Inactive'}</p>
            <p>{`Enrollment Date: ${
              data?.foi[0]?.dls.enrollmentDate
                ? new Date(data?.foi[0]?.dls.enrollmentDate).toLocaleDateString()
                : '-'
            }`}</p>
            <p>Wireless: {data?.foi[0].dls.wireless ? 'Yes' : 'No'}</p>
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
        <Grid item sx={{ mb: 2 }}>
          <Typography variant="h6">Address</Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" sx={{ mb: 3 }} component="div">
            <p>Street: {data?.foi[0].location?.address?.addressLine}</p>
            <p>
              City, State, Zip:{' '}
              {`${data?.foi[0].location?.address?.city}, ${data?.foi[0].location?.address?.state}, ${data?.foi[0].location?.address?.postalCode}`}
            </p>
            <p>Country: {data?.foi[0].location?.address?.country} </p>
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <PasswordChangeSection />
    </GenericCardContainer>
  )
}

export function LightServiceInformation({ data }: DealerInformationProps) {
  return (
    <GenericCardContainer>
      <Typography variant="h6" fontWeight={800} sx={{ mb: 3 }}>
        Dealer Light Service Information
      </Typography>
      <Grid container sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
        {data?.foi[0].contacts?.map((contact, id) => (
          <Box key={id}>
            <Grid item sx={{ mb: 2 }}>
              <Typography variant="h6">{contact.contactType} </Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" sx={{ mb: 3 }} component="div">
                <p>Name: - </p>
                <p>Email(s) for Wireless Dispatch: {renderEmails(contact.emails)}</p>
                <p>Telephone: {renderTelephones(contact.phones)}</p>
              </Typography>
            </Grid>
            <Divider sx={{ mb: 2 }} />
          </Box>
        ))}
      </Grid>

      <Grid container sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
        <Grid item sx={{ mb: 2 }}>
          <Typography variant="h6">Service Information</Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Hours of Operations
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" component="div">
            <TableContainer component={Paper} sx={{ mb: 1, boxShadow: 'none' }}>
              <Table aria-label="hours of operations table" padding="none">
                <TableHead sx={{ backgroundColor: theme.palette.secondary.light }}>
                  <TableRow>
                    <StyledTableCell sx={{ fontWeight: '500' }}>Days:</StyledTableCell>
                    <StyledTableCell align="center">Mo</StyledTableCell>
                    <StyledTableCell align="center">Tu</StyledTableCell>
                    <StyledTableCell align="center">We</StyledTableCell>
                    <StyledTableCell align="center">Th</StyledTableCell>
                    <StyledTableCell align="center">Fr</StyledTableCell>
                    <StyledTableCell align="center">Sa</StyledTableCell>
                    <StyledTableCell align="center">Su</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ my: 2 }}>
                    <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                      Open Time:
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Monday')?.period
                        .open || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Tuesday')?.period
                        .open || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Wednesday')?.period
                        .open || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Thursday')?.period
                        .open || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Friday')?.period
                        .open || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Saturday')?.period
                        .open || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Sunday')?.period
                        .open || '-'}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                      Closing Time:
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Monday')?.period
                        .close || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Tuesday')?.period
                        .close || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Wednesday')?.period
                        .close || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Thursday')?.period
                        .close || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Friday')?.period
                        .close || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Saturday')?.period
                        .close || '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {data?.foi[0].operatingDays?.find((day) => day.day === 'Sunday')?.period
                        .close || '-'}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            (hhmm 24-hour clock, local time zone. 0800 is 8am, 1330 is 1:30pm)
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" component="div">
            Service Type Blocks:
            <ul>
              <li>Dealer has 2 light service cells. </li>
              <li>Please call all of them when offering cases. </li>
              <li>No fuel deliveries</li>
            </ul>
            Travel Range:{' '}
            {data?.foi[0]?.dls.travelRange
              ? `will travel ${data?.foi[0]?.dls.travelRange} miles to service roadside calls`
              : 'Not specified'}
          </Typography>
        </Grid>
      </Grid>
    </GenericCardContainer>
  )
}
