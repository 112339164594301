import React, { useMemo, useState } from 'react'
import { Box, Typography, styled, useTheme } from '@mui/material'
import { DownloadOutlined } from '@mui/icons-material'
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid'
import {
  Button,
  Container,
  Loading,
  NoDataFoundMessage,
  Table,
  TableContainer,
  UnexpectedErrorMessage
} from 'components'
import { Case, useGetCases, getSessionUser } from 'hooks'
import { ExportCases } from './ExportCases'
import {
  showColumns,
  OPEN_CASES_STATUS,
  CLOSED_CASES_STATUS,
  CaseStatusFilter,
  GetCasesParams
} from './CasesUtils'
import { ROLES } from 'constants/Roles'
import { CasesFilters } from './CasesFilters'

const StyledFilterContainer = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '20px'
}))

const StyledStatusContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  borderBottom: `1px solid ${theme.palette.primary.light}`,
  marginBottom: '20px'
}))

const StyledTabLabel = styled(Typography)(() => ({
  cursor: 'pointer',
  fontWeight: 400,
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center'
}))

export function Cases() {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [exporting, setExporting] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [selectedRows, setSelectedRows] = useState<Case[]>([])
  const [caseStatus, setCaseStatus] = useState<CaseStatusFilter>(OPEN_CASES_STATUS)

  const theme = useTheme()
  const user = getSessionUser()

  const [getCasesParams, setGetCasesParams] = useState({} as GetCasesParams)

  const userParam = {}
  if (user?.role === ROLES.MANAGER) {
    userParam['region'] = user?.region.toString()
  } else if (user?.role === ROLES.DEALER) {
    userParam['dealerCode'] = user?.dealerCode.toString()
  }

  const parameters = useMemo(
    () => ({
      status: caseStatus,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      ...userParam,
      ...getCasesParams
    }),
    [caseStatus, pageSize, page, userParam, getCasesParams]
  )

  const { data, isLoading, isError } = useGetCases(parameters)

  const handleRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    if (data?.data) {
      const selectedSet = new Set([...data.data, ...selectedRows])
      const selected = Array.from(selectedSet)
      const newSelectedRows = selected.filter((row) => newRowSelectionModel.includes(row.id))
      setSelectedRows(newSelectedRows)
    }
  }

  const handlePageChange = (params: GridPaginationModel) => {
    setPage(params.page + 1)
    setPageSize(params.pageSize)
  }

  const handleChangeStatus = (caseStatusFilter: CaseStatusFilter) => {
    setCaseStatus(caseStatusFilter)
  }

  const columns = showColumns(user?.role)

  return (
    <Container title="Update &amp; Close Cases" fullWidth>
      <TableContainer>
        <StyledStatusContainer>
          <StyledTabLabel
            sx={{
              color:
                caseStatus === OPEN_CASES_STATUS
                  ? theme.palette.secondary.dark
                  : theme.palette.primary.dark,
              borderBottom:
                caseStatus === OPEN_CASES_STATUS
                  ? `2px solid ${theme.palette.secondary.dark}`
                  : 'none',
              marginRight: '20px'
            }}
            onClick={() => handleChangeStatus(OPEN_CASES_STATUS)}>
            Open Cases
          </StyledTabLabel>
          <StyledTabLabel
            sx={{
              color:
                caseStatus === OPEN_CASES_STATUS
                  ? theme.palette.primary.dark
                  : theme.palette.secondary.dark,
              borderBottom:
                caseStatus === CLOSED_CASES_STATUS
                  ? `2px solid ${theme.palette.secondary.dark}`
                  : 'none'
            }}
            onClick={() => handleChangeStatus(CLOSED_CASES_STATUS)}>
            Closed Cases
          </StyledTabLabel>
          <Button
            sx={{ marginLeft: 'auto', marginBottom: 1 }}
            type="submit"
            variant="contained"
            color="secondary"
            onClick={() => setExporting(true)}>
            EXPORT CASES
            <DownloadOutlined sx={{ marginLeft: 1 }} />
          </Button>
        </StyledStatusContainer>
        <ExportCases
          selectedRows={selectedRows}
          setExporting={setExporting}
          exporting={exporting}
          data={data?.data}
        />
        <StyledFilterContainer>
          <CasesFilters setGetCasesParams={setGetCasesParams} />
        </StyledFilterContainer>
        {isLoading && !data && <Loading />}
        {!isLoading && !data && !isError && <NoDataFoundMessage />}
        {!isLoading && data && (
          <Box sx={{ border: `1px solid ${theme.palette.primary.light}` }}>
            <Table
              onPaginationModelChange={handlePageChange}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              paginationMode="server"
              isLoading={isLoading}
              rowCount={data?.itemsCount || 0}
              keepNonExistentRowsSelected
              rowSelectionModel={rowSelectionModel}
              initialState={{
                pagination: {
                  paginationModel: { page: page === 1 ? 0 : page - 1, pageSize }
                }
              }}
              columns={columns}
              rows={data ? data.data : []}
            />
          </Box>
        )}
        {!isLoading && isError && <UnexpectedErrorMessage />}
      </TableContainer>
    </Container>
  )
}
