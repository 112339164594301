import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Typography
} from '@mui/material'
import {
  CircleOutlined,
  CircleRounded,
  CloseOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  PictureAsPdf,
  TextSnippetOutlined
} from '@mui/icons-material'
import { CSVLink } from 'react-csv'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { Dealer, getSessionUser } from 'hooks'
import { ROLES } from 'constants/Roles'
import theme from 'theme'

export function ExportDealers({
  exporting,
  setExporting,
  selectedRows,
  data,
  report
}: {
  exporting: boolean
  setExporting: (state: boolean) => void
  selectedRows: Dealer[]
  data: Dealer[]
  report: 'enrollment' | 'summary'
}) {
  const user = getSessionUser()
  const [checked, setChecked] = useState('all')
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const adminHeaders = [
    { label: 'Dealer Name', key: 'name' },
    { label: 'Dealer Code', key: 'id' },
    { label: 'Region', key: 'divisionCode' },
    { label: 'District', key: 'districtID' }
  ]

  const managerHeaders = [
    { label: 'Dealer Name', key: 'name' },
    { label: 'Dealer Code', key: 'id' }
  ]

  if (report === 'enrollment') {
    adminHeaders.push({ label: 'Status', key: 'enrollmentStatus' })
    managerHeaders.push({ label: 'Status', key: 'enrollmentStatus' })
    adminHeaders.push({ label: 'Enrollment Date', key: 'dls.enrollmentDate' })
    managerHeaders.push({ label: 'Enrollment Date', key: 'dls.enrollmentDate' })
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleExportClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleClose = () => {
    setExporting(false)
  }

  function dataToBeExported() {
    if (!data) return
    const dataCopy = [...data]
    dataCopy.forEach((dealer) => {
      if (dealer?.dls?.isEnrolled) {
        dealer['enrollmentStatus'] = 'Active'
      } else {
        dealer['enrollmentStatus'] = 'Inactive'
      }
    })
    if (checked === 'all') {
      return dataCopy
    } else if (checked === 'selected') {
      return selectedRows
    } else if (checked === 'active') {
      return dataCopy.filter((dealer) => dealer?.dls?.isEnrolled)
    } else if (checked === 'inactive') {
      return dataCopy.filter((dealer) => !dealer?.dls?.isEnrolled)
    }
  }

  const downloadPdf = () => {
    const doc = new jsPDF()
    const data = dataToBeExported()
    autoTable(doc, {
      head:
        user?.role === ROLES.ADMIN
          ? [adminHeaders.map((header) => header.label)]
          : [managerHeaders.map((header) => header.label)],
      body:
        data &&
        data.map((dealer) => {
          if (user?.role === ROLES.ADMIN) {
            return adminHeaders.map((header) => dealer[header.key])
          } else {
            return managerHeaders.map((header) => dealer[header.key])
          }
        })
    })
    if (report === 'enrollment') {
      doc.save('enrollment-summary.pdf')
    } else {
      doc.save('dealer-summary.pdf')
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 0,
          margin: 0,
          right: 0,
          height: '100vh',
          maxHeight: '100vh',
          width: '350px'
        }
      }}
      open={exporting}
      keepMounted
      onClose={handleClose}>
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight="bold" sx={{ fontSize: 20, color: 'black' }}>
          Export Dealers
        </Typography>
        <CloseOutlined onClick={handleClose} sx={{ cursor: 'pointer' }} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText marginBottom={2}>
          Choose one of the options and download your dealers in CSV format.
        </DialogContentText>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'all'}
              onChange={() => setChecked('all')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">All Dealers</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'selected'}
              onChange={() => setChecked('selected')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">Selected Dealers: {selectedRows.length}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'active'}
              onChange={() => setChecked('active')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">Active Dealers</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'inactive'}
              onChange={() => setChecked('inactive')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">Inactive Dealers</Typography>
          </Box>

          <Stack width="100%" direction="row">
            <div style={{ width: '100%' }}>
              <Button
                ref={anchorRef}
                id="composition-button"
                variant="contained"
                color="secondary"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                fullWidth
                sx={{
                  marginTop: 4,
                  py: 1,
                  borderRadius: 0,
                  backgroundColor: theme.palette.secondary.dark
                }}
                endIcon={open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                onClick={handleToggle}>
                Export Dealers
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom"
                transition
                disablePortal>
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      width: anchorRef.current?.clientWidth,
                      transformOrigin: 'top'
                    }}>
                    <Box
                      width="100%"
                      sx={{
                        backgroundColor: 'white',
                        border: `1px solid ${theme.palette.secondary.dark}`
                      }}>
                      <ClickAwayListener onClickAway={handleExportClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          sx={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
                          onKeyDown={handleListKeyDown}>
                          <CSVLink
                            style={{ textDecoration: 'none' }}
                            filename={
                              report === 'enrollment'
                                ? 'enrollment-summary.csv'
                                : 'dealers-summary.csv'
                            }
                            data={dataToBeExported()}
                            headers={user?.role === ROLES.ADMIN ? adminHeaders : managerHeaders}>
                            <MenuItem sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                              <Typography color={theme.palette.primary.contrastText}>
                                Export CSV
                              </Typography>
                              <TextSnippetOutlined color="secondary" sx={{ ml: 1 }} />
                            </MenuItem>
                          </CSVLink>
                          <MenuItem
                            sx={{ py: 2, display: 'flex', justifyContent: 'center' }}
                            onClick={downloadPdf}>
                            <Typography variant="body1" color={theme.palette.primary.contrastText}>
                              Export PDF
                            </Typography>
                            <PictureAsPdf color="secondary" sx={{ ml: 1 }} />
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Box>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
