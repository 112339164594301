import React from 'react'
import { Box, Typography } from '@mui/material'
import { SIDEBAR_WIDTH } from 'constants/Utils'
import theme from 'theme'
import { Announcement } from '@mui/icons-material'

interface Props {
  children?: React.ReactNode
}

class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo)
  }

  render(): React.ReactNode {
    if (this.state.hasError)
      return (
        <Box
          sx={{
            marginLeft: `${SIDEBAR_WIDTH}px`,
            width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.light,
              borderRadius: '100%',
              padding: 6
            }}>
            <Announcement sx={{ fontSize: 120, opacity: 0.7, color: theme.palette.primary.dark }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1
            }}>
            <Typography color="primary.dark" variant="h5">
              Whoops! An unexpected error has occurred.
            </Typography>
            <Typography color="primary.dark" variant="subtitle1">
              Keep using the portal as usual, and try again later.
            </Typography>
          </Box>
        </Box>
      )
    return this.props.children
  }
}

export default ErrorBoundary
