import React, { useId } from 'react'
import { InputLabel, TextField, TextFieldProps, styled, useTheme } from '@mui/material'
import { UseFormRegisterReturn } from 'react-hook-form'

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold'
}))

interface TextFieldInputProps {
  inputLabel?: string
  register?: UseFormRegisterReturn
}

const options = {
  shouldForwardProp: (prop) => prop !== 'inputLabel'
}
const StyledTextField = styled(TextField, options)(() => ({}))

export const TextFieldInput = (props: TextFieldInputProps & TextFieldProps) => {
  const id = useId()
  const theme = useTheme()

  return (
    <>
      <StyledInputLabel htmlFor={id}>
        {props.inputLabel}
        {props.required && '*'}
      </StyledInputLabel>
      <StyledTextField
        id={id}
        variant="standard"
        inputProps={{
          sx: {
            '&::placeholder': {
              color: theme.palette.primary.dark,
              opacity: 1
            }
          }
        }}
        {...props}
        {...props.register}
      />
    </>
  )
}
