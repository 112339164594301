import * as React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  styled,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion as MAccordion,
  AccordionProps
} from '@mui/material'

const StyledAccordion = styled(MAccordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  '& .dealersHeaderRow': {
    backgroundColor: theme.palette.secondary.light
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold'
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    backgroundColor: theme.palette.secondary.light
  },
  border: `1px solid ${theme.palette.secondary.light}`
}))

type Props = {
  children: React.ReactNode
  title: string
} & AccordionProps

export function Accordion({ children, title, ...props }: Props) {
  return (
    <StyledAccordion elevation={0} {...props}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="inherit" fontWeight="bolder" sx={{ fontSize: 18, paddingY: 1 }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  )
}
