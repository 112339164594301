import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Button, Container, Logo, GenericCardContainer } from 'components'
import { ROUTE_PATHS } from 'constants/Routing'

export function Login() {
  const history = useHistory()
  const theme = useTheme()
  const isUpSmallWidth = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <Container sidebar={false} fullWidth={!isUpSmallWidth}>
        <GenericCardContainer>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              <Logo />
            </Grid>
            <Grid item sx={{ mb: 4 }}>
              <Typography variant="h6">
                Welcome to Dealer Light Services (DLS) Application
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push(ROUTE_PATHS.HOME)}
                sx={{ px: 4 }}>
                Login
              </Button>
            </Grid>
          </Grid>
        </GenericCardContainer>
      </Container>
    </>
  )
}
