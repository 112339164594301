import { InputLabel, styled } from '@mui/material'
import { UseFormRegisterReturn } from 'react-hook-form'
export interface TextFieldInputProps {
  inputLabel?: string
  required?: boolean
  placeholder?: string
  register?: UseFormRegisterReturn
}

export const DateTimeInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  fontSize: 14
}))
