import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Box } from '@mui/material'
import { TextFieldInput } from 'components'

const breakDownSchema = yup.object({
  address: yup.string().optional(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  creator: yup.string().required()
})

export type BreakDownData = yup.InferType<typeof breakDownSchema>

type BreakDownFormProps = {
  onSubmit: (data: BreakDownData) => void
  actions?: React.ReactNode
}

export const BreakdownForm = ({ onSubmit, actions }: BreakDownFormProps) => {
  const useYupValidationResolver =
    (validationSchema: yup.ObjectSchema<BreakDownData>) => async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
    }

  const resolver = useYupValidationResolver(breakDownSchema)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver })

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}>
      <TextFieldInput
        fullWidth
        error={!!errors['address']}
        placeholder="Guest Address"
        inputLabel="Address"
        id="address"
        register={{ ...register('address') }}
      />
      <Box display="flex" mt={4}>
        <Box flex={1}>
          <TextFieldInput
            fullWidth
            error={!!errors['city']}
            placeholder="City"
            inputLabel="City*"
            id="city"
            register={{ ...register('city') }}
          />
        </Box>
        <Box flex={1} ml={3}>
          <TextFieldInput
            fullWidth
            error={!!errors['state']}
            placeholder="State"
            inputLabel="State*"
            id="state"
            register={{ ...register('state') }}
          />
        </Box>
      </Box>
      <Box mt={4}>
        <TextFieldInput
          fullWidth
          error={!!errors['zip']}
          placeholder="00000"
          inputLabel="Zipcode*"
          id="zip"
          register={{ ...register('zip') }}
        />
      </Box>
      <Box mt={4}>
        <TextFieldInput
          fullWidth
          error={!!errors['creator']}
          placeholder="Opened By"
          inputLabel="Opened By*"
          id="creator"
          register={{ ...register('creator') }}
        />
      </Box>
      {actions}
    </form>
  )
}
