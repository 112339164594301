import { useQuery } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { Case } from '../useGetCases'

export function useGetCase(params: { id: string | number }) {
  const client = useClient()

  return useQuery(['case/', params.id], async () => {
    try {
      return (await client.get('/case/' + params.id)) as Case
    } catch (error) {
      throw new Error(error)
    }
  })
}
