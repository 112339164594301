import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import { DownloadOutlined } from '@mui/icons-material'
import { DatePicker, PaddedFlexFullWidthContainer, SearchInput, Select } from 'components'
import { ROLES } from 'constants/Roles'
import { GetSubsidyRequests, Request, getSessionUser } from 'hooks'
import { SubmitRequestDropdown } from '../../shared/SubmitRequestDropdown'
import { ExportSubsidyRequests } from './ExportSubsidyRequests'

const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'pending' },
  { label: 'Manager Approved', value: 'managerApproved' },
  { label: 'Admin Approved', value: 'adminApproved' },
  { label: 'Manager Denied', value: 'managerDenied' },
  { label: 'Admin Denied', value: 'adminDenied' }
]

const typeOptions = [
  { label: 'All', value: '' },
  { label: 'Addition', value: 'addition' },
  { label: 'Renewal', value: 'renewal' },
  { label: 'Replacement', value: 'replacement' },
  { label: 'Removal', value: 'removal' }
]

interface RequestFiltersProps {
  setParams: (params: GetSubsidyRequests) => void
  params: GetSubsidyRequests
  selectedRows?: Request[]
  data: Request[]
  disabled: boolean
  managing?: boolean
}

export function RequestsFilters({
  setParams,
  params,
  selectedRows,
  data,
  disabled,
  managing
}: RequestFiltersProps) {
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [openCalendars, setOpenCalendars] = useState(false)
  const user = getSessionUser()
  const [exporting, setExporting] = useState(false)

  function renderDateFilters() {
    return (
      <>
        <Box>
          <DatePicker
            open={openCalendars}
            onOpen={() => setOpenCalendars(true)}
            onClose={() => setOpenCalendars(false)}
            inputLabel="Start Date"
            required={false}
            onChange={(df) => setDateFrom(df)}
            dateFrom={dateFrom}
            dateTo={dateTo}
            closeOnSelect={true}
            placement="bottom-end"
          />
        </Box>
        <Box>
          <DatePicker
            onOpen={() => setOpenCalendars(true)}
            onClose={() => setOpenCalendars(false)}
            open={openCalendars}
            inputLabel="End Date"
            minDate={dateFrom}
            required={false}
            onChange={(dt) => {
              setDateTo(dt)
              onFilterChange({ fromDate: dateFrom, toDate: dt })
            }}
            dateFrom={dateFrom}
            dateTo={dateTo}
            closeOnSelect={true}
            placement="bottom-start"
          />
        </Box>
      </>
    )
  }

  function onFilterChange(newParams) {
    setParams({ ...params, ...newParams })
  }

  const adminFilters = () => (
    <PaddedFlexFullWidthContainer
      sx={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}
      gap={4}>
      <Box display="flex" flex={1} gap={3}>
        {!managing ? (
          <Box flex={1}>
            <Select
              sx={{ width: '100%' }}
              inputlabel="Status"
              defaultValue=""
              options={statusOptions}
              onChange={(e) => onFilterChange({ status: e.target.value })}
              disabled={false}
            />
          </Box>
        ) : (
          <Box flex={1}>
            <Select
              sx={{ width: '100%' }}
              inputlabel="Type"
              defaultValue=""
              options={typeOptions}
              onChange={(e) => onFilterChange({ type: e.target.value })}
              disabled={false}
            />
          </Box>
        )}
        <Box flex={1} sx={{ gap: 4 }}>
          <Select
            fullWidth
            inputlabel="Region"
            placeholder="Select Region"
            defaultValue=""
            onChange={(e) => onFilterChange({ region: e.target.value })}
            options={[
              {
                label: 'All',
                value: ''
              },
              { label: 'Western', value: '31' },
              { label: 'Central', value: '32' },
              { label: 'Eastern', value: '33' },
              { label: 'Southern', value: '34' }
            ]}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchInput
            onChange={(e) => onFilterChange({ dealerCode: e.target.value })}
            inputlabel="Dealer Code"
            sx={{ width: '100%' }}
          />
        </Box>
      </Box>

      {data && !managing && (
        <Box>
          <Button
            sx={{ borderRadius: 0 }}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={() => setExporting(true)}>
            EXPORT
            <DownloadOutlined sx={{ marginLeft: 1 }} />
          </Button>
          <ExportSubsidyRequests
            selectedRows={selectedRows}
            setExporting={setExporting}
            exporting={exporting}
            data={data}
          />
        </Box>
      )}
    </PaddedFlexFullWidthContainer>
  )

  const managerFilters = () => (
    <PaddedFlexFullWidthContainer
      sx={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}
      gap={4}>
      <Box display="flex" flex={3} gap={3}>
        {!managing ? (
          <Box flex={1}>
            <Select
              sx={{ width: '100%' }}
              inputlabel="Status"
              defaultValue=""
              options={statusOptions}
              onChange={(e) => onFilterChange({ status: e.target.value })}
              disabled={false}
            />
          </Box>
        ) : (
          <Box flex={1}>
            <Select
              sx={{ width: '100%' }}
              inputlabel="Type"
              defaultValue=""
              options={typeOptions}
              onChange={(e) => onFilterChange({ type: e.target.value })}
              disabled={false}
            />
          </Box>
        )}
        <Box display="flex" flex={3} sx={{ gap: 4, flexWrap: 'wrap' }}>
          {renderDateFilters()}
        </Box>
      </Box>

      {data && !!selectedRows && (
        <Box>
          <Button
            sx={{ borderRadius: 1, height: '80%' }}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={() => setExporting(true)}>
            EXPORT
            <DownloadOutlined sx={{ marginLeft: 1 }} />
          </Button>
          <ExportSubsidyRequests
            selectedRows={selectedRows}
            setExporting={setExporting}
            exporting={exporting}
            data={data}
          />
        </Box>
      )}
    </PaddedFlexFullWidthContainer>
  )

  const dealerFilters = () => (
    <PaddedFlexFullWidthContainer
      sx={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}
      gap={4}>
      <Box display="flex" flex={3} gap={3}>
        <Box flex={1}>
          <Select
            sx={{ width: '100%' }}
            inputlabel="Status"
            defaultValue=""
            options={statusOptions}
            onChange={(e) => onFilterChange({ status: e.target.value })}
            disabled={false}
          />
        </Box>
        <Box display="flex" flex={3} sx={{ gap: 4, flexWrap: 'wrap' }}>
          {renderDateFilters()}
        </Box>
      </Box>

      <Box>
        <SubmitRequestDropdown />
      </Box>
    </PaddedFlexFullWidthContainer>
  )

  if (user?.role === ROLES.ADMIN) return adminFilters()
  if (user?.role === ROLES.MANAGER) return managerFilters()
  if (user?.role === ROLES.DEALER) return dealerFilters()
}
