import React from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Container, Logo, GenericCardContainer } from 'components'
import * as image from 'assets/lexus-car.png'

export function Logout() {
  const theme = useTheme()
  const isUpSmallWidth = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      <Container sidebar={false} fullWidth={!isUpSmallWidth}>
        <GenericCardContainer>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item sx={{ mb: 6 }}>
              <Logo />
            </Grid>

            <Grid item sx={{ mb: 6 }}>
              <Typography variant="h6">
                Thank you for using the Lexus Dealer's Light Service Portal
              </Typography>
            </Grid>
            <Grid item>
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  border: `2px solid ${theme.palette.primary.light}`,
                  maxWidth: '30vw'
                }}
                src={image}
              />
            </Grid>
          </Grid>
        </GenericCardContainer>
      </Container>
    </>
  )
}
