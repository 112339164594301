export const runtimeConfig =
  typeof window !== 'undefined'
    ? {
        // client
        RAZZLE_DLS_API_URL: window.env.RAZZLE_DLS_API_URL,
        RAZZLE_OKTA_AUTH_SERVER_URL: window.env.RAZZLE_OKTA_AUTH_SERVER_URL,
        RAZZLE_OKTA_CLIENT_ID: window.env.RAZZLE_OKTA_CLIENT_ID,
        RAZZLE_OKTA_REDIRECT_URI: window.env.RAZZLE_OKTA_REDIRECT_URI,
        RAZZLE_OKTA_TOYOTA_LOGOUT: window.env.RAZZLE_OKTA_TOYOTA_LOGOUT
      }
    : {
        // server
        RAZZLE_DLS_API_URL: process.env.RAZZLE_DLS_API_URL,
        RAZZLE_OKTA_AUTH_SERVER_URL: process.env.RAZZLE_OKTA_AUTH_SERVER_URL,
        RAZZLE_OKTA_CLIENT_ID: process.env.RAZZLE_OKTA_CLIENT_ID,
        RAZZLE_OKTA_REDIRECT_URI: process.env.RAZZLE_OKTA_REDIRECT_URI,
        RAZZLE_OKTA_TOYOTA_LOGOUT: process.env.RAZZLE_OKTA_TOYOTA_LOGOUT
      }
