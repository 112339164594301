import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Typography
} from '@mui/material'
import {
  CircleOutlined,
  CircleRounded,
  CloseOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  PictureAsPdf,
  TextSnippetOutlined
} from '@mui/icons-material'
import { CSVLink } from 'react-csv'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import theme from 'theme'
import { Vehicle } from 'hooks'
import { format } from 'date-fns'

export function ExportVehicles({
  exporting,
  setExporting,
  selectedRows,
  data
}: {
  exporting: boolean
  setExporting: (state: boolean) => void
  selectedRows: Vehicle[]
  data: Vehicle[]
}) {
  const [checked, setChecked] = useState('all')
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const headers = [
    { label: 'VIN', key: 'vin' },
    { label: 'Make', key: 'make' },
    { label: 'Model', key: 'model' },
    { label: 'Year', key: 'year' },
    { label: 'Miles', key: 'miles' },
    { label: 'Dofu', key: 'dofu' },
    { label: 'Region', key: 'region' },
    { label: 'District', key: 'district' },
    { label: 'Dealer code', key: 'dealerCode' },
    { label: 'Dealer name', key: 'dealerName' }
  ]

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleExportClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleClose = () => {
    setExporting(false)
  }

  function formatData(data) {
    const response = data.map((row) => {
      const dofu = format(new Date(row.createdAt as string), 'MM/dd/yyyy hh:mm a')

      return {
        ...row,
        dofu: `${dofu}`
      }
    })
    return response
  }

  function dataToBeExported() {
    if (!data) return

    const dataCopy = formatData(data)

    const selectedCopy = formatData(selectedRows)

    switch (checked) {
      case 'all':
        return dataCopy
      case 'selected':
        return selectedCopy
      default:
        return dataCopy
    }
  }

  const downloadPdf = () => {
    const doc = new jsPDF()
    const data = dataToBeExported()
    autoTable(doc, {
      head: [headers.map((header) => header.label)],
      body: data.map((row) => headers.map((header) => row[header.key]))
    })

    doc.save('vehicles.pdf')
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 0,
          margin: 0,
          right: 0,
          height: '100vh',
          maxHeight: '100vh',
          width: '350px'
        }
      }}
      open={exporting}
      keepMounted
      onClose={handleClose}>
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight="bold" sx={{ fontSize: 20, color: 'black' }}>
          Export
        </Typography>
        <CloseOutlined onClick={handleClose} sx={{ cursor: 'pointer' }} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText marginBottom={2}>
          Choose one of the options and download in CSV format.
        </DialogContentText>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'all'}
              onChange={() => setChecked('all')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">All Vehicles</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'selected'}
              onChange={() => setChecked('selected')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">Selected: {selectedRows.length} Vehicles</Typography>
          </Box>

          <Stack width="100%" direction="row">
            <div style={{ width: '100%' }}>
              <Button
                ref={anchorRef}
                id="composition-button"
                variant="contained"
                color="secondary"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                fullWidth
                sx={{
                  marginTop: 4,
                  py: 1,
                  borderRadius: 0,
                  backgroundColor: theme.palette.secondary.dark
                }}
                endIcon={open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                onClick={handleToggle}>
                Export Vehicles
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom"
                transition
                disablePortal>
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      width: anchorRef.current?.clientWidth,
                      transformOrigin: 'top'
                    }}>
                    <Box
                      width="100%"
                      sx={{
                        backgroundColor: 'white',
                        border: `1px solid ${theme.palette.secondary.dark}`
                      }}>
                      <ClickAwayListener onClickAway={handleExportClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          sx={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
                          onKeyDown={handleListKeyDown}>
                          <CSVLink
                            style={{ textDecoration: 'none' }}
                            filename="vehicles.csv"
                            data={dataToBeExported()}
                            headers={headers}>
                            <MenuItem sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                              <Typography color={theme.palette.primary.contrastText}>
                                Export CSV
                              </Typography>
                              <TextSnippetOutlined color="secondary" sx={{ ml: 1 }} />
                            </MenuItem>
                          </CSVLink>
                          <MenuItem
                            sx={{ py: 2, display: 'flex', justifyContent: 'center' }}
                            onClick={downloadPdf}>
                            <Typography variant="body1" color={theme.palette.primary.contrastText}>
                              Export PDF
                            </Typography>
                            <PictureAsPdf color="secondary" sx={{ ml: 1 }} />
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Box>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
