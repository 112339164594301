import React, { ReactElement, ReactNode } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card as MaterialCard,
  Typography,
  styled
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface MaterialCardProps {
  component?: string
  maxWidth?: boolean
}

interface CardProps {
  component?: string
  children: string | ReactElement | ReactElement[]
  actions?: ReactNode
  summary?: string
  title?: string
}

const StyledCard = styled(MaterialCard, {
  shouldForwardProp: (prop) => prop !== 'maxWidth'
})<MaterialCardProps>(({ theme }) => ({
  marginBottom: theme.spacing(),
  flex: 1,
  boxShadow: 'none'
}))

export const Card = ({ title, summary, actions, children }: CardProps) => (
  <StyledCard component="main" maxWidth={false}>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 2.5 }}>
        <div>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            {summary}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box sx={{ padding: 2.5 }}>{children}</Box>
        {actions && <Box sx={{ padding: 0 }}>{actions}</Box>}
      </AccordionDetails>
    </Accordion>
  </StyledCard>
)
