import React, { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { Breadcrumb, Container, Select, TableContainer } from 'components'
import { ROUTE_PATHS } from 'constants/Routing'
import theme from 'theme'
import { Vehicle, useSubmitRequest } from 'hooks/useSubmitRequest'

import { blobUrlToBase64 } from 'utils'
import { VehicleForm, validateVehicle } from 'pages/SubsidyProgram/Vehicles/VehicleForm'
import { useGetVehicles, Vehicle as ExistingVehicle, getSessionUser, useGetDealers } from 'hooks'
import { ErrorDialog } from 'pages/Reports/ErrorDialog'
import { ArrowForwardIosOutlined, ErrorOutline } from '@mui/icons-material'
import { VehiclePhotoDialog } from 'pages/SubsidyProgram/Vehicles/VehiclePhotoDialog'
import { format } from 'date-fns'

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 1,
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.dark}`
    }
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.primary.contrastText
  }
}))

export function SubmitAddition() {
  const user = getSessionUser()
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)
  const [tabValue, setTabValue] = useState(0)
  const [step, setStep] = useState(0)
  const [vehicleToAdd, setVehicleToAdd] = useState<Vehicle | null>(null)
  const [vehicleToEdit, setVehicleToEdit] = useState<ExistingVehicle | null>(null)
  const [editedVehicle, setEditedVehicle] = useState<ExistingVehicle | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)
  const [openImage, setOpenImage] = useState(false)
  const [comments, setComments] = useState('')
  const { data: vehicles } = useGetVehicles({
    active: false,
    limit: 50,
    dealerCode: user?.dealerCode.toString()
  })
  const { data } = useGetDealers({ dealerCode: user?.dealerCode.toString() })
  const dealer = data?.foi[0]

  const { mutateAsync: submitRequest, isLoading } = useSubmitRequest()

  function advanceStep() {
    switch (step) {
      case 0:
        if (!vehicleToEdit) {
          setError('Please select a vehicle to renew.')
        } else {
          setError(null)
          setStep(1)
        }
        break
    }
  }

  const vehicleOptions = vehicles?.data
    ? vehicles?.data.length
      ? vehicles?.data?.map((car) => ({
          label: `${car.make} ${car.model}, ${car.year}  `,
          value: car.id
        }))
      : [{ label: 'No vehicles found.', value: null }]
    : [{ label: 'Loading...', value: null }]

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    }
  }

  async function handleVehicleAddition(existing?: boolean) {
    const valid = validateVehicle(existing ? editedVehicle : vehicleToAdd, setError)
    if (valid) {
      if (existing) {
        if (editedVehicle?.image) {
          await blobUrlToBase64(editedVehicle?.image)
            .then((base64String) => {
              editedVehicle.image = base64String as string
            })
            .catch((error) => {
              console.error('Conversion failed:', error)
            })
        }
      } else {
        if (vehicleToAdd?.image) {
          await blobUrlToBase64(vehicleToAdd?.image)
            .then((base64String) => {
              vehicleToAdd.image = base64String as string
            })
            .catch((error) => {
              console.error('Conversion failed:', error)
            })
        }
      }

      const mainVehicleObj = existing ? editedVehicle : vehicleToAdd

      try {
        const submitResponse = await submitRequest({
          existing,
          type: 'addition',
          comments,
          mainVehicle: {
            active: false,
            ...mainVehicleObj,
            dealerCode: user?.dealerCode?.toString(),
            dealerName: dealer?.name,
            region: dealer?.divisionCode,
            district: dealer?.districtID
          },
          dealerCode: user?.dealerCode?.toString(),
          dealerName: dealer?.name,
          region: dealer?.divisionCode,
          district: dealer?.districtID
        })

        if (submitResponse?.requestId) {
          history.push(ROUTE_PATHS.SUBSIDY_REQUESTS + `/${submitResponse.requestId}`)
        }
      } catch (error) {
        setRequestError(error?.response?.data?.message)
      }
    }
  }

  return (
    <Container
      title={
        <Box>
          <Breadcrumb
            paths={[
              { label: 'Submit a Request', path: ROUTE_PATHS.SUBSIDY_SUBMIT },
              { label: 'Add Vehicle', path: ROUTE_PATHS.CASE_DETAILS + '?type=addition' }
            ]}
          />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Add Vehicle
          </Typography>
        </Box>
      }>
      <TableContainer style={{ paddingTop: 0 }}>
        <Tabs
          sx={{ mb: 2 }}
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth">
          <Tab label="New Vehicle" {...a11yProps(0)} />
          <Tab label="Existing Vehicle" {...a11yProps(1)} />
        </Tabs>

        {tabValue === 0 ? (
          <>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 0.5 }}>
              New Vehicle Information
            </Typography>

            <Typography
              fontWeight={400}
              fontSize={16}
              color={theme.palette.primary.dark}
              sx={{
                mb: 3
              }}>
              * Required fields
            </Typography>

            <VehicleForm error={error} setFormData={setVehicleToAdd} />

            <Box mt={4} flex={1}>
              <StyledTextField
                onChange={(e) => setComments(e.target.value)}
                value={comments}
                type="text"
                multiline
                rows={2}
                fullWidth
                label="Additional Comments"
                id="comments"
              />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box display="flex" justifyContent="space-between">
              <Button
                onClick={() => history.push(ROUTE_PATHS.SUBSIDY_SUBMIT)}
                style={{
                  color: theme.palette.primary.dark,
                  fontSize: 16,
                  textDecorationLine: 'underline'
                }}>
                Back
              </Button>
              <Button
                sx={{
                  px: 2,
                  borderRadius: 0
                }}
                color="secondary"
                variant="contained"
                onClick={() => handleVehicleAddition()}>
                {isLoading ? <CircularProgress size={22} color="primary" /> : 'Submit'}
              </Button>
            </Box>
          </>
        ) : (
          <>
            {step === 0 ? (
              <>
                <Typography variant="h5" fontWeight={700} sx={{ mb: 1 }}>
                  Existing Vehicle Information
                </Typography>
                <Box flex={1} marginBottom={3}>
                  <Select
                    sx={{ width: '100%' }}
                    inputlabel="Choose"
                    value={vehicleToEdit?.id || ''}
                    options={vehicleOptions}
                    onChange={(e) => {
                      setVehicleToEdit(vehicles?.data?.find((car) => car.id === e.target.value))
                      setError(null)
                    }}
                  />
                </Box>
                {vehicleToEdit && (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        border: `1px solid ${theme.palette.primary.light}`,
                        p: 2,
                        borderRadius: 1,
                        gap: 4,
                        backgroundColor: theme.palette.secondary.light,
                        flexWrap: 'wrap'
                      }}>
                      <VehiclePhotoDialog
                        DialogToggler={
                          <Box
                            onClick={() => setOpenImage(true)}
                            height={200}
                            width={200}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              border: `1px solid ${theme.palette.primary.light}`,
                              backgroundColor: theme.palette.secondary.light,
                              backgroundImage: `url(${vehicleToEdit?.imageUrl})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              cursor: 'pointer'
                            }}
                          />
                        }
                        setOpenImage={setOpenImage}
                        openImage={openImage}
                        image={vehicleToEdit?.imageUrl}
                      />
                      <Box flex={1} marginLeft={4} display="flex" flexDirection="column" gap={1.5}>
                        <Box display="flex" gap={1}>
                          <Typography fontWeight="bold">VIN:</Typography>
                          <Typography>{vehicleToEdit.vin}</Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography fontWeight="bold">Make:</Typography>
                          <Typography>{vehicleToEdit.make}</Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography fontWeight="bold">Model:</Typography>
                          <Typography>{vehicleToEdit.model}</Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography fontWeight="bold">Year:</Typography>
                          <Typography>{vehicleToEdit.year}</Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography fontWeight="bold">Miles:</Typography>
                          <Typography>{vehicleToEdit.miles}</Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography fontWeight="bold">DOFU:</Typography>
                          <Typography>
                            {format(new Date(vehicleToEdit.dofu), 'MM-dd-yyyy')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}

                {error && (
                  <Typography display="flex" alignItems="center" marginTop={2} color="red">
                    <ErrorOutline sx={{ mr: 1 }} />
                    {error}
                  </Typography>
                )}

                <Box marginTop={5} display="flex" justifyContent="space-between">
                  <NavLink
                    to={ROUTE_PATHS.SUBSIDY_SUBMIT}
                    style={{
                      color: theme.palette.primary.dark,
                      fontSize: 16,
                      textDecorationLine: 'underline',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    BACK
                  </NavLink>
                  <Button
                    sx={{
                      px: 2,
                      borderRadius: 0
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={advanceStep}>
                    Continue
                  </Button>
                </Box>
                <Divider sx={{ my: 4 }} />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  textAlign="center">
                  <Typography
                    fontWeight={400}
                    fontSize={16}
                    color={theme.palette.primary.dark}
                    sx={{
                      mb: 3
                    }}>
                    If your vehicle is not listed above, add a new vehicle first.
                  </Typography>

                  <Typography
                    onClick={() => setTabValue(0)}
                    sx={{
                      color: theme.palette.primary.dark,
                      fontSize: 16,
                      textDecorationLine: 'underline',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginY: 0.5,
                      cursor: 'pointer'
                    }}>
                    ADD NEW VEHICLE
                    <ArrowForwardIosOutlined sx={{ fontSize: 14, ml: 0.5 }} />
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h5" fontWeight={700} sx={{ mb: 0.5 }}>
                  Confirm Vehicle Information
                </Typography>

                <Typography
                  fontWeight={400}
                  fontSize={16}
                  color={theme.palette.primary.dark}
                  sx={{
                    mb: 3
                  }}>
                  * Required fields
                </Typography>
                <VehicleForm
                  renewal
                  currentVehicle={vehicleToEdit}
                  setFormData={setEditedVehicle}
                  error={error}
                />
                <Box mt={4} flex={1}>
                  <StyledTextField
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                    type="text"
                    multiline
                    rows={2}
                    fullWidth
                    label="Additional Comments"
                    id="comments"
                  />
                </Box>
                <Divider sx={{ my: 3 }} />
                <Box
                  marginTop={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center">
                  <Button
                    onClick={() => history.push(ROUTE_PATHS.SUBSIDY_SUBMIT)}
                    style={{
                      color: theme.palette.primary.dark,
                      fontSize: 16,
                      textDecorationLine: 'underline'
                    }}>
                    CANCEL
                  </Button>
                  <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                    <Button
                      onClick={() => setStep(0)}
                      sx={{
                        backgroundColor: 'white',
                        px: 2,
                        border: '1px solid black',
                        borderRadius: 0
                      }}
                      color="secondary"
                      variant="outlined">
                      BACK
                    </Button>
                    <Button
                      sx={{
                        px: 2,
                        borderRadius: 0
                      }}
                      color="secondary"
                      variant="contained"
                      onClick={() => handleVehicleAddition(true)}>
                      {isLoading ? <CircularProgress size={22} color="primary" /> : 'Submit'}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </TableContainer>
      <ErrorDialog
        content={requestError}
        open={!!requestError}
        onClose={() => setRequestError(null)}
      />
    </Container>
  )
}
