import React from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'
import { GenericCardContainer } from 'components'

const StyledStepCounterBox = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  backgroundColor: theme.palette.primary.light,
  width: 'max-content',
  paddingInline: 8,
  borderRadius: 2,
  marginBottom: 16
}))

export const Steps = ({
  currentStep,
  stepCount,
  title,
  subtitle,
  content
}: {
  currentStep: number
  stepCount: number
  title: string
  content?: React.ReactNode
  subtitle?: string
}) => {
  const theme = useTheme()
  return (
    <GenericCardContainer>
      <StyledStepCounterBox>
        <Typography variant="caption" sx={{ mb: 2 }}>
          Step {String(currentStep).padStart(2, '0')} / {String(stepCount).padStart(2, '0')}
        </Typography>
      </StyledStepCounterBox>

      <Typography
        fontWeight={700}
        fontSize={20}
        color={theme.palette.secondary.dark}
        sx={{ mb: 1 }}>
        {title}
      </Typography>

      {subtitle && (
        <Typography
          fontWeight={400}
          fontSize={16}
          color={theme.palette.primary.dark}
          sx={{
            mb: 1
          }}>
          {subtitle}
        </Typography>
      )}

      <Typography fontWeight={400} fontSize={12} color={theme.palette.secondary.dark}>
        * Required fields
      </Typography>

      <Box sx={{ mt: 4 }}>{content}</Box>
    </GenericCardContainer>
  )
}
