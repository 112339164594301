import * as React from 'react'
import { Breadcrumbs, Typography } from '@mui/material'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'

type Props = {
  paths?: {
    label: string
    path: string
  }[]
}

export function Breadcrumb({ paths = [] }: Props) {
  const lastPath = paths[paths.length - 1]
  const restPaths = paths.slice(0, paths.length - 1)
  return (
    <Breadcrumbs sx={{ display: 'flex', alignItems: 'center' }} aria-label="breadcrumb">
      {restPaths.map(({ label, path }) => (
        <NavLink
          key={path}
          to={path}
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'inherit',
            textDecoration: 'none',
            justifyContent: 'space-between'
          }}>
          <ArrowBackIosNewOutlined fontSize="small" />
          <Typography
            sx={{
              '&:hover': {
                textDecoration: 'underline'
              },
              marginLeft: 1
            }}>
            {label}
          </Typography>
        </NavLink>
      ))}
      <Typography
        sx={{
          fontWeight: 'bold'
        }}>
        {lastPath?.label}
      </Typography>
    </Breadcrumbs>
  )
}
