import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import NobelRegularWoff from 'assets/fonts/NobelRegular.woff'
import NobelRegularEot from 'assets/fonts/NobelRegular.eot'

let theme = createTheme({
  spacing: 8,
  palette: {
    warning: {
      main: '#FFE082'
    },
    error: {
      main: '#EF9A9A',
      dark: '#c23434'
    },
    success: {
      main: '#A5D6A7',
      dark: '#49914c'
    },
    primary: {
      main: '#FFFFFF',
      light: '#D7DFE1',
      dark: '#56595A',
      contrastText: '#000000'
    },
    secondary: {
      main: '#000000',
      contrastText: '#FFFFFF',
      light: '#F4F6F6',
      dark: '#292929'
    }
  },
  typography: {
    fontFamily: `"Nobel-Regular", sans-serif`,
    allVariants: {
      color: '#000000'
    },
    h4: {
      fontWeight: 700
    },
    body1: {
      color: '#56595A',
      fontWeight: 500
    }
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#69696B',
          '&.Mui-checked': {
            color: '#000000'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Nobel-Regular';
          font-style: normal;
          font-display: block;
          src: local('Nobel-Regular'), local('Nobel-Regular'), url(${NobelRegularWoff}) format('woff'), url(${NobelRegularEot})
        }
      `
    }
  }
})

theme = responsiveFontSizes(theme)

export default theme
