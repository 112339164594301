import React, { useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import {
  AccountCircleOutlined,
  AssignmentOutlined,
  DriveEtaOutlined,
  ExitToApp,
  ExpandMore,
  FolderOpenOutlined,
  GroupOutlined,
  HelpOutline,
  HouseOutlined,
  LabelOutlined,
  NotificationsOutlined
} from '@mui/icons-material'
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { CustomUserClaim } from '@okta/okta-auth-js/types/lib/oidc/types/UserClaims'
import { ROUTE_PATHS } from 'constants/Routing'
import { ROLES } from 'constants/Roles'

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  },
  '.MuiAccordionSummary-root': {
    padding: 0
  },
  '.MuiAccordionSummary-content': {
    margin: 0
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    margin: 0
  },
  '.MuiBox-root': {
    padding: '7px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: 1,
    cursor: 'pointer',
    borderRadius: '2px'
  },
  '.MuiAccordionDetails-root': {
    borderLeft: '3px solid #ABABB5',
    cursor: 'pointer',
    borderWidth: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px'
  }
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(2)
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light
}))

export const StyledExpandMore = styled(ExpandMore)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}))

export const StyledNavLink = styled(NavLink)(() => ({
  padding: '12px 12px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '2px',
  marginRight: '16px'
}))

export const SidebarLogout = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const theme = useTheme()

  const logout = async () => {
    if (authState.isAuthenticated) {
      sessionStorage.setItem('userData', 'logout')
      await oktaAuth.signOut({
        clearTokensBeforeRedirect: true,
        revokeAccessToken: true,
        revokeRefreshToken: true,
        postLogoutRedirectUri: process.env.RAZZLE_OKTA_TOYOTA_LOGOUT
      })
    }
  }

  return (
    <Box
      onClick={logout}
      sx={{
        marginTop: 'auto',
        borderTop: `1px solid ${theme.palette.secondary.light}`,
        width: '100%',
        paddingLeft: 2,
        paddingTop: 2,
        paddingBottom: 7,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        zIndex: -1
      }}>
      <SidebarOption>
        <Typography color="inherit" variant="body1">
          Log out
        </Typography>
      </SidebarOption>
      <ExitToApp sx={{ mr: 2, cursor: 'pointer' }} />
    </Box>
  )
}

export const SidebarOption = ({ children, onClick }: { children; onClick? }) => (
  <Box sx={{ mb: 3, mr: 2, cursor: 'pointer' }} onClick={onClick}>
    {children}
  </Box>
)

const SidebarCasesOption = ({ role }: { role: string | CustomUserClaim }) => {
  const isNewCases = useRouteMatch({ path: ROUTE_PATHS.NEW_CASE })
  const isCases = useRouteMatch({ path: ROUTE_PATHS.CASES })
  const history = useHistory()

  return (
    <StyledAccordion disableGutters defaultExpanded={Boolean(isNewCases) || Boolean(isCases)}>
      <StyledAccordionSummary expandIcon={<StyledExpandMore />}>
        <Box>
          <FolderOpenOutlined />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            Manage Cases
          </Typography>
        </Box>
      </StyledAccordionSummary>
      {role === ROLES.DEALER && (
        <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.NEW_CASE)}>
          <Typography color="inherit" variant="body1">
            <NavLink to={ROUTE_PATHS.NEW_CASE}>Create a Case</NavLink>
          </Typography>
        </StyledAccordionDetails>
      )}

      <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.CASES)}>
        <Typography color="inherit" variant="body1">
          <NavLink to={ROUTE_PATHS.CASES}>Update & Close Cases</NavLink>
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const SidebarReportsOption = () => {
  const history = useHistory()

  const isReportsAcceptanceRate = useRouteMatch({ path: ROUTE_PATHS.REPORTS_ACCEPTANCE_RATE })
  const isReportsDailyServiceManager = useRouteMatch({
    path: ROUTE_PATHS.REPORTS_DAILY_SERVICE_MANAGER
  })
  const isReportsLRA = useRouteMatch({ path: ROUTE_PATHS.REPORTS_LRA })

  return (
    <StyledAccordion
      disableGutters
      defaultExpanded={
        Boolean(isReportsAcceptanceRate) ||
        Boolean(isReportsDailyServiceManager) ||
        Boolean(isReportsLRA)
      }>
      <StyledAccordionSummary expandIcon={<StyledExpandMore />}>
        <Box>
          <AssignmentOutlined />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            Reports
          </Typography>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.REPORTS_ACCEPTANCE_RATE)}>
        <Typography color="inherit" variant="body1">
          <NavLink to={ROUTE_PATHS.REPORTS_ACCEPTANCE_RATE}>Acceptance Rate Report</NavLink>
        </Typography>
      </StyledAccordionDetails>
      <StyledAccordionDetails
        onClick={() => history.push(ROUTE_PATHS.REPORTS_DAILY_SERVICE_MANAGER)}>
        <Typography color="inherit" variant="body1">
          <NavLink to={ROUTE_PATHS.REPORTS_DAILY_SERVICE_MANAGER}>Daily Service Manager</NavLink>
        </Typography>
      </StyledAccordionDetails>
      <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.REPORTS_LRA)}>
        <Typography color="inherit" variant="body1">
          <NavLink to={ROUTE_PATHS.REPORTS_LRA}>LRA Report</NavLink>
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const SidebarDealersOption = () => {
  const history = useHistory()

  const isDealerSummary = useRouteMatch({ path: ROUTE_PATHS.DEALERS_SUMMARY })
  const isDealerDetails = useRouteMatch({ path: ROUTE_PATHS.DEALER_DETAILS })
  const isDealerEnrollment = useRouteMatch({ path: ROUTE_PATHS.DEALERS_ENROLLMENT })

  useEffect(() => {
    if (!isDealerEnrollment && (isDealerSummary || isDealerDetails)) {
      if (document.getElementById('dealerSummaryMenu')) {
        document.getElementById('dealerSummaryMenu').style.borderLeft = '4px solid black'
      }
    } else {
      if (document.getElementById('dealerSummaryMenu')) {
        document.getElementById('dealerSummaryMenu').style.borderLeft = '4px solid #ABABB5'
      }
    }
  }, [isDealerSummary, isDealerDetails])

  return (
    <StyledAccordion
      disableGutters
      defaultExpanded={
        Boolean(isDealerSummary) || Boolean(isDealerEnrollment) || Boolean(isDealerDetails)
      }>
      <StyledAccordionSummary expandIcon={<StyledExpandMore />}>
        <Box>
          <GroupOutlined />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            Dealers
          </Typography>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails
        id="dealerSummaryMenu"
        onClick={() => history.push(ROUTE_PATHS.DEALERS_SUMMARY)}>
        <Typography color="inherit" variant="body1">
          <NavLink to={ROUTE_PATHS.DEALERS_SUMMARY}>Dealers Summary</NavLink>
        </Typography>
      </StyledAccordionDetails>
      <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.DEALERS_ENROLLMENT)}>
        <Typography color="inherit" variant="body1">
          <NavLink to={ROUTE_PATHS.DEALERS_ENROLLMENT}>Enrollment Summary</NavLink>
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  )
}

const SidebarHomeOption = () => {
  const isHome = useRouteMatch({ path: ROUTE_PATHS.HOME })

  return (
    <Box sx={{ mt: 3 }}>
      <StyledNavLink to={ROUTE_PATHS.HOME}>
        <HouseOutlined />
        <Typography sx={{ fontWeight: isHome && 'bold', ml: 1 }} variant="subtitle1">
          Homepage
        </Typography>
      </StyledNavLink>
    </Box>
  )
}

const SidebarReplaceTopbarOption = () => {
  const isGetHelp = useRouteMatch({ path: ROUTE_PATHS.GET_HELP })
  const isNotifications = useRouteMatch({ path: ROUTE_PATHS.NOTIFICATIONS })
  const isMyAccount = useRouteMatch({ path: ROUTE_PATHS.PROFILE })

  return (
    <Box>
      <StyledNavLink to={ROUTE_PATHS.GET_HELP}>
        <HelpOutline />
        <Typography sx={{ fontWeight: isGetHelp && 'bold', ml: 1 }} variant="subtitle1">
          Get Help
        </Typography>
      </StyledNavLink>
      <StyledNavLink to={ROUTE_PATHS.NOTIFICATIONS}>
        <NotificationsOutlined />
        <Typography sx={{ fontWeight: isNotifications && 'bold', ml: 1 }} variant="subtitle1">
          Notifications
        </Typography>
      </StyledNavLink>
      <StyledNavLink to={ROUTE_PATHS.PROFILE}>
        <AccountCircleOutlined />
        <Typography sx={{ fontWeight: isMyAccount && 'bold', ml: 1 }} variant="subtitle1">
          My Account
        </Typography>
      </StyledNavLink>
    </Box>
  )
}

const SidebarManagersOption = () => {
  const isHome = useRouteMatch({ path: ROUTE_PATHS.MANAGERS })

  return (
    <Box>
      <StyledNavLink to={ROUTE_PATHS.MANAGERS}>
        <LabelOutlined />
        <Typography sx={{ fontWeight: isHome && 'bold', ml: 1 }} variant="subtitle1">
          Managers
        </Typography>
      </StyledNavLink>
    </Box>
  )
}

export const SidebarRoleOptions = ({ role }: { role: string | CustomUserClaim }) => {
  const SidebarSubsidyOption = () => {
    const history = useHistory()

    const isVehicles = useRouteMatch(ROUTE_PATHS.SUBSIDY_VEHICLES)
    const isVehicleDetails = useRouteMatch(ROUTE_PATHS.VEHICLE_DETAILS)
    const isRequest = useRouteMatch(ROUTE_PATHS.SUBSIDY_REQUESTS)
    const isSubmit = useRouteMatch(ROUTE_PATHS.SUBSIDY_SUBMIT)
    const isManage = useRouteMatch(ROUTE_PATHS.SUBSIDY_MANAGE)

    return (
      <StyledAccordion
        disableGutters
        defaultExpanded={
          Boolean(isVehicles) ||
          Boolean(isRequest) ||
          Boolean(isVehicleDetails) ||
          Boolean(isSubmit) ||
          Boolean(isManage)
        }>
        <StyledAccordionSummary expandIcon={<StyledExpandMore />}>
          <Box>
            <DriveEtaOutlined />
            <Typography sx={{ ml: 1 }} variant="subtitle1">
              Subsidy Program
            </Typography>
          </Box>
        </StyledAccordionSummary>
        <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.SUBSIDY_REQUESTS)}>
          <Typography color="inherit" variant="body1">
            <NavLink to={ROUTE_PATHS.SUBSIDY_REQUESTS}>View Requests</NavLink>
          </Typography>
        </StyledAccordionDetails>
        {role === ROLES.DEALER ? (
          <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.SUBSIDY_SUBMIT)}>
            <Typography color="inherit" variant="body1">
              <NavLink to={ROUTE_PATHS.SUBSIDY_SUBMIT}>Submit a Request</NavLink>
            </Typography>
          </StyledAccordionDetails>
        ) : (
          <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.SUBSIDY_MANAGE)}>
            <Typography color="inherit" variant="body1">
              <NavLink to={ROUTE_PATHS.SUBSIDY_MANAGE}>Manage Requests</NavLink>
            </Typography>
          </StyledAccordionDetails>
        )}

        <StyledAccordionDetails onClick={() => history.push(ROUTE_PATHS.SUBSIDY_VEHICLES)}>
          <Typography color="inherit" variant="body1">
            <NavLink to={ROUTE_PATHS.SUBSIDY_VEHICLES}>Vehicles</NavLink>
          </Typography>
        </StyledAccordionDetails>
      </StyledAccordion>
    )
  }

  const theme = useTheme()
  const isUpLargeWidth = useMediaQuery(theme.breakpoints.up('lg'))

  switch (role) {
    case ROLES.DEALER:
      return (
        <>
          <SidebarHomeOption />
          <SidebarCasesOption role={role} />
          <SidebarSubsidyOption />
          <SidebarReportsOption />
          {!isUpLargeWidth && <SidebarReplaceTopbarOption />}
        </>
      )
    case ROLES.MANAGER:
      return (
        <>
          <SidebarHomeOption />
          <SidebarCasesOption role={role} />
          <SidebarSubsidyOption />
          <SidebarDealersOption />
          <SidebarReportsOption />
          {!isUpLargeWidth && <SidebarReplaceTopbarOption />}
        </>
      )
    case ROLES.ADMIN:
      return (
        <>
          <SidebarHomeOption />
          <SidebarCasesOption role={role} />
          <SidebarSubsidyOption />
          <SidebarDealersOption />
          <SidebarManagersOption />
          <SidebarReportsOption />
          {!isUpLargeWidth && <SidebarReplaceTopbarOption />}
        </>
      )
  }
  return <></>
}
