import React from 'react'
import { ConfirmationDialog } from 'components'

export const UpdateCaseDialog = ({
  open,
  onClose,
  onConfirm,
  error
}: {
  open: boolean
  error?: boolean
  onClose: () => void
  onConfirm: () => void
}) => {
  return (
    <ConfirmationDialog
      icon={error ? 'error' : 'success'}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      onConfirmLabel={error ? 'CLOSE' : 'GO TO CASES'}
      title={error ? 'Something went wrong' : 'Case submitted successfully'}
      content={
        error
          ? 'Please check if your inputs are valid or try again later'
          : 'Your case will appear in the list of cases, on the Update & Close Cases section.'
      }
    />
  )
}
