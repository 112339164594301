import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { Typography } from '@mui/material'
import { Case } from 'hooks'
import { valueGetter } from 'utils'
import { ROLES } from 'constants/Roles'

export const OPEN_CASES_STATUS = 'CREATED,ACCEPTED'
export const CLOSED_CASES_STATUS = 'CLOSED,CANCELLED,COMPLETED'

export type CaseStatusFilter = typeof OPEN_CASES_STATUS | typeof CLOSED_CASES_STATUS
export type GetCasesParams = {
  limit: number
  status: any
  region: any
  caseNumber: number
  offset: number
  fromDate: string
  toDate: string
  warrantyClaimId: string
}

export const REGION_OPTIONS = [
  {
    label: 'All',
    value: null
  },
  { label: 'Western', value: '31' },
  { label: 'Central', value: '32' },
  { label: 'Eastern', value: '33' },
  { label: 'Southern', value: '34' }
]

export const dateValueFormatter = (params: { value: Case['dispatchedAt'] }) => {
  if (!params.value) {
    return '-'
  }

  const dispatchedAt = new Date(params.value)

  const date = format(dispatchedAt, 'MM/dd/yyyy')
  const time = format(dispatchedAt, 'hh:mm a')
  return `Date: ${date} - Time: ${time}`
}

export const regionValueFormatter = (params: { value: Case['region'] }) => {
  if (!params.value) {
    return '-'
  }

  return REGION_OPTIONS.find((region) => region.value === params.value)?.label || '-'
}

export const nameValueGetter = (params) => {
  if (!params.row.firstName && !params.row.lastName) {
    return '-'
  }

  return `${params.row.firstName} ${params.row.lastName}`
}

export const caseNameColumn = (params) => {
  return (
    <Link style={{ color: 'inherit' }} to={`/case/${params.row.id}`}>
      <Typography
        variant="body2"
        sx={{ textDecoration: 'underline', cursor: 'pointer', ':hover': { color: 'black' } }}>
        {params.value}
      </Typography>
    </Link>
  )
}

export const areaManagerColumns = [
  {
    flex: 1,
    field: 'firstName',
    valueGetter: nameValueGetter,
    renderCell: caseNameColumn,
    headerName: 'Guest Name',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'dispatchedAt',
    valueFormatter: dateValueFormatter,
    headerName: 'Dispatched',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'externalId',
    valueFormatter: valueGetter,
    headerName: 'Case Number',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'warrantyClaimId',
    valueFormatter: valueGetter,
    headerName: 'Warranty Claim ID',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'dealerName',
    valueFormatter: valueGetter,
    headerName: 'Dealer',
    headerClassName: 'casesHeaderRow'
  }
]

export const adminColumns = [
  {
    flex: 1,
    field: 'firstName',
    valueGetter: nameValueGetter,
    renderCell: caseNameColumn,
    headerName: 'Guest Name',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'dispatchedAt',
    valueFormatter: dateValueFormatter,
    headerName: 'Dispatched',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'externalId',
    valueFormatter: valueGetter,
    headerName: 'Case Number',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'warrantyClaimId',
    valueFormatter: valueGetter,
    headerName: 'Warranty Claim ID',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'dealerName',
    valueFormatter: valueGetter,
    headerName: 'Dealer',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'region',
    valueFormatter: regionValueFormatter,
    headerName: 'Region',
    headerClassName: 'casesHeaderRow'
  }
]

export const defaultColumns = [
  {
    flex: 1,
    field: 'firstName',
    valueGetter: nameValueGetter,
    renderCell: caseNameColumn,
    headerName: 'Guest Name',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'dispatchedAt',
    valueFormatter: dateValueFormatter,
    headerName: 'Dispatched',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'externalId',
    valueFormatter: valueGetter,
    headerName: 'Case Number',
    headerClassName: 'casesHeaderRow'
  },
  {
    flex: 1,
    field: 'warrantyClaimId',
    valueFormatter: valueGetter,
    headerName: 'Warranty Claim ID',
    headerClassName: 'casesHeaderRow'
  }
]

export const showColumns = (role) => {
  switch (role) {
    case ROLES.ADMIN:
      return adminColumns
    case ROLES.MANAGER:
      return areaManagerColumns
    default:
      return defaultColumns
  }
}
