import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import { DataGrid, GridColDef, DataGridProps } from '@mui/x-data-grid'
import { ErrorOutline } from '@mui/icons-material'

interface TableProps<T> {
  columns: GridColDef[]
  rows: T[]
  isLoading: boolean
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '.MuiDataGrid-cell': {
    color: theme.palette.primary.dark,
    width: '100%',
    cursor: 'default'
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  '.MuiDataGrid-virtualScroller': {
    minHeight: '250px'
  },
  border: 'none'
}))

export const Table = ({
  columns,
  rows,
  isLoading,
  checkboxSelection = true,
  ...props
}: TableProps<unknown> & DataGridProps) => (
  <Box sx={{ height: '100%', width: '100%' }}>
    <StyledDataGrid
      loading={isLoading}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      pageSizeOptions={[5, 10, 15, 20, 25, 30, 50]}
      checkboxSelection={checkboxSelection}
      slots={{
        noRowsOverlay: () => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <ErrorOutline sx={{ color: 'secondary', fontSize: 40 }} />
            <Typography variant="body1" sx={{ marginLeft: 2 }}>
              No data found with the selected filters.
            </Typography>
          </Box>
        )
      }}
      {...props}
    />
  </Box>
)
