import React, { useEffect, useState } from 'react'
import { DownloadOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button, PaddedFlexFullWidthContainer, SearchInput, Select } from 'components'
import { getSessionUser } from 'hooks'
import { ROLES } from 'constants/Roles'
import { ExportDealers } from '../ExportDealers'

export function EnrollmentSummaryFilters({ setFilteredData, data, selectedRows, disabled }) {
  const user = getSessionUser()
  const [region, setRegion] = useState('')
  const [status, setStatus] = useState('')
  const [district, setDistrict] = useState('')
  const [dealerNameOrCode, setDealerNameOrCode] = useState('')
  const [exporting, setExporting] = useState(false)

  useEffect(() => {
    const statusFiltered = status
      ? status === 'active'
        ? data?.filter((dealer) => dealer?.dls?.isEnrolled)
        : data?.filter((dealer) => !dealer?.dls?.isEnrolled)
      : data
    const regionFiltered = region
      ? statusFiltered?.filter((dealer) => dealer.divisionCode == region)
      : statusFiltered
    const districtFiltered = district
      ? regionFiltered?.filter((dealer) => dealer.districtID == district)
      : regionFiltered
    const dealerNameOrCodeFiltered = dealerNameOrCode
      ? districtFiltered?.filter(
          (dealer) =>
            dealer.name.toLowerCase().includes(dealerNameOrCode.toLowerCase()) ||
            dealer.id.toLowerCase().includes(dealerNameOrCode.toLowerCase())
        )
      : districtFiltered
    setFilteredData(dealerNameOrCodeFiltered)
  }, [status, region, district, dealerNameOrCode])

  function generateAvailableDistricts() {
    const availableDistricts = []
    for (let i = 1; i < 7; i++) {
      availableDistricts.push({ label: `0${i}`, value: i })
    }
    availableDistricts.unshift({ label: 'All', value: '' })
    return availableDistricts
  }

  function exportDealersSummary() {
    setExporting(true)
  }

  const managerFilters = () => (
    <PaddedFlexFullWidthContainer
      sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
      gap={4}>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="Status"
          defaultValue=""
          options={[
            { label: 'All', value: '' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' }
          ]}
          onChange={(e) => setStatus(e.target.value as string)}
          disabled={disabled}
        />
      </Box>
      {/* TODO To be defined by Lexus (dates) */}
      {/* <Box sx={{ flex: 1 }}>
        <DateRangePicker localeText={{ start: 'Start Date', end: 'End Date' }} />
      </Box> */}
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => setDealerNameOrCode(e.target.value)}
          inputlabel="Dealer Name or Code"
          sx={{ width: '100%' }}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 0 }}>
        <Button
          sx={{ borderRadius: 1, height: '80%' }}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={exportDealersSummary}>
          EXPORT
          <DownloadOutlined sx={{ marginLeft: 1 }} />
        </Button>
      </Box>
      <ExportDealers
        report="enrollment"
        selectedRows={selectedRows}
        setExporting={setExporting}
        exporting={exporting}
        data={data}
      />
    </PaddedFlexFullWidthContainer>
  )

  const adminFilters = () => (
    <PaddedFlexFullWidthContainer sx={{ flexWrap: 'wrap' }} gap={4}>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="Status"
          defaultValue=""
          options={[
            { label: 'All', value: '' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' }
          ]}
          onChange={(e) => setStatus(e.target.value as string)}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="Region"
          defaultValue=""
          options={[
            { label: 'All', value: '' },
            { label: 'Western', value: '31' },
            { label: 'Central', value: '32' },
            { label: 'Eastern', value: '33' },
            { label: 'Southern', value: '34' }
          ]}
          onChange={(e) => setRegion(e.target.value as string)}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="District"
          defaultValue=""
          options={data ? generateAvailableDistricts() : [{ label: 'All', value: '' }]}
          onChange={(e) => setDistrict(e.target.value as string)}
          disabled={disabled}
        />
      </Box>
      {/* To be defined by Lexus (dates) */}
      {/* <Box sx={{ flex: 1 }}>
        <DateRangePicker localeText={{ start: 'Start Date', end: 'End Date' }} />
      </Box> */}
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => setDealerNameOrCode(e.target.value)}
          inputlabel="Dealer Name or Code"
          disabled={disabled}
          sx={{ width: '100%' }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 0 }}>
        <Button
          sx={{ borderRadius: 1, height: '80%' }}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={exportDealersSummary}>
          EXPORT
          <DownloadOutlined sx={{ marginLeft: 1 }} />
        </Button>
      </Box>

      <ExportDealers
        report="enrollment"
        selectedRows={selectedRows}
        setExporting={setExporting}
        exporting={exporting}
        data={data}
      />
    </PaddedFlexFullWidthContainer>
  )

  if (user?.role == ROLES.ADMIN) return adminFilters()
  if (user?.role == ROLES.MANAGER) return managerFilters()
}
