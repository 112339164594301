import React from 'react'
import { FileDownloadOutlined } from '@mui/icons-material'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { DatePicker, GenericCardContainer } from 'components'
import { ReportFormDealerProps } from './ReportFormProps'
import { CSVLink } from 'react-csv'

export function ReportFormDealer({
  title,
  reportName,
  handleSubmit,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  completedOnly,
  setCompletedOnly,
  reportData,
  isLoadingReport,
  isErrorReport,
  handleDownloadPDF,
  handleDownloadCSV,
  handleClearInputs,
  dateRequired = true
}: ReportFormDealerProps) {
  const theme = useTheme()

  return (
    <GenericCardContainer>
      <Box>
        <Typography
          fontWeight={700}
          fontSize={20}
          color={theme.palette.secondary.dark}
          sx={{ mb: 1 }}>
          {title}
        </Typography>
        {dateRequired && (
          <Typography fontWeight={400} fontSize={12} color={theme.palette.secondary.dark}>
            * Required fields
          </Typography>
        )}
        <Box sx={{ mt: 4 }}>
          <form onSubmit={handleSubmit}>
            <Box display="flex">
              <Box flex={1}>
                <DatePicker
                  inputLabel="Start Date"
                  required={dateRequired}
                  onChange={(date: string) => setFromDate(date)}
                  value={fromDate}
                  dateFrom={fromDate}
                  dateTo={toDate}
                  closeOnSelect={true}
                  placement="bottom-end"
                />
              </Box>
              <Box flex={1} ml={3}>
                <DatePicker
                  inputLabel="End Date"
                  required={dateRequired}
                  onChange={(date: string) => setToDate(date)}
                  value={toDate}
                  minDate={fromDate}
                  dateFrom={fromDate}
                  dateTo={toDate}
                  closeOnSelect={true}
                  placement="bottom-end"
                />
              </Box>
            </Box>
            {setCompletedOnly && (
              <Box mt={2} display={'flex'} justifyContent={'end'}>
                <FormControlLabel
                  control={<Checkbox defaultChecked color="primary" />}
                  label="Completed only"
                  labelPlacement="start"
                  value={completedOnly}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCompletedOnly(e.target.checked as boolean)
                  }
                />
              </Box>
            )}
            {!reportData && (
              <Box mt={4} display={'flex'} justifyContent={'end'}>
                <Button type="submit" variant="contained" color="secondary">
                  {isLoadingReport && !isErrorReport ? (
                    <CircularProgress size={22} color="primary" />
                  ) : (
                    'RUN REPORT'
                  )}
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Box>
      {!isLoadingReport && reportData && !isErrorReport && (
        <Box sx={{ mt: 4 }}>
          <Typography
            fontWeight={700}
            fontSize={20}
            color={theme.palette.secondary.dark}
            sx={{ mb: 1 }}>
            Report
          </Typography>
          <Typography fontWeight={400} fontSize={16} color={theme.palette.primary.dark}>
            Click to view the report for, the report is PDF or CSV format.
          </Typography>
          <Box mt={4} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'}>
              <FileDownloadOutlined sx={{ mr: 1 }} />
              <Typography fontWeight={400} fontSize={18} color={theme.palette.secondary.dark}>
                {reportName}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Button type="button" variant="text" color="secondary" onClick={handleDownloadPDF}>
                DOWNLOAD PDF
              </Button>
              <CSVLink
                style={{ textDecoration: 'none' }}
                filename={`${new Date().toISOString()}-acceptance-rate-report.csv`}
                data={handleDownloadCSV()}>
                <Button type="button" variant="text" color="secondary">
                  DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Box>
          </Box>
          <Box mt={4} display={'flex'} justifyContent={'end'}>
            <Button type="button" variant="contained" color="secondary" onClick={handleClearInputs}>
              CLEAR INPUTS
            </Button>
          </Box>
        </Box>
      )}
    </GenericCardContainer>
  )
}
