import React from 'react'
import { Container, Loading } from 'components'
import { DealerInformation, LightServiceInformation, UserInformation } from './Widgets'
import { useGetDealers, getSessionUser } from 'hooks'
import { ROLES } from 'constants/Roles'
import { Box, Button, Typography } from '@mui/material'
import theme from 'theme'

const DealerInfo = ({ user }) => {
  const { data, isLoading, isError } = useGetDealers({ dealerCode: String(user.dealerCode) })

  function title() {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          My information
        </Typography>
        <a target="_blank" rel="noreferrer" href="https://admin.dis.toyota.com/">
          <Button
            style={{
              color: theme.palette.primary.dark,
              fontSize: 16,
              textDecorationLine: 'underline',
              border: 'none'
            }}
            color="secondary"
            variant="outlined">
            UPDATE MY INFORMATION
          </Button>
        </a>
      </Box>
    )
  }

  return (
    <Container title={title()}>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <>There was a problem when loading the information, please try again or contact support</>
      ) : (
        <>
          <DealerInformation data={data} />
          <LightServiceInformation data={data} />
        </>
      )}
    </Container>
  )
}

export function Profile() {
  const user = getSessionUser()
  const isDealer = user?.role === ROLES.DEALER

  if (!isDealer) {
    return (
      <Container title="My Information">
        <UserInformation data={user} />
      </Container>
    )
  }
  return <DealerInfo user={user} />
}
