import axios from 'axios'
import { useOktaAuth } from '@okta/okta-react'
import { runtimeConfig } from '../../config'

const baseURL = runtimeConfig.RAZZLE_DLS_API_URL

const useClient = () => {
  const client = axios.create({
    baseURL,
    headers: {
      'Content-type': 'application/json'
    }
  })

  const { authState } = useOktaAuth() || {}
  const token = `Bearer ${authState?.accessToken?.accessToken}`

  client.interceptors.request.use(
    (config) => {
      if (config.baseURL === baseURL) {
        if (token) {
          config.headers.Authorization = token
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  client.interceptors.response.use((response) => {
    return response.data
  })

  return client
}

export { useClient }
