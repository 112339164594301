import { useQuery } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { GetDocumentsResponse } from './GetDocumentsResponse'

export function useGetDocuments() {
  const client = useClient()

  return useQuery(['documents'], async () => {
    try {
      return (await client.get('/documents')) as GetDocumentsResponse
    } catch (error) {
      throw new Error(error)
    }
  })
}
