import theme from 'theme'

export function chipColor(status) {
  switch (status) {
    case 'pending':
      return { backgroundColor: theme.palette.primary.light }
    case 'managerApproved':
      return { backgroundColor: theme.palette.success.main }
    case 'adminApproved':
      return { backgroundColor: theme.palette.success.dark, color: 'white' }
    case 'managerDenied':
      return { backgroundColor: theme.palette.error.main }
    case 'adminDenied':
      return { backgroundColor: theme.palette.error.dark, color: 'white' }
    default:
      return { backgroundColor: theme.palette.primary.light }
  }
}

export function chipLabel(status) {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'managerApproved':
      return 'Manager Approved'
    case 'adminApproved':
      return 'Admin Approved'
    case 'managerDenied':
      return 'Manager Denied'
    case 'adminDenied':
      return 'Admin Denied'
    default:
      return 'Pending'
  }
}
