/* eslint-disable no-prototype-builtins */
import React, { Dispatch, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab'
import { timelineItemClasses } from '@mui/lab/TimelineItem'
import { DownloadOutlined, ImageOutlined } from '@mui/icons-material'
import { format } from 'date-fns'
import {
  Vehicle,
  useGetDealers,
  useGetVehicles,
  useUpdateVehicle,
  getSessionUser,
  UpdateVehicleRequest,
  useGetSubsidyRequests
} from 'hooks'
import { ROLES } from 'constants/Roles'
import { ROUTE_PATHS } from 'constants/Routing'
import theme from 'theme'
import { VehicleForm } from './VehicleForm'
import { SubmitRequestDropdown } from '../shared/SubmitRequestDropdown'
import {
  Breadcrumb,
  Container,
  Loading,
  GenericCardContainer,
  UnexpectedErrorMessage,
  NoDataFoundMessage
} from 'components'
import { RenderData, blobUrlToBase64, deepEqual, getDifferentAttributes, renderRegion } from 'utils'
import { VehiclePhotoDialog } from './VehiclePhotoDialog'
import { ExportSubsidyHistory } from './ExportSubsidyHistory'
import { chipColor, chipLabel } from '../shared/subsidyUtils'

function renderData(isLoading: boolean, isError: boolean, SucessNode) {
  if (isLoading) {
    return (
      <Loading
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.light}`,
          margin: 0,
          padding: 25,
          marginBottom: theme.spacing(2)
        }}
      />
    )
  } else if (isError) {
    return <Typography variant="body1">Error</Typography>
  } else {
    return SucessNode
  }
}

export function VehicleDetails() {
  const [exporting, setExporting] = useState(false)
  const user = getSessionUser()
  const { id } = useParams()
  const { data, isLoading, isError, refetch } = useGetVehicles({ id })
  const { mutateAsync: updateVehicle, isLoading: isUpdating } = useUpdateVehicle()
  const vehicle = data?.data[0]
  const {
    data: dealerData,
    isLoading: dealerLoading,
    isError: dealerError
  } = useGetDealers({ dealerCode: vehicle?.dealerCode })

  const dealer = dealerData?.foi && dealerData?.foi[0]

  const [editing, setEditing] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [newVehicleData, setNewVehicleData]: [
    UpdateVehicleRequest | Vehicle,
    Dispatch<React.SetStateAction<UpdateVehicleRequest | Vehicle>>
  ] = useState(vehicle)
  const {
    data: dataSubsidyRequest,
    isLoading: isLoadingSubsidyRequest,
    isError: isErrorSubsidyRequest
  } = useGetSubsidyRequests({
    mainVehicleId: id
  })

  const [errorMessage, setErrorMessage] = useState('')

  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (deepEqual(vehicle, newVehicleData)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [newVehicleData])

  async function handleVehicleUpdate() {
    const newData = { ...newVehicleData }
    setErrorMessage('')
    if (newVehicleData?.image) {
      await blobUrlToBase64(newVehicleData?.image)
        .then((base64String) => {
          newData.image = base64String as string
        })
        .catch((error) => {
          setErrorMessage('Error converting the image, please check it is a valid one')
          console.error('Conversion failed:', error)
        })
    }

    const updateData: UpdateVehicleRequest = getDifferentAttributes(newData, vehicle)

    const update = await updateVehicle(updateData)

    if (update.status === 'ok') {
      setEditing(false)
      refetch()
    } else {
      setErrorMessage('There was a problem updating the vehicle, please try again later')
    }
  }

  const hasSubsidyHistory =
    !isLoadingSubsidyRequest && !isErrorSubsidyRequest && dataSubsidyRequest?.data.length > 0

  return (
    <Container
      title={
        <Breadcrumb
          paths={[
            {
              path: ROUTE_PATHS.SUBSIDY_VEHICLES,
              label: 'Vehicles'
            },
            {
              path: ROUTE_PATHS.VEHICLE_DETAILS,
              label: 'Vehicle Details'
            }
          ]}
        />
      }>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Vehicle Details
      </Typography>
      {renderData(
        isLoading,
        isError,
        <>
          {editing ? (
            <GenericCardContainer>
              <VehicleForm renewal setFormData={setNewVehicleData} currentVehicle={vehicle} />
              <Box marginTop={3} display="flex" justifyContent="space-between">
                <Button
                  sx={{
                    backgroundColor: 'white',
                    px: 2,
                    border: '1px solid black',
                    borderRadius: 0
                  }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setEditing(false)
                    setErrorMessage('')
                  }}>
                  Cancel
                </Button>
                <Button
                  sx={{
                    px: 2,
                    borderRadius: 0
                  }}
                  disabled={disabled}
                  color="secondary"
                  variant="contained"
                  onClick={handleVehicleUpdate}>
                  {isUpdating ? <CircularProgress size={22} color="primary" /> : 'SAVE INFORMATION'}
                </Button>
              </Box>
            </GenericCardContainer>
          ) : (
            <GenericCardContainer display="flex" flexDirection="column" gap={3}>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ flexWrap: 'wrap' }}>
                <Box mb={2} display="flex" alignItems="center" gap={1.5}>
                  <Typography fontWeight="bold" variant="h6">
                    {vehicle?.make} {vehicle?.model}
                  </Typography>
                  {vehicle?.active ? (
                    <Chip
                      sx={{
                        borderRadius: 10,
                        pb: 0,
                        fontSize: 16,
                        backgroundColor: theme.palette.success.dark,
                        color: theme.palette.primary.main
                      }}
                      label="Active"
                      size="small"
                    />
                  ) : (
                    <Chip
                      sx={{
                        borderRadius: 10,
                        pb: 0,
                        fontSize: 16,
                        backgroundColor: theme.palette.error.dark,
                        color: theme.palette.primary.main
                      }}
                      label="Inactive"
                      size="small"
                    />
                  )}
                </Box>

                {user?.role === ROLES.DEALER && <SubmitRequestDropdown />}
              </Box>

              <Box
                display="flex"
                alignItems="center"
                gap={4}
                flexDirection={isDownMediumWidth ? 'column' : 'row'}>
                {vehicle?.imageUrl ? (
                  <VehiclePhotoDialog
                    DialogToggler={
                      <Box
                        width={250}
                        onClick={() => setOpenImage(true)}
                        height={250}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          border: `1px solid ${theme.palette.primary.light}`,
                          backgroundColor: theme.palette.secondary.light,
                          backgroundImage: `url(${vehicle?.imageUrl})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          cursor: 'pointer'
                        }}
                      />
                    }
                    setOpenImage={setOpenImage}
                    openImage={openImage}
                    image={vehicle?.imageUrl}
                  />
                ) : (
                  <Box
                    flex={1}
                    height={250}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      border: `1px solid ${theme.palette.primary.light}`,
                      backgroundColor: theme.palette.secondary.light
                    }}>
                    <ImageOutlined sx={{ fontSize: 60, color: theme.palette.primary.dark }} />
                    <Typography>Missing image</Typography>
                  </Box>
                )}
                <Box flex={1} marginLeft={4} display="flex" flexDirection="column" gap={1.5}>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">VIN:</Typography>
                    <Typography>{vehicle?.vin}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Make:</Typography>
                    <Typography>{vehicle?.make}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Model:</Typography>
                    <Typography>{vehicle?.model}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Year:</Typography>
                    <Typography>{vehicle?.year}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Miles:</Typography>
                    <Typography>{vehicle?.miles}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">DOFU:</Typography>
                    <Typography>
                      {vehicle?.dofu ? format(new Date(vehicle?.dofu), 'MM/dd/yyyy') : ''}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
              {user?.role === ROLES.DEALER && (
                <Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      onClick={() => setEditing(true)}
                      style={{
                        color: theme.palette.primary.dark,
                        fontSize: 16,
                        textDecorationLine: 'underline'
                      }}>
                      UPLOAD A NEW PHOTO
                    </Button>
                    <Button
                      style={{
                        color: theme.palette.primary.dark,
                        fontSize: 16,
                        textDecorationLine: 'underline'
                      }}
                      onClick={() => setEditing(true)}>
                      EDIT INFORMATION
                    </Button>
                  </Box>
                  <Typography sx={{ color: theme.palette.error.main }}>{errorMessage}</Typography>
                </Box>
              )}
              {user?.role !== ROLES.DEALER && (
                <Box flexDirection="column" flex={1} marginLeft={1} display="flex" gap={1}>
                  <Typography fontWeight="bold" variant="h6">
                    Dealer Owner
                  </Typography>
                  <RenderData
                    data={dealerData?.foi}
                    loading={dealerLoading}
                    error={dealerError}
                    ErrorState={<UnexpectedErrorMessage />}
                    LoadingState={<Loading sx={{ py: 5 }} />}
                    EmptyState={<NoDataFoundMessage />}
                    SuccessState={
                      <Box display="flex" width="100%" gap={10}>
                        <Box flexDirection="column" display="flex" gap={1.5}>
                          <Box display="flex" gap={1}>
                            <Typography fontWeight="bold">Name:</Typography>
                            <Link
                              style={{ color: 'inherit' }}
                              to={ROUTE_PATHS.DEALER_DETAILS.replace(':id', dealer?.id)}>
                              <Typography
                                sx={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  ':hover': { color: 'black' }
                                }}>
                                {dealer?.name}
                              </Typography>
                            </Link>
                          </Box>
                          <Box display="flex" gap={1}>
                            <Typography fontWeight="bold">Dealer Code:</Typography>
                            <Typography>{dealer?.id}</Typography>
                          </Box>
                        </Box>
                        <Box flexDirection="column" display="flex" gap={1}>
                          <Box display="flex" gap={1}>
                            <Typography fontWeight="bold">Region:</Typography>
                            <Typography>{renderRegion(dealer?.divisionCode)}</Typography>
                          </Box>
                          <Box display="flex" gap={1}>
                            <Typography fontWeight="bold">District:</Typography>
                            <Typography>{dealer?.districtID}</Typography>
                          </Box>
                        </Box>
                        <Box flexDirection="column" display="flex" gap={1}>
                          <Box display="flex" gap={1}>
                            <Typography fontWeight="bold">Status:</Typography>
                            <Typography>
                              {dealer?.dls.isEnrolled ? 'Active' : 'Inactive'}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={1}>
                            <Typography fontWeight="bold">Wireless:</Typography>
                            <Typography>{dealer?.dls.wireless ? 'Yes' : 'No'}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                  />
                </Box>
              )}
            </GenericCardContainer>
          )}
        </>
      )}

      <GenericCardContainer my={4} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight="bold" variant="h6">
            Vehicle Subsidy Requests History
          </Typography>
          <Button
            sx={{
              py: 1,
              px: 2,
              border: hasSubsidyHistory
                ? `1px solid ${theme.palette.primary.dark}`
                : `1px solid ${theme.palette.primary.light}`,
              borderRadius: 0
            }}
            disabled={!hasSubsidyHistory}
            type="submit"
            color="secondary"
            onClick={() => setExporting(true)}>
            EXPORT
            <DownloadOutlined sx={{ marginLeft: 1 }} />
          </Button>
          {!isLoadingSubsidyRequest && !isErrorSubsidyRequest && (
            <ExportSubsidyHistory
              selectedRows={dataSubsidyRequest?.data}
              setExporting={setExporting}
              exporting={exporting}
              data={dataSubsidyRequest?.data}
            />
          )}
        </Box>
        <RenderData
          data={dataSubsidyRequest?.data}
          loading={isLoadingSubsidyRequest}
          error={isErrorSubsidyRequest}
          ErrorState={<UnexpectedErrorMessage />}
          LoadingState={<Loading sx={{ py: 5 }} />}
          EmptyState={<NoDataFoundMessage sx={{ py: 5 }} />}
          SuccessState={
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0
                }
              }}>
              {dataSubsidyRequest?.data?.map((requisition, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator sx={{ mr: 1 }}>
                    <TimelineDot sx={{ my: 0 }} color="inherit" variant="outlined" />
                    <TimelineConnector sx={{ bgcolor: 'primary.dark' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 0, pb: 1 }}>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography variant="body2" component="div">
                        <Chip
                          size="small"
                          sx={{
                            borderRadius: 1,
                            py: 0,
                            fontSize: 14,
                            width: 'fit-content',
                            backgroundColor: 'primary'
                          }}
                          label={format(new Date(requisition.createdAt), 'MM-dd-yyyy')}
                        />
                      </Typography>
                      <Box display="flex" flexDirection="column" gap={0.5}>
                        <Box display="flex" gap={1}>
                          <Typography>Type:</Typography>
                          <Typography fontWeight="bold">
                            {requisition?.type.charAt(0).toUpperCase() + requisition?.type.slice(1)}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography>Status:</Typography>
                          <Chip
                            size="small"
                            sx={{
                              borderRadius: 1,
                              py: 0,
                              fontSize: 14,
                              width: 'fit-content',
                              ...chipColor(requisition?.requestStatuses[0].status)
                            }}
                            label={chipLabel(requisition?.requestStatuses[0].status)}
                          />
                        </Box>
                        <Box display="flex" gap={1}>
                          <Typography>Comments:</Typography>
                          <Typography>{requisition?.comments || '-'}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 0.5 }} />
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          }
        />
      </GenericCardContainer>
    </Container>
  )
}
