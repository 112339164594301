import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AppBar, Grid, SxProps, Theme, Toolbar, Typography, styled, useTheme } from '@mui/material'
import { AccountCircleOutlined, HelpOutline, NotificationsOutlined } from '@mui/icons-material'
import { ROUTE_PATHS } from 'constants/Routing'
import { Logo } from 'components'

interface AppBarProps {
  component?: string
  position?: string
  elevation?: number
  sx?: SxProps<Theme>
}

const StyledAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  padding: 0,
  height: 64,
  zIndex: 1200,
  position: 'fixed',
  a: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    padding: '7px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: 1,
    cursor: 'pointer',
    borderRadius: '5px'
  },
  '.MuiAccordionDetails-root:has(a.active)': {
    background: theme.palette.primary.light,
    borderLeftColor: theme.palette.primary.contrastText,
    'a.active': {
      color: theme.palette.primary.contrastText
    }
  }
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

export const Header = () => {
  const location = useLocation()
  const theme = useTheme()

  return (
    <StyledAppBar component="div" position="static" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Logo />
          <Grid item alignItems="center">
            <Typography color="inherit" component="span">
              <StyledGrid container sx={{ gap: 2 }}>
                <NavLink
                  to={ROUTE_PATHS.GET_HELP}
                  style={{
                    background:
                      location.pathname === ROUTE_PATHS.GET_HELP && theme.palette.secondary.light
                  }}>
                  <HelpOutline />
                  <Typography sx={{ ml: 1 }} variant="subtitle1">
                    Get Help
                  </Typography>
                </NavLink>
                <NavLink
                  to={ROUTE_PATHS.NOTIFICATIONS}
                  style={{
                    background:
                      location.pathname === ROUTE_PATHS.NOTIFICATIONS &&
                      theme.palette.secondary.light
                  }}>
                  <NotificationsOutlined />
                  <Typography sx={{ ml: 1 }} variant="subtitle1">
                    Notifications
                  </Typography>
                </NavLink>
                <NavLink
                  to={ROUTE_PATHS.PROFILE}
                  style={{
                    background:
                      location.pathname === ROUTE_PATHS.PROFILE && theme.palette.secondary.light
                  }}>
                  <AccountCircleOutlined />
                  <Typography sx={{ ml: 1 }} variant="subtitle1">
                    My Account
                  </Typography>
                </NavLink>
              </StyledGrid>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  )
}
