import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Box } from '@mui/material'
import { DatePicker, Select, TextFieldInput, TimePicker } from 'components'
import { SERVICES_OPTIONS } from 'constants/Services'

const customerSchema = yup.object({
  name: yup.string().required(),
  phone: yup.string().required(),
  service: yup.string().required()
})

export type CustomerData = yup.InferType<typeof customerSchema>

export type CustomerDataWithDate = CustomerData & {
  dispatched: string | unknown
}

type CustomerFormProps = {
  onSubmit: (data: CustomerDataWithDate) => void
  actions?: React.ReactNode
}

export const CustomerForm = ({ onSubmit, actions }: CustomerFormProps) => {
  const [date, setDate] = useState<string | object | unknown>(null)
  const [time, setTime] = useState<string | object | unknown>(null)

  const useYupValidationResolver =
    (validationSchema: yup.ObjectSchema<CustomerData>) => async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
    }

  const resolver = useYupValidationResolver(customerSchema)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver })

  return (
    <form
      onSubmit={handleSubmit((data: CustomerDataWithDate) => {
        data.dispatched = time
        onSubmit(data)
      })}>
      <Box display="flex">
        <Box flex={1}>
          <TextFieldInput
            fullWidth
            error={!!errors['name']}
            placeholder="Guest Name"
            inputLabel="Guest Name*"
            id="name"
            register={{ ...register('name') }}
          />
        </Box>
        <Box flex={1} ml={3}>
          <TextFieldInput
            fullWidth
            error={!!errors['phone']}
            placeholder="(000) 000-0000"
            inputLabel="Phone Number*"
            id="phone"
            register={{ ...register('phone') }}
          />
        </Box>
      </Box>
      <Box display="flex" mt={4}>
        <Box flex={1}>
          <DatePicker
            value={date}
            inputLabel="Dispatched"
            required
            onChange={(value) => {
              setDate(value)
              let timeDate = new Date(value as string)
              timeDate.setHours(new Date().getHours())
              timeDate.setMinutes(new Date().getMinutes())
              timeDate.setSeconds(new Date().getSeconds())
              setTime(timeDate)
            }}
          />
        </Box>
        <Box flex={1} ml={3}>
          <TimePicker
            disabled={!date}
            helperText={!date ? 'Choose a date first ' : null}
            value={time}
            required
            inputLabel="Time"
            onChange={(value) => {
              setTime(value)
            }}
          />
        </Box>
      </Box>
      <Box mt={4}>
        <Select
          inputlabel="Service Requested*"
          placeholder="Select a Service"
          fullWidth
          options={SERVICES_OPTIONS}
          error={!!errors['service']}
          id="service"
          register={{ ...register('service') }}
          defaultValue={''}
        />
      </Box>
      {actions}
    </form>
  )
}
