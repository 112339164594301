import React, { useId } from 'react'
import { InputAdornment, TextField, TextFieldProps, styled } from '@mui/material'
import { Search } from '@mui/icons-material'
import { StyledInputLabel } from 'components/Input'

export const StyledSearch = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}))

export const SearchInput = (props: TextFieldProps & { inputlabel: string }) => {
  const id = useId()

  return (
    <>
      <StyledInputLabel sx={{ fontSize: 14 }} htmlFor={id}>
        {props.inputlabel}
      </StyledInputLabel>
      <TextField
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledSearch />
            </InputAdornment>
          )
        }}
        {...props}
      />
    </>
  )
}
