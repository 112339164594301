export const MANAGERS_INFO = {
  EASTERN: [
    {
      name: "Jackie Lange",
      email: 'jaclyn.lange@lexus.com',
      area: 'Eastern Area'
    }
  ],
  WESTERN: [
    {
      name: 'Kimberly Stempkowski',
      email: 'kim.stempkowski@toyota.com',
      area: 'Western Area'
    },
    {
      name: 'David Dawood',
      email: 'david.dawood@toyota.com',
      area: 'Western Area'
    }
  ],
  CENTRAL: [
    {
      name: 'Taurest Dillard',
      email: 'taurest.dillard@toyota.com',
      area: 'Western Area'
    },
    {
      name: 'Chris Tenebehn',
      email: 'chris.tenebehn@toyota.com',
      area: 'Western Area'
    },
  ],
  SOUTHERN: [
    {
      name: 'Deborah Velazquez',
      email: 'deborah.velazquez@lexus.com',
      area: 'Southern Area'
    },
    {
      name: 'Christopher Reid',
      email: 'christopher.reid@toyota.com',
      area: 'Southern Area'
    },
  ]
}
