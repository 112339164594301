import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Loading,
  NoDataFoundMessage,
  Table,
  TableContainer,
  UnexpectedErrorMessage
} from 'components'
import { chipColor, chipLabel } from '../../shared/subsidyUtils'
import { renderRegion, valueGetter } from 'utils'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import {
  useDebounce,
  GetSubsidyRequests,
  useGetSubsidyRequests,
  getSessionUser,
  Request,
  RequestStatus
} from 'hooks'
import { ROLES } from 'constants/Roles'
import { RequestsFilters } from './RequestsFilters'
import { ROUTE_PATHS } from 'constants/Routing'
import { format } from 'date-fns'

const typeColumn = ({ row }) => {
  return (
    <Link
      style={{ color: 'inherit', fontWeight: 'bold' }}
      to={ROUTE_PATHS.SUBSIDY_REQUEST_DETAILS.replace(':id', row.id)}>
      {row.type.toUpperCase()}
    </Link>
  )
}

const vehicleColumn = ({ row }) => {
  return (
    <Link
      style={{ color: 'inherit' }}
      to={ROUTE_PATHS.VEHICLE_DETAILS.replace(':id', row?.mainVehicle?.id)}>
      <Typography
        variant="body2"
        sx={{ textDecoration: 'underline', cursor: 'pointer', ':hover': { color: 'black' } }}>
        {`${row?.mainVehicle?.make} ${row?.mainVehicle?.model}`}
      </Typography>
    </Link>
  )
}

export const statusValueGetter = (params) => {
  const status = params?.row?.requestStatuses?.length
    ? params?.row?.requestStatuses[0]?.status
    : null
  if (!status) {
    return '-'
  } else {
    return status
  }
}

export const vehicleValueGetter = (params) => {
  const vehicle = params?.row?.mainVehicle
  return `${vehicle?.make} ${vehicle?.model}`
}

const getStatus = (statuses: RequestStatus[], status) => {
  if (status) return status

  const adminStatus = statuses?.find(
    (status) => status.status === 'adminApproved' || status.status === 'adminDenied'
  )

  if (adminStatus) return adminStatus.status

  const managerStatus = statuses?.find(
    (status) => status.status === 'managerApproved' || status.status === 'managerDenied'
  )

  if (managerStatus) return managerStatus.status

  const pendingStatus = statuses?.find((status) => status.status === 'pending')

  if (pendingStatus) return pendingStatus.status
}

export function SubsidyRequests() {
  const theme = useTheme()
  const user = getSessionUser()
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [params, setParams]: [
    GetSubsidyRequests,
    React.Dispatch<React.SetStateAction<GetSubsidyRequests>>
  ] = useState({})
  const [selectedRows, setSelectedRows] = useState<Request[]>([])
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const debouncedSearchParams = useDebounce(params)

  const { data, isLoading, isError, refetch } = useGetSubsidyRequests({
    limit,
    offset,
    region: user?.role === ROLES.MANAGER ? user?.region?.toString() : null,
    dealerCode: user?.role === ROLES.DEALER ? user?.dealerCode?.toString() : null,
    ...debouncedSearchParams
  })

  const [paginationModel, setPaginationMode] = useState({
    page: offset / limit,
    pageSize: limit
  })

  const statusColumn = ({ row }) => {
    const status = getStatus(row?.requestStatuses, params?.status)
    return (
      <Chip
        sx={{
          borderRadius: 0.5,
          py: 0,
          fontSize: 14,
          ...chipColor(status)
        }}
        label={chipLabel(status)}
      />
    )
  }

  const dealerColumns = [
    {
      flex: 1,
      field: 'type',
      valueGetter,
      renderCell: typeColumn,
      headerName: 'Type',
      headerClassName: 'dealersHeaderRow'
    },
    {
      flex: 1,
      field: 'status',
      valueGetter: statusValueGetter,
      headerName: 'Status',
      renderCell: statusColumn,
      headerClassName: 'dealersHeaderRow',
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      flex: 1,
      field: 'mainVehicle',
      valueGetter: vehicleValueGetter,
      renderCell: vehicleColumn,
      headerName: 'Vehicle',
      headerClassName: 'dealersHeaderRow',
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      flex: 1,
      field: 'createdAt',
      valueGetter,
      headerName: 'Date',
      renderCell: ({ row }) => format(new Date(row.createdAt), "MM-dd-yyyy 'at' hh:mm a"),
      headerClassName: 'dealersHeaderRow'
    }
  ]

  const managerColumns = [
    {
      flex: 1,
      field: 'type',
      valueGetter,
      renderCell: typeColumn,
      headerName: 'Type',
      headerClassName: 'dealersHeaderRow'
    },
    {
      flex: 1,
      field: 'status',
      valueGetter: statusValueGetter,
      headerName: 'Status',
      renderCell: statusColumn,
      headerClassName: 'dealersHeaderRow',
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      flex: 1,
      field: 'dealerCode',
      valueGetter,
      headerName: 'Dealer Code',
      headerClassName: 'dealersHeaderRow'
    },
    {
      flex: 1,
      field: 'mainVehicle',
      valueGetter: vehicleValueGetter,
      renderCell: vehicleColumn,
      headerName: 'Vehicle',
      headerClassName: 'dealersHeaderRow',
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      flex: 1,
      field: 'createdAt',
      valueGetter,
      headerName: 'Date',
      renderCell: ({ row }) => format(new Date(row.createdAt), "MM-dd-yyyy 'at' hh:mm a"),
      headerClassName: 'dealersHeaderRow'
    }
  ]

  const adminColumns = [
    {
      flex: 1,
      field: 'type',
      valueGetter,
      renderCell: typeColumn,
      headerName: 'Type',
      headerClassName: 'dealersHeaderRow'
    },
    {
      flex: 1,
      field: 'status',
      valueGetter: statusValueGetter,
      headerName: 'Status',
      renderCell: statusColumn,
      headerClassName: 'dealersHeaderRow',
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      flex: 1,
      field: 'dealerCode',
      valueGetter,
      headerName: 'Dealer Code',
      headerClassName: 'dealersHeaderRow'
    },
    {
      flex: 1,
      field: 'region',
      valueGetter,
      headerName: 'Region',
      renderCell: (params) => renderRegion(params.value),
      headerClassName: 'dealersHeaderRow'
    },
    {
      flex: 1,
      field: 'mainVehicle',
      valueGetter: vehicleValueGetter,
      renderCell: vehicleColumn,
      headerName: 'Vehicle',
      headerClassName: 'dealersHeaderRow',
      sortComparator: (v1, v2) => v1.localeCompare(v2)
    },
    {
      flex: 1,
      field: 'createdAt',
      valueGetter,
      headerName: 'Date',
      renderCell: ({ row }) => format(new Date(row.createdAt), "MM-dd-yyyy 'at' hh:mm a"),
      headerClassName: 'dealersHeaderRow'
    }
  ]

  function handlePaginationChange(params) {
    setLimit(params.pageSize)
    setOffset(params.page * params.pageSize)
    setPaginationMode({ page: params.page, pageSize: params.pageSize })
    refetch()
  }

  function displayColumns() {
    if (user?.role == ROLES.ADMIN) {
      return adminColumns
    } else if (user?.role == ROLES.MANAGER) {
      return managerColumns
    } else if (user?.role == ROLES.DEALER) {
      return dealerColumns
    } else {
      return []
    }
  }

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    setSelectedRows(data.data.filter((row) => newRowSelectionModel.includes(row.id)))
  }

  return (
    <Container fullWidth title="View Requests">
      <TableContainer>
        <RequestsFilters
          params={params}
          setParams={setParams}
          selectedRows={selectedRows}
          data={data?.data}
          disabled={isLoading}
        />

        {isLoading && !data && <Loading />}
        {!isLoading && !data && !isError && <NoDataFoundMessage />}
        {!isLoading && data && (
          <Box sx={{ border: `1px solid ${theme.palette.primary.light}` }}>
            <Table
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowCount={data.itemsCount}
              isLoading={isLoading}
              columns={displayColumns()}
              rows={data.data}
            />
          </Box>
        )}
        {!isLoading && isError && <UnexpectedErrorMessage />}
      </TableContainer>
    </Container>
  )
}
