import React, { useEffect, useReducer, useState } from 'react'
import { Box, Button, Divider, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import { ErrorOutline, ImageOutlined } from '@mui/icons-material'
import { DatePicker, TextFieldInput } from 'components'
import { VehiclePhotoUpload } from './VehiclePhotoUpload'
import { Vehicle } from 'hooks/useGetVehicles/GetVehiclesResponse'
import { Vehicle as CreateVehicle } from 'hooks/useSubmitRequest/SubmitRequestRequest'
import { UpdateVehicleRequest } from 'hooks/useUpdateVehicle/UpdateVehicleRequest'
import { parseISO } from 'date-fns'
import { VehiclePhotoDialog } from './VehiclePhotoDialog'

interface VehicleFormProps {
  currentVehicle?: Vehicle
  setFormData: React.Dispatch<React.SetStateAction<UpdateVehicleRequest | Vehicle | CreateVehicle>>
  error?: string
  renewal?: boolean
}

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.primary.dark,
    marginTop: 2,
    fontSize: 15
  }
}))

export function validateVehicle(vehicle: Vehicle, onError: (error: string | null) => void) {
  if (!vehicle?.image && !vehicle?.imageUrl) {
    onError('Please upload a vehicle image.')
    return false
  }
  if (
    !vehicle?.vin ||
    !vehicle?.make ||
    !vehicle?.model ||
    !vehicle?.year ||
    !vehicle?.miles ||
    !vehicle?.dofu
  ) {
    onError('Please fill out all required fields.')
    return false
  }
  onError(null)
  return true
}

export function VehicleForm({ currentVehicle, setFormData, error, renewal }: VehicleFormProps) {
  const [upload, setUpload] = useState(false)
  const [newImage, setNewImage] = useState()
  const [openImage, setOpenImage] = useState(false)

  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_FIELD':
        return {
          ...state,
          [action.field]: action.value
        }
      case 'RESET_FIELDS':
        return currentVehicle
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, currentVehicle)

  useEffect(() => {
    if (newImage) {
      setFormData({ ...state, image: newImage })
    } else {
      setFormData({ ...state })
    }
  }, [state, newImage])

  const handleFieldChange = (field, value) => {
    dispatch({ type: 'UPDATE_FIELD', field, value })
  }

  return (
    <StyledBoxContainer display="flex" flexDirection="column" gap={3}>
      <Box
        display="flex"
        alignItems="center"
        gap={8}
        flexDirection={isDownMediumWidth ? 'column' : 'row'}>
        <Box
          display="flex"
          alignItems="center"
          gap={4}
          flexDirection={isDownMediumWidth ? 'column' : 'row'}>
          {currentVehicle?.imageUrl || newImage ? (
            <VehiclePhotoDialog
              DialogToggler={
                <Box
                  height={200}
                  width={250}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    border: `1px solid ${theme.palette.primary.light}`,
                    backgroundImage: `url(${newImage ? newImage : currentVehicle.imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => setOpenImage(true)}
                />
              }
              image={newImage ? newImage : currentVehicle.imageUrl}
              openImage={openImage}
              setOpenImage={setOpenImage}
            />
          ) : (
            <Box
              p={4}
              height={150}
              width={170}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                border: `1px solid ${theme.palette.primary.light}`,
                backgroundColor: theme.palette.secondary.light
              }}>
              <ImageOutlined sx={{ fontSize: 40, color: theme.palette.primary.dark }} />
              <Typography variant="body2">Missing image</Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography variant="h6">Upload a photo*</Typography>
          <Typography variant="body2">
            Upload a clear image of your vehicle fully visible.
          </Typography>
          <VehiclePhotoUpload
            DialogToggler={
              <Button
                onClick={() => setUpload(true)}
                sx={{
                  backgroundColor: 'white',
                  px: 2,
                  border: '1px solid black',
                  borderRadius: 0
                }}
                color="secondary"
                variant="outlined">
                UPLOAD A PHOTO
              </Button>
            }
            dialogState={[upload, setUpload]}
            setNewImage={setNewImage}
          />
        </Box>
      </Box>
      {error && error.includes('image') && (
        <Typography display="flex" alignItems="center" marginTop={2} color="red">
          <ErrorOutline sx={{ mr: 1 }} />
          {error}
        </Typography>
      )}
      <Divider sx={{ my: 1 }} />
      <Box display="flex" gap={5}>
        <Box flex={1}>
          <TextFieldInput
            onChange={(e) => handleFieldChange('vin', e.target.value)}
            value={state?.vin}
            fullWidth
            inputLabel="Vehicle Identification Number*"
            id="vin"
            disabled={renewal}
          />
        </Box>
        <Box flex={1}>
          <TextFieldInput
            onChange={(e) => handleFieldChange('make', e.target.value)}
            value={state?.make}
            fullWidth
            inputLabel="Make*"
            id="make"
          />
        </Box>
      </Box>
      <Box display="flex" gap={5}>
        <Box flex={1}>
          <TextFieldInput
            onChange={(e) => handleFieldChange('model', e.target.value)}
            value={state?.model}
            InputLabelProps={{ shrink: true }}
            fullWidth
            inputLabel="Model*"
            id="model"
          />
        </Box>
        <Box flex={1}>
          <TextFieldInput
            onChange={(e) => handleFieldChange('year', Number(e.target.value))}
            value={state?.year}
            type="number"
            fullWidth
            inputLabel="Year*"
            id="year"
          />
        </Box>
      </Box>
      <Box display="flex" gap={5}>
        <Box flex={1}>
          <TextFieldInput
            onChange={(e) => handleFieldChange('miles', Number(e.target.value))}
            value={state?.miles}
            type="number"
            fullWidth
            inputLabel="Miles*"
            id="miles"
          />
        </Box>
        <Box flex={1}>
          <DatePicker
            onChange={(v) => handleFieldChange('dofu', v)}
            value={parseISO(state?.dofu)}
            inputLabel="DOFU* (date-of-first-use)"
          />
        </Box>
      </Box>
      {error && !error.includes('image') && (
        <Typography display="flex" alignItems="center" marginTop={2} color="red">
          <ErrorOutline sx={{ mr: 1 }} />
          {error}
        </Typography>
      )}
    </StyledBoxContainer>
  )
}
