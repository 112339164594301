import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { CreateCaseResponse } from './CreateCaseResponse'
import { CreateCaseRequest } from './CreateCaseRequest'

export function useCreateCase() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: CreateCaseRequest) => {
      return (await client.post('/case', data)) as CreateCaseResponse
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['create-portal-case'])
        queryClient.setQueryData(['create-portal-case'], result)
        return result
      },
      onError: () => {
        queryClient.setQueryData(['create-portal-case'], false)
        return false
      }
    }
  )
}
