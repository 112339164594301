import React, { useMemo, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from './AppRoutes'
import theme from './theme'
import { AuthRequiredDialog } from './components'
import { runtimeConfig } from '../config'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false)

  const origin = typeof window !== 'undefined' && window?.location?.origin

  const oktaAuth = useMemo(
    () =>
      new OktaAuth({
        issuer: runtimeConfig.RAZZLE_OKTA_AUTH_SERVER_URL,
        clientId: runtimeConfig.RAZZLE_OKTA_CLIENT_ID,
        redirectUri: runtimeConfig.RAZZLE_OKTA_REDIRECT_URI,
        postLogoutRedirectUri: runtimeConfig.RAZZLE_OKTA_TOYOTA_LOGOUT,
        scopes: ['profile', 'openid', 'dls_users', 'email']
      }),
    [origin]
  )

  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.push(toRelativeUrl(originalUri || '/', window.location.origin))
    history.go()
  }

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect()
  }

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      sessionStorage.removeItem('userData')
      // App initialization stage
      await triggerLogin()
    } else {
      if (!(sessionStorage.getItem('userData') === 'logout')) {
        // Ask the user to trigger the login process during token autoRenew process
        setAuthRequiredModalOpen(true)
      }
    }
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={customAuthHandler}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <AuthRequiredDialog
              {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }}
            />
            <AppRoutes />
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Security>
  )
}

export default App
