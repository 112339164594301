import React from 'react'
import { ConfirmationDialog } from 'components'

export const NotFoundDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <ConfirmationDialog
      icon="error"
      open={open}
      onConfirm={onClose}
      onConfirmLabel="CLOSE"
      title="No Data Found"
      content="No data was found for this report. Please try again."
    />
  )
}
