import { useQuery } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { GetVehiclesResponse } from './GetVehiclesResponse'

interface GetVehicles {
  id?: number
  vin?: string
  make?: string
  model?: string
  year?: number
  miles?: number
  dofu?: Date | string
  imageUrl?: string
  region?: string
  district?: string
  dealerCode?: string
  dealerName?: string
  active?: boolean
  limit?: number
  offset?: number
}

export function useGetVehicles(params?: GetVehicles) {
  const client = useClient()

  return useQuery(['vehicles', { params }], async () => {
    for (const key in params) {
      if (params[key] === '') {
        delete params[key]
      }
    }
    try {
      return (await client.get('/vehicles', { params })) as GetVehiclesResponse
    } catch (error) {
      throw new Error(error)
    }
  })
}
