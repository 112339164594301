import React from 'react'
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip
} from '@mui/material'
import { Link } from 'react-router-dom'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab'
import { timelineItemClasses } from '@mui/lab/TimelineItem'
import { useParams } from 'react-router-dom'
import {
  Accordion,
  Container,
  Breadcrumb,
  Loading,
  NoDataFoundMessage,
  UnexpectedErrorMessage
} from 'components'
import { useGetDealers, useGetSubsidyRequests, useGetVehicles } from 'hooks'
import theme from 'theme'
import { ROUTE_PATHS } from 'constants/Routing'
import { renderRegion, renderTelephones, renderEmails, RenderData } from 'utils'
import { format } from 'date-fns'
import { chipColor, chipLabel } from 'pages/SubsidyProgram/shared/subsidyUtils'

export function DealerDetails() {
  const { id } = useParams()
  const { data, isLoading, isError } = useGetDealers({ dealerCode: id })
  const {
    data: requests,
    isLoading: loadingRequests,
    isError: errorRequests
  } = useGetSubsidyRequests({
    dealerCode: id,
    limit: 5
  })
  const {
    data: vehicles,
    isLoading: loadingVehicles,
    isError: errorVehicles
  } = useGetVehicles({
    dealerCode: id,
    active: true
  })
  const dealerInformation = () => {
    if (isLoading)
      return (
        <Accordion title="Dealership Information, Address and Enrollment Status">
          <Loading />
        </Accordion>
      )
    if (isError)
      return (
        <Accordion title="Dealership Information, Address and Enrollment Status">Error</Accordion>
      )
    return (
      <Accordion title="Dealership Information, Address and Enrollment Status">
        <Typography
          fontSize={18}
          fontWeight="bold"
          color="inherit"
          sx={{ textDecoration: 'underline' }}
          mb={1}>
          Dealership Information
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Name: <Typography>{data?.foi[0]?.name}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Dealer Code: <Typography>{data?.foi[0]?.id}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Region: <Typography>{renderRegion(data?.foi[0]?.divisionCode)}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          District: <Typography>{data?.foi[0]?.districtID}</Typography>
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        <Typography
          fontSize={18}
          fontWeight="bold"
          color="inherit"
          sx={{ textDecoration: 'underline' }}
          mb={1}>
          Address
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Street: <Typography>{data?.foi[0]?.location?.address?.addressLine}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          City, State, Zip:{' '}
          <Typography>{`${data?.foi[0]?.location?.address?.city}, ${data?.foi[0]?.location?.address?.state}, ${data?.foi[0]?.location?.address?.postalCode}`}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Country: <Typography>{data?.foi[0]?.location?.address?.country}</Typography>
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        <Typography
          fontSize={18}
          fontWeight="bold"
          color="inherit"
          sx={{ textDecoration: 'underline' }}
          mb={1}>
          Enrollment Status
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Status: <Typography>{data?.foi[0]?.dls.isEnrolled ? 'Active' : 'Inactive'}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Enrollment Date:{' '}
          <Typography>
            {data?.foi[0]?.dls.enrollmentDate
              ? new Date(data?.foi[0]?.dls.enrollmentDate).toLocaleDateString()
              : '-'}
          </Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Wireless: <Typography>{data?.foi[0]?.dls.wireless ? 'Yes' : 'No'}</Typography>
        </Typography>
      </Accordion>
    )
  }

  const lightServiceInformation = () => {
    const headerColumnStyle = { backgroundColor: theme.palette.secondary.light, fontWeight: 'bold' }
    if (isLoading)
      return (
        <Accordion title="Dealer Light Service Information">
          <Loading />
        </Accordion>
      )
    if (isError) return <Accordion title="Dealer Light Service Information">Error</Accordion>
    return (
      <Accordion title="Dealer Light Service Information">
        <Typography
          fontSize={18}
          fontWeight="bold"
          color="inherit"
          sx={{ textDecoration: 'underline' }}
          mb={1}>
          Contacts
        </Typography>
        {data?.foi[0]?.contacts?.map((contact, id) => (
          <Box key={id}>
            <Typography fontSize={16} fontWeight="bold" color="inherit" component="div">
              {contact.contactType}
            </Typography>
            <Typography
              display="flex"
              gap={1}
              fontWeight="semi-bold"
              color="secondary"
              component="div">
              Name: <Typography>-</Typography>
            </Typography>
            <Typography
              display="flex"
              gap={1}
              fontWeight="semi-bold"
              color="secondary"
              component="div">
              Email(s): <Typography>{renderEmails(contact.emails)}</Typography>
            </Typography>
            <Typography
              display="flex"
              gap={1}
              fontWeight="semi-bold"
              color="secondary"
              component="div">
              Telephone(s): <Typography>{renderTelephones(contact.phones)}</Typography>
            </Typography>

            <Divider sx={{ marginY: 1 }} />
          </Box>
        ))}
        <Typography
          fontSize={18}
          fontWeight="bold"
          color="inherit"
          sx={{ textDecoration: 'underline' }}
          my={2}>
          Service Information
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Travel Range:{' '}
          <Typography>
            {data?.foi[0]?.dls.travelRange
              ? `${data?.foi[0]?.dls.travelRange} miles`
              : 'Not specified'}
          </Typography>
        </Typography>
        <Typography fontWeight="semi-bold" color="secondary" mb={1}>
          Hours of Operations:
        </Typography>

        <TableContainer
          sx={{ border: `1px solid ${theme.palette.primary.light}`, width: '90%' }}
          elevation={0}
          component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={headerColumnStyle}>Days:</TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Monday
                </TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Tuesday
                </TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Wednesday
                </TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Thursday
                </TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Friday
                </TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Saturday
                </TableCell>
                <TableCell sx={headerColumnStyle} align="right">
                  Sunday
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">
                  Open Time:
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Monday')?.period.open ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Tuesday')?.period.open ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Wednesday')?.period
                    .open || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Thursday')?.period
                    .open || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Friday')?.period.open ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Saturday')?.period
                    .open || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Sunday')?.period.open ||
                    '-'}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">
                  Close Time:
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Monday')?.period.close ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Tuesday')?.period
                    .close || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Wednesday')?.period
                    .close || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Thursday')?.period
                    .close || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Friday')?.period.close ||
                    '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Saturday')?.period
                    .close || '-'}
                </TableCell>
                <TableCell align="right">
                  {data?.foi[0]?.operatingDays?.find((day) => day.day === 'Sunday')?.period.close ||
                    '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Accordion>
    )
  }

  const subsidiesInformation = () => {
    return (
      <Accordion title="Vehicles & Subsidy Requests">
        <Box mb={2} alignItems="flex-end" display="flex" gap={0.5}>
          <Typography
            fontSize={18}
            fontWeight="bold"
            color="inherit"
            sx={{ textDecoration: 'underline' }}>
            Vehicles:
          </Typography>
          <Typography variant="subtitle2">Vehicles being used at this dealership</Typography>
        </Box>

        <RenderData
          data={vehicles?.data}
          loading={loadingVehicles}
          error={errorVehicles}
          SuccessState={
            <TableContainer
              sx={{ width: 'fit-content', border: `1px solid ${theme.palette.primary.light}` }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.secondary.light }}>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>#</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>VIN</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>Make</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>Model</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>Year</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>Miles</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', py: 1 }}>DOFU</TableCell>
                </TableRow>
              </TableHead>
              {vehicles?.data.map((car, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    <Link
                      style={{ color: 'inherit' }}
                      to={ROUTE_PATHS.VEHICLE_DETAILS.replace(':id', car.id.toString())}>
                      {car.vin}
                    </Link>
                  </TableCell>
                  <TableCell>{car.make}</TableCell>
                  <TableCell>{car.model}</TableCell>
                  <TableCell>{car.year}</TableCell>
                  <TableCell>{car.miles}</TableCell>
                  <TableCell>{format(new Date(car.dofu), 'MM-dd-yyyy')}</TableCell>
                </TableRow>
              ))}
            </TableContainer>
          }
          LoadingState={
            <Loading
              sx={{
                my: 2,
                py: 5,
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.secondary.light
              }}
            />
          }
          ErrorState={
            <UnexpectedErrorMessage
              sx={{
                my: 2,
                py: 5,
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.secondary.light
              }}
            />
          }
          EmptyState={
            <NoDataFoundMessage
              sx={{
                my: 2,
                py: 5,
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.secondary.light
              }}
            />
          }
        />

        <Box alignItems="flex-end" display="flex" gap={0.5}>
          <Typography
            fontSize={18}
            fontWeight="bold"
            color="inherit"
            mt={3}
            sx={{ textDecoration: 'underline' }}>
            Subsidy Requests:
          </Typography>
          <Typography variant="subtitle2">Last five requests made by this dealer</Typography>
        </Box>
        <RenderData
          data={requests?.data}
          loading={loadingRequests}
          error={errorRequests}
          SuccessState={
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0
                }
              }}>
              {requests?.data?.map((requisition, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineSeparator sx={{ mr: 1 }}>
                      <TimelineDot sx={{ my: 0 }} color="inherit" variant="outlined" />
                      <TimelineConnector sx={{ bgcolor: 'primary.dark' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, pb: 1 }}>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="body2">
                          <Chip
                            size="small"
                            sx={{
                              borderRadius: 1,
                              py: 0,
                              fontSize: 14,
                              width: 'fit-content',
                              backgroundColor: 'primary'
                            }}
                            label={format(new Date(requisition.createdAt), 'MM-dd-yyyy')}
                          />
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={0.5}>
                          <Box display="flex" gap={1}>
                            <Typography>Type:</Typography>
                            <Typography fontWeight="bold">
                              {requisition?.type.charAt(0).toUpperCase() +
                                requisition?.type.slice(1)}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={1}>
                            <Typography>Status:</Typography>
                            <Chip
                              size="small"
                              sx={{
                                borderRadius: 1,
                                py: 0,
                                fontSize: 14,
                                width: 'fit-content',
                                ...chipColor(requisition?.requestStatuses[0]?.status)
                              }}
                              label={chipLabel(requisition?.requestStatuses[0]?.status)}
                            />
                          </Box>
                          <Box display="flex" gap={1}>
                            <Typography>Comments:</Typography>
                            <Typography>{requisition?.comments || '-'}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider sx={{ pt: 1, mb: 1 }} />
                    </TimelineContent>
                  </TimelineItem>
                )
              })}
            </Timeline>
          }
          LoadingState={
            <Loading
              sx={{
                my: 2,
                py: 5,
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.secondary.light
              }}
            />
          }
          ErrorState={
            <UnexpectedErrorMessage
              sx={{
                my: 2,
                py: 5,
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.secondary.light
              }}
            />
          }
          EmptyState={
            <NoDataFoundMessage
              sx={{
                my: 2,
                py: 5,
                border: '1px solid',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.secondary.light
              }}
            />
          }
        />
      </Accordion>
    )
  }

  return (
    <Container
      fullWidth
      title={
        <Breadcrumb
          paths={[
            {
              path: ROUTE_PATHS.DEALERS_SUMMARY,
              label: 'Dealers Summary'
            },
            {
              path: ROUTE_PATHS.DEALER_DETAILS,
              label: 'Dealer Details'
            }
          ]}
        />
      }>
      <Box sx={{ mx: 1 }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Dealer Details
        </Typography>
        <Box border={`0.8px solid ${theme.palette.primary.light}`}>{dealerInformation()}</Box>
        <Box border={`0.8px solid ${theme.palette.primary.light}`} sx={{ my: 2 }}>
          {lightServiceInformation()}
        </Box>
        <Box border={`0.8px solid ${theme.palette.primary.light}`}>{subsidiesInformation()}</Box>
      </Box>
    </Container>
  )
}
