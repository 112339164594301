import React, { useEffect, useState } from 'react'
import { addDays, format, isValid } from 'date-fns'
import { Box } from '@mui/material'
import { SearchInput, Select } from 'components'
import { useDebounce, getSessionUser } from 'hooks'
import { REGION_OPTIONS, GetCasesParams } from './CasesUtils'
import { ROLES } from 'constants/Roles'
import { DatePicker } from 'components'

export function CasesFilters({
  setGetCasesParams
}: {
  setGetCasesParams: (params: GetCasesParams) => void
}) {
  const [region, setRegion] = useState(null)
  const [caseNumberOrDealerName, setCaseNumberOrDealerName] = useState('')
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [warrantyClaimId, setWarrantyClaimId] = useState(null)
  const [openCalendars, setOpenCalendars] = useState(false)
  const debouncedCaseNumberOrDealerName = useDebounce(caseNumberOrDealerName)
  const user = getSessionUser()

  useEffect(() => {
    const getCasesParams = {
      region,
      caseNumber: debouncedCaseNumberOrDealerName,
      fromDate: dateFrom && isValid(dateFrom) && format(dateFrom, 'yyyy-MM-dd'),
      toDate: dateTo && isValid(dateTo) && format(addDays(dateTo, 1), 'yyyy-MM-dd'),
      warrantyClaimId
    } as GetCasesParams

    if (user?.role === ROLES.MANAGER) {
      getCasesParams['region'] = user?.region.toString()
    } else if (user?.role === ROLES.DEALER) {
      getCasesParams['dealerCode'] = user?.dealerCode.toString()
    }

    setGetCasesParams(getCasesParams)
  }, [region, dateFrom, dateTo, debouncedCaseNumberOrDealerName, warrantyClaimId])

  switch (user?.role) {
    case ROLES.ADMIN:
      return (
        <Box display="flex" flex={3} gap={3}>
          <Box sx={{ display: 'flex', gap: 4, marginRight: 2 }}>
            <Box>
              <DatePicker
                autoFocus={false}
                open={openCalendars}
                onOpen={() => setOpenCalendars(true)}
                inputLabel="Start Date"
                required={false}
                onAccept={(df) => {
                  setDateFrom(df)
                  setOpenCalendars(false)
                }}
                dateFrom={dateFrom}
                dateTo={dateTo}
                closeOnSelect={true}
                placement="bottom-end"
              />
            </Box>
            <Box>
              <DatePicker
                autoFocus={false}
                onOpen={() => setOpenCalendars(true)}
                open={openCalendars}
                inputLabel="End Date"
                minDate={dateFrom}
                required={false}
                onAccept={(dt) => {
                  setDateTo(dt)
                  setOpenCalendars(false)
                }}
                dateFrom={dateFrom}
                dateTo={dateTo}
                closeOnSelect={true}
                placement="bottom-start"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box sx={{ flex: 1 }}>
              <Select
                sx={{ width: '100%' }}
                inputlabel="Region"
                placeholder="Select Region"
                defaultValue={'all'}
                onChange={(e) => setRegion(e.target.value as string)}
                options={REGION_OPTIONS}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <SearchInput
                onChange={(e) => setCaseNumberOrDealerName(e.target.value)}
                inputlabel="Case Number or Dealer Name"
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <SearchInput
                onChange={(e) => setWarrantyClaimId(e.target.value)}
                inputlabel="Warranty Claim ID"
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
      )
    case ROLES.MANAGER:
      return (
        <Box sx={{ display: 'flex', gap: 6 }}>
          <Box sx={{ display: 'flex', gap: 4, marginRight: 2 }}>
            <Box>
              <DatePicker
                autoFocus={false}
                open={openCalendars}
                onOpen={() => setOpenCalendars(true)}
                inputLabel="Start Date"
                required={false}
                onAccept={(df) => {
                  setDateFrom(df)
                  setOpenCalendars(false)
                }}
                dateFrom={dateFrom}
                dateTo={dateTo}
                closeOnSelect={true}
                placement="bottom-end"
              />
            </Box>
            <Box>
              <DatePicker
                autoFocus={false}
                onOpen={() => setOpenCalendars(true)}
                open={openCalendars}
                inputLabel="End Date"
                minDate={dateFrom}
                required={false}
                onAccept={(dt) => {
                  setDateTo(dt)
                  setOpenCalendars(false)
                }}
                dateFrom={dateFrom}
                dateTo={dateTo}
                closeOnSelect={true}
                placement="bottom-start"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box>
              <SearchInput
                onChange={(e) => setCaseNumberOrDealerName(e.target.value)}
                inputlabel="Case Number or Dealer Name"
                sx={{ width: '100%' }}
              />
            </Box>
            <Box>
              <SearchInput
                onChange={(e) => setWarrantyClaimId(e.target.value)}
                inputlabel="Warranty Claim ID"
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
      )
    default:
      return (
        <Box sx={{ display: 'flex', gap: 6 }}>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box>
              <DatePicker
                autoFocus={false}
                open={openCalendars}
                onOpen={() => setOpenCalendars(true)}
                inputLabel="Start Date"
                required={false}
                onAccept={(df) => {
                  setDateFrom(df)
                  setOpenCalendars(false)
                }}
                dateFrom={dateFrom}
                dateTo={dateTo}
                closeOnSelect={true}
                placement="bottom-end"
              />
            </Box>
            <Box>
              <DatePicker
                autoFocus={false}
                onOpen={() => setOpenCalendars(true)}
                open={openCalendars}
                inputLabel="End Date"
                minDate={dateFrom}
                required={false}
                onAccept={(dt) => {
                  setDateTo(dt)
                  setOpenCalendars(false)
                }}
                dateFrom={dateFrom}
                dateTo={dateTo}
                closeOnSelect={true}
                placement="bottom-start"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Box>
              <SearchInput
                onChange={(e) => setCaseNumberOrDealerName(e.target.value)}
                inputlabel="Case Number"
                sx={{ width: '100%' }}
              />
            </Box>
            <Box>
              <SearchInput
                onChange={(e) => setWarrantyClaimId(e.target.value)}
                inputlabel="Warranty Claim ID"
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Box>
      )
  }
}
