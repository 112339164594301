import React from 'react'
import { Button, GenericCardContainer } from 'components'
import { Divider, Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { format, subDays } from 'date-fns'
import { ROUTE_PATHS } from 'constants/Routing'
import { GetCasesParams, getSessionUser, useGetCases, useGetSubsidyRequests } from 'hooks'
import { CLOSED_CASES_STATUS, OPEN_CASES_STATUS } from 'pages/Cases'
import { ROLES } from 'constants/Roles'

interface WidgetLayoutProps {
  title: string
  subtitle?: string
  actionLabel?: string
  actionButtonText?: string
  actionLinkPath?: string
  buttonColor?: 'secondary' | 'inherit' | 'primary' | 'success' | 'error' | 'info' | 'warning'
  buttonVariant?: 'contained' | 'outlined'
  centered?: boolean
}

interface AlertLayoutProps {
  label: string
  actionLabel?: string
  actionButtonText?: string
  actionLinkPath?: string
  buttonColor?: 'secondary' | 'inherit' | 'primary' | 'success' | 'error' | 'info' | 'warning'
  buttonVariant?: 'contained' | 'outlined'
  centered?: boolean
}

export function AlertLayout({
  label,
  actionLinkPath,
  buttonColor = 'secondary',
  buttonVariant = 'outlined',
  actionButtonText,
  centered = false
}: AlertLayoutProps) {
  const theme = useTheme()

  return (
    <GenericCardContainer
      sx={{ backgroundColor: theme.palette.warning.main, borderColor: theme.palette.primary.dark }}>
      <Grid
        container
        sx={{
          justifyContent: centered ? 'center' : 'space-between',
          alignItems: 'baseline',
          gap: centered ? 2 : 0
        }}>
        <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="h6" fontWeight={800}>
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <NavLink to={actionLinkPath}>
            <Button variant={buttonVariant} color={buttonColor}>
              {actionButtonText}
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </GenericCardContainer>
  )
}

export function WidgetLayout({
  title,
  subtitle,
  actionLabel,
  actionButtonText,
  buttonColor = 'secondary',
  buttonVariant = 'outlined',
  actionLinkPath,
  centered = false
}: WidgetLayoutProps) {
  const theme = useTheme()
  return (
    <GenericCardContainer>
      <Typography variant="h6" fontWeight={800}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        {subtitle}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid
        container
        sx={{
          justifyContent: centered ? 'center' : 'space-between',
          alignItems: 'baseline',
          gap: centered ? 2 : 0
        }}>
        <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.contrastText, fontWeight: 'bold' }}>
            {actionLabel}
          </Typography>
        </Grid>
        <Grid item>
          <NavLink to={actionLinkPath}>
            <Button variant={buttonVariant} color={buttonColor}>
              {actionButtonText}
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </GenericCardContainer>
  )
}

export function CreateCaseWidget({ subtitle }: { subtitle: string }) {
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')
  return (
    <WidgetLayout
      title="Create a Case"
      subtitle={subtitle}
      actionLabel="Start creating your case"
      actionButtonText="CREATE NOW"
      buttonColor="secondary"
      buttonVariant="contained"
      actionLinkPath={ROUTE_PATHS.NEW_CASE}
      centered={isDownSmallWidth}
    />
  )
}

export function SubsidyProgramWidget({ subtitle }: { subtitle: string }) {
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')
  return (
    <WidgetLayout
      title="Subsidy Program"
      subtitle={subtitle}
      actionLabel="View Subsidy Information"
      actionButtonText="SEE MORE"
      actionLinkPath={ROUTE_PATHS.SUBSIDY_REQUESTS}
      centered={isDownSmallWidth}
    />
  )
}

export function EnrollmentStatusWidget() {
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')
  return (
    <WidgetLayout
      title="Enrollment Status"
      subtitle="Check your status"
      actionLabel="View your information"
      actionButtonText="SEE MORE"
      actionLinkPath={ROUTE_PATHS.PROFILE}
      centered={isDownSmallWidth}
    />
  )
}

export function DealersWidget() {
  return (
    <WidgetLayout
      title="Dealers Summary"
      subtitle="Check light services information."
      actionLabel="See the information"
      actionButtonText="SEE MORE"
      actionLinkPath={ROUTE_PATHS.DEALERS_SUMMARY}
    />
  )
}

export function OpenCaseAlertWidget() {
  const user = getSessionUser()
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')

  const getCasesParams = {
    status: OPEN_CASES_STATUS
  } as GetCasesParams

  if (user?.role === ROLES.MANAGER) {
    getCasesParams['region'] = user?.region.toString()
  } else if (user?.role === ROLES.DEALER) {
    getCasesParams['dealerCode'] = user?.dealerCode.toString()
  }

  const { data, isLoading, isError } = useGetCases(getCasesParams)

  let label = `You have ${data?.itemsCount} open cases`
  if (isLoading) {
    label = 'Loading...'
  }
  if (isError) {
    label = 'There was a problem when loading the cases'
  }
  return (
    <AlertLayout
      label={label}
      actionButtonText="UPDATE CASES NOW"
      buttonColor="secondary"
      buttonVariant="contained"
      actionLinkPath={ROUTE_PATHS.CASES}
      centered={isDownSmallWidth}
    />
  )
}

export function ClosedCaseAlertWidget() {
  const periodCheck = new Date(subDays(new Date(), 30)) //check up until last month
  const user = getSessionUser()
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')

  const getCasesParams = {
    status: CLOSED_CASES_STATUS,
    fromDate: format(periodCheck, 'yyyy-MM-dd')
  } as GetCasesParams

  if (user?.role === ROLES.MANAGER) {
    getCasesParams['region'] = user?.region.toString()
  } else if (user?.role === ROLES.DEALER) {
    getCasesParams['dealerCode'] = user?.dealerCode.toString()
  }

  const { data, isLoading, isError } = useGetCases(getCasesParams)

  let label = `There were ${data?.itemsCount} cases closed since last month`

  if (isLoading) {
    label = 'Loading...'
  }
  if (isError) {
    label = 'There was a problem when loading the cases'
  }

  return (
    <AlertLayout
      label={label}
      actionButtonText="REVIEW NOW"
      buttonColor="secondary"
      buttonVariant="contained"
      actionLinkPath={ROUTE_PATHS.CASES}
      centered={isDownSmallWidth}
    />
  )
}

export function ManagerSubsidyAlertWidget() {
  let label
  const user = getSessionUser()
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')

  const { data, isLoading, isError } = useGetSubsidyRequests({
    status: 'pending',
    region: user?.role === ROLES.MANAGER ? user?.region?.toString() : null
  })

  if (isLoading) {
    label = 'Loading...'
  }

  if (data && !isLoading && !isError) {
    label = `There are ${data.itemsCount} Subsidy requests waiting for your action.`
  }

  if (isError) {
    label = 'There was a problem when loading the requests'
  }

  return (
    <AlertLayout
      label={label}
      actionButtonText="REVIEW NOW"
      buttonColor="secondary"
      buttonVariant="contained"
      actionLinkPath={ROUTE_PATHS.SUBSIDY_MANAGE}
      centered={isDownSmallWidth}
    />
  )
}

export function AdminSubsidyAlertWidget() {
  let label: string
  const isDownSmallWidth = useMediaQuery('(max-width:400px)')
  const {
    data: dataManagerApproved,
    isLoading,
    isError
  } = useGetSubsidyRequests({ status: 'managerApproved' })

  if (isLoading) {
    label = 'Loading...'
  }

  if (dataManagerApproved && !isLoading && !isError) {
    label = `There are ${dataManagerApproved.itemsCount} Subsidy requests waiting for your action.`
  }

  if (isError) {
    label = 'There was a problem when loading the requests'
  }

  return (
    <AlertLayout
      label={label}
      actionButtonText="REVIEW NOW"
      buttonColor="secondary"
      buttonVariant="contained"
      actionLinkPath={ROUTE_PATHS.SUBSIDY_MANAGE}
      centered={isDownSmallWidth}
    />
  )
}
