import React from 'react'
import { ConfirmationDialog } from 'components'

export const OpenCaseDialog = ({
  open,
  onClose,
  onConfirm
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}) => {
  return (
    <ConfirmationDialog
      icon="success"
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      onConfirmLabel="GO TO CASES"
      title="Case opened successfully."
      content="Your case will appear in the list of cases, on the Update &amp; Close Cases section."
    />
  )
}
