import { useClient } from 'hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ReportRequest } from './ReportRequest'
import { ReportLRAResponse } from './ReportLRAResponse'
import { format } from 'date-fns'

export function useReportLRA() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: ReportRequest) => {
      const response = (await client.get('/report/lra', {
        params: {
          fromDate: format(new Date(data.fromDate), 'yyyy-MM-dd'),
          toDate: format(new Date(data.toDate), 'yyyy-MM-dd'),
          area: data.area,
          district: data.district,
          dealerId: data.dealerId
        }
      })) as ReportLRAResponse
      return response
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(['lra-data'], result)
        return result
      },
      onError: () => {
        return false
      }
    }
  )
}
