import React, { ReactElement, ReactNode } from 'react'
import { Box, Grid, Theme, Typography, styled } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface BoxProps {
  theme?: Theme
  dark?: boolean
}

interface ActionItemProps {
  title: string
  children?: string | ReactElement | ReactElement[]
  action?: ReactNode
  dark?: boolean
}

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'dark' })<BoxProps>(
  ({ theme, dark }) => ({
    margin: 0,
    borderTop: `1px ${dark ? theme.palette.primary.dark : theme.palette.primary.light} solid`,
    padding: 20,
    backgroundColor: `${dark ? theme.palette.secondary.main : 'unset'}`,
    color: `${dark ? theme.palette.primary.main : 'unset'}`,
    ...(dark && {
      '&:first-of-type': { borderTopLeftRadius: '4px', borderTopRightRadius: '4px' },
      '&:last-child': { borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }
    })
  })
)

export const ActionItem = ({ title, children, action, dark }: ActionItemProps) => (
  <StyledBox dark={dark}>
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item alignItems="center">
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </Grid>
      {action && (
        <Grid item alignItems="center" sx={{ cursor: 'pointer' }}>
          <Grid container>
            <Grid item>{action}</Grid>
            {!dark && (
              <Grid item>
                <ChevronRightIcon />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
    {children}
  </StyledBox>
)
