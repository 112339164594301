import { useClient } from 'hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ReportRequest } from './ReportRequest'
import { ReportDailyServiceResponse } from './ReportDailyServiceResponse'
import { format } from 'date-fns'

export function useReportDailyService() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: ReportRequest) => {
      const response = (await client.get('/report/daily-service', {
        params: {
          fromDate: format(new Date(data.fromDate), 'yyyy-MM-dd'),
          toDate: format(new Date(data.toDate), 'yyyy-MM-dd'),
          completed: data.completed || null,
          area: data.area || null,
          district: data.district || null,
          dealerId: data.dealerId || null
        }
      })) as ReportDailyServiceResponse[]
      return response
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(['daily-service-data'], result)
        return result
      },
      onError: () => {
        return false
      }
    }
  )
}
