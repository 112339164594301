import React from 'react'
import { ConfirmationDialog } from 'components'

interface AuthRequiredDialogProps {
  authRequiredModalOpen: boolean
  setAuthRequiredModalOpen: (boolean) => void
  triggerLogin: () => void
}

export const AuthRequiredDialog = ({
  authRequiredModalOpen,
  setAuthRequiredModalOpen,
  triggerLogin
}: AuthRequiredDialogProps) => {
  const confirmModal = () => {
    setAuthRequiredModalOpen(false)
    triggerLogin()
  }

  return (
    <ConfirmationDialog
      onClose={confirmModal}
      onConfirm={confirmModal}
      onConfirmLabel="OK"
      open={authRequiredModalOpen}
      title="Authentication"
      content="Renew of authorization token required"
    />
  )
}
