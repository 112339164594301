import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { SubmitRequestResponse } from './SubmitRequestResponse'
import { SubmitRequestRequest } from './SubmitRequestRequest'

interface CreateVehicleResponse {
  status: string
  vehicleId: number
}

export function useSubmitRequest() {
  const queryClient = useQueryClient()
  const client = useClient()

  async function addVehicle(data: SubmitRequestRequest) {
    if (data.existing) {
      if (data?.mainVehicle?.image === undefined) delete data.mainVehicle.image
      return (await client.put('/vehicles', data.mainVehicle)) as CreateVehicleResponse
    }
    {
      return (await client.post('/vehicles', data.mainVehicle)) as CreateVehicleResponse
    }
  }

  async function addReplacementVehicle(data: SubmitRequestRequest) {
    if (data.existing) {
      if (data?.secondaryVehicle?.image === undefined) delete data.secondaryVehicle.image
      return (await client.put('/vehicles', data.secondaryVehicle)) as CreateVehicleResponse
    }
    {
      return (await client.post('/vehicles', data.secondaryVehicle)) as CreateVehicleResponse
    }
  }

  return useMutation(
    async (data: SubmitRequestRequest) => {
      if (data.type === 'addition') {
        const vehicleResponse = await addVehicle(data)
        if (vehicleResponse?.vehicleId || data?.mainVehicle?.id) {
          data.mainVehicleId = vehicleResponse?.vehicleId
            ? vehicleResponse.vehicleId
            : data?.mainVehicle?.id
          delete data.mainVehicle
          delete data.existing
          return (await client.post('/subsidy-requests', data)) as SubmitRequestResponse
        }
      } else if (data.type === 'replacement') {
        const vehicleResponse = await addReplacementVehicle(data)
        if (vehicleResponse?.vehicleId || data?.secondaryVehicle?.id) {
          data.secondaryVehicleId = vehicleResponse?.vehicleId
            ? vehicleResponse.vehicleId
            : data?.secondaryVehicle?.id
          delete data.secondaryVehicle
          delete data.existing
          return (await client.post('/subsidy-requests', data)) as SubmitRequestResponse
        }
      } else if (data.type === 'renewal') {
        await client.put('/vehicles', data.mainVehicle)
        delete data.mainVehicle
        return (await client.post('/subsidy-requests', data)) as SubmitRequestResponse
      } else if (data.type === 'removal') {
        return (await client.post('/subsidy-requests', data)) as SubmitRequestResponse
      }
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['submitRequest'])
        queryClient.setQueryData(['submitRequest'], result)
        return result
      },
      onError: () => {
        queryClient.setQueryData(['submitRequest'], false)
        return false
      }
    }
  )
}
