export const SERVICES_OPTIONS = [
  { value: '', label: 'Select a service' },
  { value: 'T1', label: 'Flat' },
  { value: 'T3', label: 'Battery' },
  { value: 'T5', label: 'Gas' },
  { value: 'T6', label: 'Tow' },
  { value: 'T7', label: 'Lockout' },
  { value: 'T8', label: 'Extrication' },
  { value: 'T9', label: 'Misc' }
]
