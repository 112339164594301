import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  Typography
} from '@mui/material'
import {
  CircleOutlined,
  CircleRounded,
  CloseOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  PictureAsPdf,
  TextSnippetOutlined
} from '@mui/icons-material'
import { CSVLink } from 'react-csv'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { format } from 'date-fns'
import { Case, getSessionUser } from 'hooks'
import { ROLES } from 'constants/Roles'
import theme from 'theme'
import { REGION_OPTIONS } from 'pages/Cases'

export function ExportCases({
  exporting,
  setExporting,
  selectedRows,
  data
}: {
  exporting: boolean
  setExporting: (state: boolean) => void
  selectedRows: Case[]
  data: Case[]
}) {
  const user = getSessionUser()
  const [checked, setChecked] = useState('all')
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const adminHeaders = [
    { label: 'Guest Name', key: 'firstName' },
    { label: 'Dispatched', key: 'dispatched' },
    { label: 'Case Number', key: 'externalId' },
    { label: 'Warranty Claim ID', key: 'warrantyClaimId' },
    { label: 'Region', key: 'region' },
    { label: 'Dealer', key: 'dealerName' }
  ]

  const managerHeaders = [
    { label: 'Guest Name', key: 'firstName' },
    { label: 'Dispatched', key: 'dispatched' },
    { label: 'Case Number', key: 'externalId' },
    { label: 'Warranty Claim ID', key: 'warrantyClaimId' }
  ]

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleExportClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleClose = () => {
    setExporting(false)
  }

  function dataToBeExported() {
    if (!data) return

    switch (checked) {
      case 'selected':
        return selectedRows.map((row) => {
          const date = format(new Date(row.dispatchedAt), 'MM/dd/yyyy')
          const time = format(new Date(row.dispatchedAt), 'hh:mm a')
          const region = REGION_OPTIONS.find((region) => region.value === row.region).label || '-'

          return {
            ...row,
            dispatched: `${date} ${time}`,
            region
          }
        })
      default:
        return data.map((row) => {
          const date = format(new Date(row.dispatchedAt), 'MM/dd/yyyy')
          const time = format(new Date(row.dispatchedAt), 'hh:mm a')
          const region = REGION_OPTIONS.find((region) => region.value === row.region).label || '-'

          return {
            ...row,
            dispatched: `${date} ${time}`,
            region
          }
        })
    }
  }

  const downloadPdf = () => {
    const doc = new jsPDF()
    const data = dataToBeExported()
    autoTable(doc, {
      head:
        user?.role === ROLES.ADMIN
          ? [adminHeaders.map((header) => header.label)]
          : [managerHeaders.map((header) => header.label)],
      body: data.map((row) => {
        if (user?.role === ROLES.ADMIN) {
          return adminHeaders.map((header) => row[header.key])
        } else {
          return managerHeaders.map((header) => row[header.key])
        }
      })
    })

    doc.save('cases.pdf')
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 0,
          margin: 0,
          right: 0,
          height: '100vh',
          maxHeight: '100vh',
          width: '350px'
        }
      }}
      open={exporting}
      keepMounted
      onClose={handleClose}>
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight="bold" sx={{ fontSize: 20, color: 'black' }}>
          Export Cases
        </Typography>
        <CloseOutlined onClick={handleClose} sx={{ cursor: 'pointer' }} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText marginBottom={2}>
          Choose one of the options and download your cases in CSV format.
        </DialogContentText>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'all'}
              onChange={() => setChecked('all')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">All cases</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Checkbox
              checked={checked === 'selected'}
              onChange={() => setChecked('selected')}
              icon={<CircleOutlined />}
              checkedIcon={<CircleRounded />}
            />
            <Typography color="secondary">Selected: {selectedRows.length} Cases</Typography>
          </Box>

          <Stack width="100%" direction="row">
            <div style={{ width: '100%' }}>
              <Button
                ref={anchorRef}
                id="composition-button"
                variant="contained"
                color="secondary"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                fullWidth
                sx={{
                  marginTop: 4,
                  py: 1,
                  borderRadius: 0,
                  backgroundColor: theme.palette.secondary.dark
                }}
                endIcon={open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                onClick={handleToggle}>
                Export cases
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom"
                transition
                disablePortal>
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      width: anchorRef.current?.clientWidth,
                      transformOrigin: 'top'
                    }}>
                    <Box
                      width="100%"
                      sx={{
                        backgroundColor: 'white',
                        border: `1px solid ${theme.palette.secondary.dark}`
                      }}>
                      <ClickAwayListener onClickAway={handleExportClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          sx={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
                          onKeyDown={handleListKeyDown}>
                          <CSVLink
                            style={{ textDecoration: 'none' }}
                            filename="cases.csv"
                            data={dataToBeExported()}
                            headers={user?.role === ROLES.ADMIN ? adminHeaders : managerHeaders}>
                            <MenuItem sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
                              <Typography color={theme.palette.primary.contrastText}>
                                Export CSV
                              </Typography>
                              <TextSnippetOutlined color="secondary" sx={{ ml: 1 }} />
                            </MenuItem>
                          </CSVLink>
                          <MenuItem
                            sx={{ py: 2, display: 'flex', justifyContent: 'center' }}
                            onClick={downloadPdf}>
                            <Typography variant="body1" color={theme.palette.primary.contrastText}>
                              Export PDF
                            </Typography>
                            <PictureAsPdf color="secondary" sx={{ ml: 1 }} />
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Box>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
