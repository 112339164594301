import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, TableContainer } from 'components'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material'
import { ROUTE_PATHS } from 'constants/Routing'
import theme from 'theme'
import { SubmitAddition } from './SubmitAddition'
import { SubmitReplacement } from './SubmitReplacement'
import { SubmitRenewal } from './SubmitRenewal'
import { SubmitRemoval } from './SubmitRemoval'
import {
  AddOutlined,
  ExpandMoreOutlined,
  Help,
  RemoveOutlined,
  ReplayOutlined,
  SwapHorizOutlined,
  WarningOutlined
} from '@mui/icons-material'

export function SubsidySubmit() {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const requestType = queryParams.get('type')
  const [request, setRequest] = useState('')

  switch (requestType) {
    case 'addition':
      return <SubmitAddition />
    case 'replacement':
      return <SubmitReplacement />
    case 'renewal':
      return <SubmitRenewal />
    case 'removal':
      return <SubmitRemoval />
    default:
      return (
        <Container title="Submit a Request">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TableContainer>
              <Typography variant="subtitle1" fontSize={20}>
                Request Type
              </Typography>

              <Typography
                fontWeight={400}
                fontSize={16}
                color={theme.palette.primary.dark}
                sx={{
                  mb: 1
                }}>
                Choose an option to proceed with the request.
              </Typography>
              <Box marginY={2} display="flex" flexDirection="column" gap={2}>
                <FormGroup>
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        color: theme.palette.primary.contrastText
                      }
                    }}
                    control={<Checkbox />}
                    label="Adding additional vehicle"
                    checked={request === 'addition'}
                    onChange={() => setRequest('addition')}
                  />
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        color: theme.palette.primary.contrastText
                      }
                    }}
                    onChange={() => setRequest('replacement')}
                    checked={request === 'replacement'}
                    control={<Checkbox />}
                    label="Replacing vehicle"
                  />
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        color: theme.palette.primary.contrastText
                      }
                    }}
                    onChange={() => setRequest('renewal')}
                    checked={request === 'renewal'}
                    control={<Checkbox />}
                    label="Renewing vehicle"
                  />
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        color: theme.palette.primary.contrastText
                      }
                    }}
                    onChange={() => setRequest('removal')}
                    checked={request === 'removal'}
                    control={<Checkbox />}
                    label="Removing vehicle"
                  />
                </FormGroup>
              </Box>
              <Box display="flex" justifyContent="end">
                <Button
                  onClick={() =>
                    request && history.push(ROUTE_PATHS.SUBSIDY_SUBMIT + '?type=' + request)
                  }
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ borderRadius: 0, py: 1 }}>
                  Continue
                </Button>
              </Box>
            </TableContainer>

            <TableContainer>
              <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', mb: 0.5 }}>
                <Help sx={{ fontSize: 25 }} />
                <Typography variant="subtitle1" fontSize={20}>
                  Need help understanding each request type?
                </Typography>
              </Box>
              <Typography fontWeight={400} fontSize={16} color={theme.palette.primary.dark}>
                Expand the sections below to learn more about the different request types.
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}>
                <Accordion
                  sx={{
                    ':before': {
                      display: 'none'
                    },
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.primary.light}`,
                    borderRadius: 1,
                    backgroundColor: theme.palette.secondary.light
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.8 }}>
                      <AddOutlined />
                      <Typography variant="subtitle1">Addition</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      An <b>addition</b> request is submitted when you want to expand the dealership
                      fleet by adding a new vehicle. You can either create a new vehicle or activate
                      an existing one.
                    </Typography>
                    <Typography>
                      Remember that in order to create a vehicle you must upload a picture of it.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    ':before': {
                      display: 'none'
                    },
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.primary.light}`,
                    borderRadius: 1,
                    backgroundColor: theme.palette.secondary.light
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.8 }}>
                      <SwapHorizOutlined />
                      <Typography variant="subtitle1">Replacement</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      A <b>replacement</b> request is submitted when you want to replace an existing
                      active vehicle by a new one, or by an existing and inactive one.
                    </Typography>
                    <Typography>
                      Remember that in order to create a vehicle you must upload a picture of it.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    ':before': {
                      display: 'none'
                    },
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.primary.light}`,
                    borderRadius: 1,
                    backgroundColor: theme.palette.secondary.light
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.8 }}>
                      <ReplayOutlined />
                      <Typography variant="subtitle1">Renewal</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      A <b>renewal</b> request is submitted once a year for each vehicle to confirm
                      that the vehicle is still active and that the information is still correct.
                    </Typography>
                    <Typography>
                      When doing a renewal you can update the vehicle information if needed.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    ':before': {
                      display: 'none'
                    },
                    boxShadow: 'none',
                    border: `1px solid ${theme.palette.primary.light}`,
                    borderRadius: 1,
                    backgroundColor: theme.palette.secondary.light
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.8 }}>
                      <RemoveOutlined />
                      <Typography variant="subtitle1">Removal</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      A <b>removal</b> request is submitted when you want to remove a vehicle from
                      the program. After submitting a removal, the vehicle will be marked as
                      inactive (no longer being used at the dealership).
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        backgroundColor: theme.palette.warning.light,
                        p: 1,
                        border: `1px solid ${theme.palette.warning.dark}`,
                        mt: 1,
                        mb: 2
                      }}>
                      <WarningOutlined sx={{ fontSize: 20 }} />
                      <Typography>
                        Removals should only be submitted if the vehicle is not being used for the
                        program anymore, otherwise you can submit a replacement request.
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </TableContainer>
          </Box>
        </Container>
      )
  }
}
