import React, { useEffect, useState } from 'react'
import { Container, GenericCardContainer, Loading } from 'components'
import { useGetSubsidyRequests } from 'hooks'
import { NavLink } from 'react-router-dom'
import { isToday, format } from 'date-fns'
import theme from 'theme'
import { Grid, Typography } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { ROUTE_PATHS } from 'constants/Routing'

const HistoryNotificationsKey = '@history-notifications'

function NotificationItem({ date, type, count, path }: Notification) {
  const typeLabel = type === 'subsidy' && 'Subsidy request  pending Area Manager approval.'

  return (
    <GenericCardContainer
      sx={{
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.primary.dark
      }}>
      <NavLink to={path || '/'} style={{ textDecoration: 'none' }}>
        <Grid container sx={{ display: 'flex' }}>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'baseline',
              flexDirection: 'column',
              width: 'max-content'
            }}>
            <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography variant="caption" fontWeight={400}>
                {format(new Date(date), 'MM/dd/yyyy . HH:mm')}
              </Typography>
            </Grid>
            <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography variant="h6" fontWeight={800}>
                {`There are ${count} ${typeLabel}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ alignItems: 'center', display: 'flex', marginLeft: 'auto' }}>
            <ChevronRight sx={{ color: theme.palette.primary.dark }} />
          </Grid>
        </Grid>
      </NavLink>
    </GenericCardContainer>
  )
}

type Notification = {
  date: Date
  count: number
  type: 'subsidy'
  path: string
}

export function Notifications() {
  const { data, isLoading, isError } = useGetSubsidyRequests({ status: 'pending' })
  const [notifications, setNotifications] = useState<Notification[]>([] as Notification[])

  useEffect(
    function addSubsidyNotification() {
      if (!data || isLoading || isError) return

      const historyNotifications = JSON.parse(
        localStorage.getItem(HistoryNotificationsKey) || '[]'
      ) as Notification[]

      const hasFetchedToday = historyNotifications.some((notification) =>
        isToday(new Date(notification.date))
      )

      setNotifications(historyNotifications)

      if (hasFetchedToday) return

      const newHistoryNotifications = [...historyNotifications]

      newHistoryNotifications.push({
        date: new Date(),
        count: data.itemsCount,
        type: 'subsidy',
        path: ROUTE_PATHS.SUBSIDY_REQUESTS
      })

      localStorage.setItem(HistoryNotificationsKey, JSON.stringify(newHistoryNotifications))
      setNotifications(newHistoryNotifications)
    },
    [data, isError]
  )

  return (
    <Container title="Notifications">
      {notifications.length === 0 && <Loading />}
      {notifications.map((notification) => (
        <NotificationItem key={notification.date.toString()} {...notification} />
      ))}
    </Container>
  )
}
