import React, { useState } from 'react'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  Container,
  Loading,
  NoDataFoundMessage,
  Table,
  TableContainer,
  UnexpectedErrorMessage
} from 'components'
import { useDebounce, useGetVehicles, getSessionUser, Vehicle } from 'hooks'
import { VehiclesFilters } from './VehiclesFilters'
import { renderRegion, valueGetter } from 'utils'
import { ROUTE_PATHS } from 'constants/Routing'
import { ROLES } from 'constants/Roles'
import theme from 'theme'

export const vehicleValueGetter = (params) => {
  return `${params?.row?.make} ${params?.row?.model}`
}

const vehicleNameColumn = (params) => {
  return (
    <Link style={{ color: 'inherit' }} to={ROUTE_PATHS.SUBSIDY_VEHICLES + '/' + params.row.id}>
      <Typography
        variant="body2"
        sx={{ textDecoration: 'underline', cursor: 'pointer', ':hover': { color: 'black' } }}>
        {params.row.make} {params.row.model}
      </Typography>
    </Link>
  )
}

export const statusValueGetter = (params) => {
  return params.row.active ? 'Active' : 'Inactive'
}

const statusColumn = (params) => {
  return (
    <Chip
      sx={{
        borderRadius: 5,
        py: 0,
        fontSize: 16,
        backgroundColor: params.row.active ? theme.palette.success.dark : theme.palette.error.dark,
        color: 'white'
      }}
      label={params.row.active ? 'Active' : 'Inactive'}
    />
  )
}

const adminColumns = [
  {
    flex: 1,
    field: 'mainVehicle',
    valueGetter: vehicleValueGetter,
    renderCell: vehicleNameColumn,
    headerName: 'Vehicle',
    headerClassName: 'dealersHeaderRow',
    sortComparator: (v1, v2) => v1.localeCompare(v2)
  },
  {
    flex: 1,
    field: 'vin',
    valueGetter,
    headerName: 'VIN',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'year',
    valueGetter,
    headerName: 'Year',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'region',
    valueGetter: (params) => renderRegion(params.value),
    headerName: 'Region',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'district',
    valueGetter,
    headerName: 'District',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'dealerCode',
    valueGetter,
    headerName: 'Dealer Code',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'active',
    valueGetter: statusValueGetter,
    headerName: 'Status',
    headerClassName: 'dealersHeaderRow',
    renderCell: statusColumn
  }
]

const managerColumns = [
  {
    flex: 1,
    field: 'mainVehicle',
    valueGetter: vehicleValueGetter,
    renderCell: vehicleNameColumn,
    headerName: 'Vehicle',
    headerClassName: 'dealersHeaderRow',
    sortComparator: (v1, v2) => v1.localeCompare(v2)
  },
  {
    flex: 1,
    field: 'vin',
    valueGetter,
    headerName: 'VIN',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'year',
    valueGetter,
    headerName: 'Year',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'district',
    valueGetter,
    headerName: 'District',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'dealerCode',
    valueGetter,
    headerName: 'Dealer Code',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'active',
    valueGetter: statusValueGetter,
    headerName: 'Status',
    headerClassName: 'dealersHeaderRow',
    renderCell: statusColumn
  }
]

const dealerColumns = [
  {
    flex: 1,
    field: 'mainVehicle',
    valueGetter: vehicleValueGetter,
    renderCell: vehicleNameColumn,
    headerName: 'Vehicle',
    headerClassName: 'dealersHeaderRow',
    sortComparator: (v1, v2) => v1.localeCompare(v2)
  },
  {
    flex: 1,
    field: 'vin',
    valueGetter,
    headerName: 'VIN',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'year',
    valueGetter,
    headerName: 'Year',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'active',
    valueGetter: statusValueGetter,
    headerName: 'Status',
    headerClassName: 'dealersHeaderRow',
    renderCell: statusColumn
  }
]

interface SearchParams {
  vehicle?: string
  vin?: string
}

const displayColumns = (role) => {
  if (role == ROLES.ADMIN) {
    return adminColumns
  } else if (role == ROLES.MANAGER) {
    return managerColumns
  } else if (role == ROLES.DEALER) {
    return dealerColumns
  } else {
    return []
  }
}

export function Vehicles() {
  const theme = useTheme()
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [params, setParams]: [SearchParams, React.Dispatch<React.SetStateAction<SearchParams>>] =
    useState({})
  const debouncedSearchParams = useDebounce(params)
  const [selectedRows, setSelectedRows] = useState<Vehicle[]>([])
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const user = getSessionUser()

  const { data, isLoading, isError, refetch } = useGetVehicles({
    limit,
    offset,
    region: user?.role === ROLES.MANAGER ? user?.region?.toString() : null,
    dealerCode: user?.role === ROLES.DEALER ? user?.dealerCode?.toString() : null,
    ...debouncedSearchParams
  })
  const [paginationModel, setPaginationMode] = useState({
    page: offset / limit,
    pageSize: limit
  })

  function handlePaginationChange(params) {
    setLimit(params.pageSize)
    setOffset(params.page * params.pageSize)
    setPaginationMode({ page: params.page, pageSize: params.pageSize })
    refetch()
  }

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    setSelectedRows(data.data.filter((row) => newRowSelectionModel.includes(row.id)))
  }

  const columns = displayColumns(user?.role)

  return (
    <Container fullWidth title="Vehicles">
      <TableContainer>
        <VehiclesFilters
          params={params}
          setParams={setParams}
          selectedRows={selectedRows}
          data={data?.data}
          disabled={isLoading}
        />

        {isLoading && !data && <Loading />}
        {!isLoading && !data && !isError && <NoDataFoundMessage />}
        {!isLoading && data && (
          <Box sx={{ border: `1px solid ${theme.palette.primary.light}` }}>
            <Table
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowCount={data.itemsCount}
              isLoading={isLoading}
              columns={columns}
              rows={data.data}
            />
          </Box>
        )}
        {!isLoading && isError && <UnexpectedErrorMessage />}
      </TableContainer>
    </Container>
  )
}
