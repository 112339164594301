import React, { useState } from 'react'
import { Box, Chip } from '@mui/material'
import { Button, PaddedFlexFullWidthContainer, SearchInput, Select } from 'components'
import { ROLES } from 'constants/Roles'
import { getSessionUser } from 'hooks'
import { DownloadOutlined } from '@mui/icons-material'
import { ExportVehicles } from './ExportVehicles'
import theme from 'theme'

export function VehiclesFilters({ setParams, params, selectedRows, data, disabled }) {
  const [exporting, setExporting] = useState(false)
  const user = getSessionUser()

  function onFilterChange(newParams) {
    setParams({ ...params, ...newParams })
  }

  function generateAvailableDistricts() {
    const availableDistricts = []
    for (let i = 1; i < 7; i++) {
      availableDistricts.push({ label: `0${i}`, value: `0${i}` })
    }
    availableDistricts.unshift({ label: 'All', value: '' })
    return availableDistricts
  }

  const statusFilter = () => (
    <Select
      sx={{ width: '100%' }}
      inputlabel="Status"
      defaultValue=""
      options={[
        {
          label: (
            <Chip
              sx={{
                borderRadius: 5,
                py: 0,
                fontSize: 16
              }}
              label="All"
            />
          ),
          value: ''
        },
        {
          label: (
            <Chip
              sx={{
                borderRadius: 5,
                py: 0,
                fontSize: 16,
                backgroundColor: theme.palette.success.dark,
                color: 'white'
              }}
              label="Active"
            />
          ),
          value: 'true'
        },
        {
          label: (
            <Chip
              sx={{
                borderRadius: 5,
                py: 0,
                fontSize: 16,
                backgroundColor: theme.palette.error.dark,
                color: 'white'
              }}
              label="Inactive"
            />
          ),
          value: 'false'
        }
      ]}
      onChange={(e) => onFilterChange({ active: e.target.value })}
      disabled={false}
    />
  )

  const adminFilters = () => (
    <PaddedFlexFullWidthContainer
      sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
      gap={4}>
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => onFilterChange({ vin: e.target.value })}
          inputlabel="Vehicle VIN"
          sx={{ width: '100%' }}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => onFilterChange({ dealerCode: e.target.value })}
          inputlabel="Dealer Code"
          sx={{ width: '100%' }}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="Region"
          defaultValue=""
          options={[
            { label: 'All', value: '' },
            { label: 'Western', value: '31' },
            { label: 'Central', value: '32' },
            { label: 'Eastern', value: '33' },
            { label: 'Southern', value: '34' }
          ]}
          onChange={(e) => onFilterChange({ region: e.target.value })}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="District"
          defaultValue=""
          options={generateAvailableDistricts()}
          onChange={(e) => onFilterChange({ district: e.target.value })}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>{statusFilter()}</Box>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ borderRadius: 0 }}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={() => setExporting(true)}>
          EXPORT
          <DownloadOutlined sx={{ marginLeft: 1 }} />
        </Button>
        {data && (
          <ExportVehicles
            selectedRows={selectedRows}
            setExporting={setExporting}
            exporting={exporting}
            data={data}
          />
        )}
      </Box>
    </PaddedFlexFullWidthContainer>
  )

  const managerFilters = () => (
    <PaddedFlexFullWidthContainer
      sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
      gap={4}>
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => onFilterChange({ vin: e.target.value })}
          inputlabel="Vehicle VIN"
          sx={{ width: '100%' }}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => onFilterChange({ dealerName: e.target.value })}
          inputlabel="Dealer Name"
          sx={{ width: '100%' }}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <SearchInput
          onChange={(e) => onFilterChange({ dealerCode: e.target.value })}
          inputlabel="Dealer Code"
          sx={{ width: '100%' }}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          sx={{ width: '100%' }}
          inputlabel="District"
          defaultValue=""
          options={generateAvailableDistricts()}
          onChange={(e) => onFilterChange({ district: e.target.value })}
          disabled={false}
        />
      </Box>
      <Box sx={{ flex: 1 }}>{statusFilter()}</Box>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ borderRadius: 0 }}
          type="submit"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={() => setExporting(true)}>
          EXPORT
          <DownloadOutlined sx={{ marginLeft: 1 }} />
        </Button>
        {data && (
          <ExportVehicles
            selectedRows={selectedRows}
            setExporting={setExporting}
            exporting={exporting}
            data={data}
          />
        )}
      </Box>
    </PaddedFlexFullWidthContainer>
  )

  const dealerFilters = () => (
    <PaddedFlexFullWidthContainer gap={4} sx={{ flexWrap: 'wrap' }}>
      <Box sx={{ width: '30%' }}>
        <SearchInput
          onChange={(e) => onFilterChange({ vin: e.target.value })}
          inputlabel="Vehicle VIN"
          sx={{ width: '100%' }}
          disabled={false}
        />
      </Box>
      <Box sx={{ width: '20%' }}>{statusFilter()}</Box>
    </PaddedFlexFullWidthContainer>
  )

  if (user?.role === ROLES.ADMIN) return adminFilters()
  if (user?.role === ROLES.MANAGER) return managerFilters()
  if (user?.role === ROLES.DEALER) return dealerFilters()
}
