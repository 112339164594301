import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { UpdateVehicleResponse } from './UpdateVehicleResponse'
import { UpdateVehicleRequest } from './UpdateVehicleRequest'

export function useUpdateVehicle() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: UpdateVehicleRequest) => {
      return (await client.put('/vehicles', data)) as UpdateVehicleResponse
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['updateVehicle'])
        queryClient.setQueryData(['updateVehicle'], result)
        return result
      },
      onError: () => {
        queryClient.setQueryData(['updateVehicle'], false)
        return false
      }
    }
  )
}
