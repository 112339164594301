import React, { ReactNode } from 'react'
import {
  Box,
  Container as MaterialContainer,
  styled,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { CARD_WIDTH, SIDEBAR_WIDTH } from 'constants/Utils'
interface ContainerProps {
  component?: string
  fullWidth?: boolean
  children?: ReactNode
  title?: string | ReactNode
  subtitle?: string
  sidebar?: boolean
}

const StyledContainer = styled(MaterialContainer, {
  shouldForwardProp: (prop) => prop !== 'sidebar'
})<ContainerProps>(({ theme, sidebar }) => ({
  backgroundColor: theme.palette.secondary.light,
  margin: 0,
  paddingTop: theme.spacing(11),
  paddingBottom: theme.spacing(3),
  marginLeft: sidebar ? SIDEBAR_WIDTH : 0,
  width: sidebar ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%',
  minHeight: '100vh',
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
    width: '100%'
  }
}))

//Main container with sidebar
export function Container({
  title,
  subtitle,
  fullWidth,
  children,
  sidebar = true
}: ContainerProps) {
  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledContainer component="main" maxWidth={false} sidebar={sidebar}>
      <Box sx={{ width: fullWidth || isDownMediumWidth ? '100%' : CARD_WIDTH, margin: '0 auto' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body1" sx={{ mb: 3 }}>
            {subtitle}
          </Typography>
        )}
        <Box>{children}</Box>
      </Box>
    </StyledContainer>
  )
}

//Generic Card used in the UI
export const GenericCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary.light}`,
  margin: 0,
  padding: 20,
  marginBottom: theme.spacing(2)
}))

//Table Container
export const TableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  padding: theme.spacing(3),
  '& .dealersHeaderRow': {
    backgroundColor: theme.palette.secondary.light
  },
  '& .casesHeaderRow': {
    backgroundColor: theme.palette.secondary.light
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold'
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    backgroundColor: theme.palette.secondary.light
  },
  border: `1px solid ${theme.palette.primary.light}`
}))

//Details Container
export const PaddedFlexFullWidthContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  paddingBottom: theme.spacing(3)
}))
