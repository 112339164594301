import { useClient } from 'hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ReportRequest } from './ReportRequest'
import { ReportAcceptanceRateResponse } from './ReportAcceptanceRateResponse'
import { format } from 'date-fns'

export function useReportAcceptanceRate() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: ReportRequest) => {
      const response = (await client.get('/report/acceptance-rate', {
        params: {
          fromDate: format(new Date(data.fromDate), 'yyyy-MM-dd'),
          toDate: format(new Date(data.toDate), 'yyyy-MM-dd'),
          area: data.area,
          district: data.district,
          dealerId: data.dealerId
        }
      })) as ReportAcceptanceRateResponse

      return response
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(['acceptance-rate-data'], result)
        return result
      },
      onError: () => {
        return false
      }
    }
  )
}
