import React, { useState } from 'react'
import jsPDF, { jsPDFOptions } from 'jspdf'
import autoTable, { UserOptions } from 'jspdf-autotable'
import { Container } from 'components'
import { ReportForm } from './ReportForm'
import { NotFoundDialog } from './NotFoundDialog'
import { ReportRequest, getSessionUser, ReportLRAResponse, useReportLRA, HourOfDay } from 'hooks'
import { ROLES } from 'constants/Roles'
import { ErrorDialog } from './ErrorDialog'
import { ReportFormDealer } from './ReportFormDealer'

export function ReportsLRA() {
  const user = getSessionUser()
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [area, setArea] = useState('')
  const [district, setDistrict] = useState('')
  const [dealerId, setDealer] = useState('')
  const [reportData, setReportData] = useState<ReportLRAResponse>()
  const [showNotFoundDialog, setShowNotFoundDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { mutateAsync: getLRA, isLoading: isLoadingReport, isError: isErrorReport } = useReportLRA()

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    const request: ReportRequest = {
      fromDate: fromDate?.toString(),
      toDate: toDate?.toString()
    }

    if (user?.role === ROLES.ADMIN) {
      request.area = area
      request.district = district
      request.dealerId = dealerId
    } else if (user?.role === ROLES.MANAGER) {
      request.area = String(user?.region)
      request.dealerId = dealerId
    } else if (user?.role === ROLES.DEALER) {
      request.dealerId = String(user?.dealerCode)
    }

    try {
      const response = await getLRA(request)
      if (!isErrorReport && Object.keys(response).length > 0) {
        setReportData(response)
      } else {
        setShowNotFoundDialog(true)
      }
    } catch (error) {
      setErrorMessage(error.response.data.message)
    }
  }

  const handleClearInputs = () => {
    setFromDate(null)
    setToDate(null)
    setArea('')
    setDistrict('')
    setDealer('')
    setReportData(undefined)
  }

  const getTotals = (
    aaaWeekdays: HourOfDay,
    aaaWeekend: HourOfDay,
    dealerWeekdays: HourOfDay,
    dealerWeekend: HourOfDay
  ) => {
    const aaaWeekdaysTotal = Object.entries(aaaWeekdays).reduce(
      (acc, weekdaysKey) => (acc += weekdaysKey[1]),
      0
    )
    const dealerWeekdaysTotal = Object.entries(dealerWeekdays).reduce(
      (acc, weekdaysKey) => (acc += weekdaysKey[1]),
      0
    )

    const aaaWeekendTotal = Object.entries(aaaWeekend).reduce(
      (acc, weekendKey) => (acc += weekendKey[1]),
      0
    )
    const dealerWeekendTotal = Object.entries(dealerWeekend).reduce(
      (acc, weekdaysKey) => (acc += weekdaysKey[1]),
      0
    )

    return {
      aaaWeekdaysTotal,
      dealerWeekdaysTotal,
      aaaWeekendTotal,
      dealerWeekendTotal
    }
  }

  const handleDownloadPDF = () => {
    const miles = 10
    const today = new Date()

    const docConfig: jsPDFOptions = {
      format: 'a4',
      orientation: 'landscape'
    }

    const doc = new jsPDF(docConfig)

    const head = [
      {
        empty: '',
        am12: '12 AM',
        am1: '1 AM',
        am2: '2 AM',
        am3: '3 AM',
        am4: '4 AM',
        am5: '5 AM',
        am6: '6 AM',
        am7: '7 AM',
        am8: '8 AM',
        am9: '9 AM',
        am10: '10 AM',
        am11: '11 AM',
        pm12: '12 PM',
        pm13: '1 PM',
        pm14: '2 PM',
        pm15: '3 PM',
        pm16: '4 PM',
        pm17: '5 PM',
        pm18: '6 PM',
        pm19: '7 PM',
        pm20: '8 PM',
        pm21: '9 PM',
        pm22: '10 PM',
        pm23: '11 PM',
        total: 'Total'
      }
    ]

    const options: Partial<UserOptions> = {
      head,
      headStyles: {
        halign: 'left',
        valign: 'middle',
        textColor: '#000000',
        fillColor: '#808080',
        fontSize: 8,
        fontStyle: 'bold',
        lineWidth: 0.1,
        lineColor: '#EDF2F6'
      },
      bodyStyles: {
        halign: 'right',
        valign: 'middle',
        fontSize: 9,
        textColor: '#000000',
        fillColor: '#FEFEFE',
        lineColor: '#EDF2F6',
        lineWidth: 0.1
      },
      alternateRowStyles: {
        fillColor: '#FEFEFE'
      },
      columnStyles: {
        empty: {
          halign: 'left'
        },
        total: {
          fillColor: '#808080'
        }
      }
    }

    Object.keys(reportData).forEach((key) => {
      const { aaaWeekdaysTotal, dealerWeekdaysTotal, aaaWeekendTotal, dealerWeekendTotal } =
        getTotals(
          reportData[key].aaaData.weekdays,
          reportData[key].aaaData.weekend,
          reportData[key].dealerData.weekdays,
          reportData[key].dealerData.weekend
        )

      doc.setFontSize(20)
      doc.setFont(doc.getFont().fontName, 'normal', 700)
      doc.text(reportData[key].dealerName, 10, 20)
      doc.setFontSize(16)
      doc.setFont(doc.getFont().fontName, 'normal', 400)
      doc.text(`Provided Services within ${miles} miles of Dealership`, 10, 30)
      doc.setFontSize(14)
      doc.text(`Created at: ${today.toLocaleDateString()} ${today.toLocaleTimeString()}`, 10, 35)
      doc.text(
        `From: ${fromDate.toLocaleDateString()} ${fromDate.toLocaleTimeString()} - To: ${toDate.toLocaleDateString()} ${toDate.toLocaleTimeString()}`,
        10,
        40
      )
      doc.setFontSize(16)
      doc.text('Light Service Opportunities', 10, 50)

      doc.setFontSize(12)
      doc.text('Monday - Friday', 10, 60)
      autoTable(doc, {
        ...options,
        startY: 65,
        body: [
          {
            empty: 'AAA',
            am12: reportData[key].aaaData.weekdays['0'],
            am1: reportData[key].aaaData.weekdays['1'],
            am2: reportData[key].aaaData.weekdays['2'],
            am3: reportData[key].aaaData.weekdays['3'],
            am4: reportData[key].aaaData.weekdays['4'],
            am5: reportData[key].aaaData.weekdays['5'],
            am6: reportData[key].aaaData.weekdays['6'],
            am7: reportData[key].aaaData.weekdays['7'],
            am8: reportData[key].aaaData.weekdays['8'],
            am9: reportData[key].aaaData.weekdays['9'],
            am10: reportData[key].aaaData.weekdays['10'],
            am11: reportData[key].aaaData.weekdays['11'],
            pm12: reportData[key].aaaData.weekdays['12'],
            pm13: reportData[key].aaaData.weekdays['13'],
            pm14: reportData[key].aaaData.weekdays['14'],
            pm15: reportData[key].aaaData.weekdays['15'],
            pm16: reportData[key].aaaData.weekdays['16'],
            pm17: reportData[key].aaaData.weekdays['17'],
            pm18: reportData[key].aaaData.weekdays['18'],
            pm19: reportData[key].aaaData.weekdays['19'],
            pm20: reportData[key].aaaData.weekdays['20'],
            pm21: reportData[key].aaaData.weekdays['21'],
            pm22: reportData[key].aaaData.weekdays['22'],
            pm23: reportData[key].aaaData.weekdays['23'],
            total: aaaWeekdaysTotal
          },
          {
            empty: 'DEALER',
            am12: reportData[key].dealerData.weekdays['0'],
            am1: reportData[key].dealerData.weekdays['1'],
            am2: reportData[key].dealerData.weekdays['2'],
            am3: reportData[key].dealerData.weekdays['3'],
            am4: reportData[key].dealerData.weekdays['4'],
            am5: reportData[key].dealerData.weekdays['5'],
            am6: reportData[key].dealerData.weekdays['6'],
            am7: reportData[key].dealerData.weekdays['7'],
            am8: reportData[key].dealerData.weekdays['8'],
            am9: reportData[key].dealerData.weekdays['9'],
            am10: reportData[key].dealerData.weekdays['10'],
            am11: reportData[key].dealerData.weekdays['11'],
            pm12: reportData[key].dealerData.weekdays['12'],
            pm13: reportData[key].dealerData.weekdays['13'],
            pm14: reportData[key].dealerData.weekdays['14'],
            pm15: reportData[key].dealerData.weekdays['15'],
            pm16: reportData[key].dealerData.weekdays['16'],
            pm17: reportData[key].dealerData.weekdays['17'],
            pm18: reportData[key].dealerData.weekdays['18'],
            pm19: reportData[key].dealerData.weekdays['19'],
            pm20: reportData[key].dealerData.weekdays['20'],
            pm21: reportData[key].dealerData.weekdays['21'],
            pm22: reportData[key].dealerData.weekdays['22'],
            pm23: reportData[key].dealerData.weekdays['23'],
            total: dealerWeekdaysTotal
          },
          {
            empty: 'Total',
            am12: reportData[key].aaaData.weekdays['0'] + reportData[key].dealerData.weekdays['0'],
            am1: reportData[key].aaaData.weekdays['1'] + reportData[key].dealerData.weekdays['1'],
            am2: reportData[key].aaaData.weekdays['2'] + reportData[key].dealerData.weekdays['2'],
            am3: reportData[key].aaaData.weekdays['3'] + reportData[key].dealerData.weekdays['3'],
            am4: reportData[key].aaaData.weekdays['4'] + reportData[key].dealerData.weekdays['4'],
            am5: reportData[key].aaaData.weekdays['5'] + reportData[key].dealerData.weekdays['5'],
            am6: reportData[key].aaaData.weekdays['6'] + reportData[key].dealerData.weekdays['6'],
            am7: reportData[key].aaaData.weekdays['7'] + reportData[key].dealerData.weekdays['7'],
            am8: reportData[key].aaaData.weekdays['8'] + reportData[key].dealerData.weekdays['8'],
            am9: reportData[key].aaaData.weekdays['9'] + reportData[key].dealerData.weekdays['9'],
            am10:
              reportData[key].aaaData.weekdays['10'] + reportData[key].dealerData.weekdays['10'],
            am11:
              reportData[key].aaaData.weekdays['11'] + reportData[key].dealerData.weekdays['11'],
            pm12:
              reportData[key].aaaData.weekdays['12'] + reportData[key].dealerData.weekdays['12'],
            pm13:
              reportData[key].aaaData.weekdays['13'] + reportData[key].dealerData.weekdays['13'],
            pm14:
              reportData[key].aaaData.weekdays['14'] + reportData[key].dealerData.weekdays['14'],
            pm15:
              reportData[key].aaaData.weekdays['15'] + reportData[key].dealerData.weekdays['15'],
            pm16:
              reportData[key].aaaData.weekdays['16'] + reportData[key].dealerData.weekdays['16'],
            pm17:
              reportData[key].aaaData.weekdays['17'] + reportData[key].dealerData.weekdays['17'],
            pm18:
              reportData[key].aaaData.weekdays['18'] + reportData[key].dealerData.weekdays['18'],
            pm19:
              reportData[key].aaaData.weekdays['19'] + reportData[key].dealerData.weekdays['19'],
            pm20:
              reportData[key].aaaData.weekdays['20'] + reportData[key].dealerData.weekdays['20'],
            pm21:
              reportData[key].aaaData.weekdays['21'] + reportData[key].dealerData.weekdays['21'],
            pm22:
              reportData[key].aaaData.weekdays['22'] + reportData[key].dealerData.weekdays['22'],
            pm23:
              reportData[key].aaaData.weekdays['23'] + reportData[key].dealerData.weekdays['23'],
            total: aaaWeekdaysTotal + dealerWeekdaysTotal
          }
        ]
      })

      doc.text('Weekend', 10, 110)
      autoTable(doc, {
        ...options,
        startY: 115,
        body: [
          {
            empty: 'AAA',
            am12: reportData[key].aaaData.weekend['0'],
            am1: reportData[key].aaaData.weekend['1'],
            am2: reportData[key].aaaData.weekend['2'],
            am3: reportData[key].aaaData.weekend['3'],
            am4: reportData[key].aaaData.weekend['4'],
            am5: reportData[key].aaaData.weekend['5'],
            am6: reportData[key].aaaData.weekend['6'],
            am7: reportData[key].aaaData.weekend['7'],
            am8: reportData[key].aaaData.weekend['8'],
            am9: reportData[key].aaaData.weekend['9'],
            am10: reportData[key].aaaData.weekend['10'],
            am11: reportData[key].aaaData.weekend['11'],
            pm12: reportData[key].aaaData.weekend['12'],
            pm13: reportData[key].aaaData.weekend['13'],
            pm14: reportData[key].aaaData.weekend['14'],
            pm15: reportData[key].aaaData.weekend['15'],
            pm16: reportData[key].aaaData.weekend['16'],
            pm17: reportData[key].aaaData.weekend['17'],
            pm18: reportData[key].aaaData.weekend['18'],
            pm19: reportData[key].aaaData.weekend['19'],
            pm20: reportData[key].aaaData.weekend['20'],
            pm21: reportData[key].aaaData.weekend['21'],
            pm22: reportData[key].aaaData.weekend['22'],
            pm23: reportData[key].aaaData.weekend['23'],
            total: aaaWeekendTotal
          },
          {
            empty: 'DEALER',
            am12: reportData[key].dealerData.weekend['0'],
            am1: reportData[key].dealerData.weekend['1'],
            am2: reportData[key].dealerData.weekend['2'],
            am3: reportData[key].dealerData.weekend['3'],
            am4: reportData[key].dealerData.weekend['4'],
            am5: reportData[key].dealerData.weekend['5'],
            am6: reportData[key].dealerData.weekend['6'],
            am7: reportData[key].dealerData.weekend['7'],
            am8: reportData[key].dealerData.weekend['8'],
            am9: reportData[key].dealerData.weekend['9'],
            am10: reportData[key].dealerData.weekend['10'],
            am11: reportData[key].dealerData.weekend['11'],
            pm12: reportData[key].dealerData.weekend['12'],
            pm13: reportData[key].dealerData.weekend['13'],
            pm14: reportData[key].dealerData.weekend['14'],
            pm15: reportData[key].dealerData.weekend['15'],
            pm16: reportData[key].dealerData.weekend['16'],
            pm17: reportData[key].dealerData.weekend['17'],
            pm18: reportData[key].dealerData.weekend['18'],
            pm19: reportData[key].dealerData.weekend['19'],
            pm20: reportData[key].dealerData.weekend['20'],
            pm21: reportData[key].dealerData.weekend['21'],
            pm22: reportData[key].dealerData.weekend['22'],
            pm23: reportData[key].dealerData.weekend['23'],
            total: dealerWeekendTotal
          },
          {
            empty: 'Total',
            am12: reportData[key].aaaData.weekend['0'] + reportData[key].dealerData.weekend['0'],
            am1: reportData[key].aaaData.weekend['1'] + reportData[key].dealerData.weekend['1'],
            am2: reportData[key].aaaData.weekend['2'] + reportData[key].dealerData.weekend['2'],
            am3: reportData[key].aaaData.weekend['3'] + reportData[key].dealerData.weekend['3'],
            am4: reportData[key].aaaData.weekend['4'] + reportData[key].dealerData.weekend['4'],
            am5: reportData[key].aaaData.weekend['5'] + reportData[key].dealerData.weekend['5'],
            am6: reportData[key].aaaData.weekend['6'] + reportData[key].dealerData.weekend['6'],
            am7: reportData[key].aaaData.weekend['7'] + reportData[key].dealerData.weekend['7'],
            am8: reportData[key].aaaData.weekend['8'] + reportData[key].dealerData.weekend['8'],
            am9: reportData[key].aaaData.weekend['9'] + reportData[key].dealerData.weekend['9'],
            am10: reportData[key].aaaData.weekend['10'] + reportData[key].dealerData.weekend['10'],
            am11: reportData[key].aaaData.weekend['11'] + reportData[key].dealerData.weekend['11'],
            pm12: reportData[key].aaaData.weekend['12'] + reportData[key].dealerData.weekend['12'],
            pm13: reportData[key].aaaData.weekend['13'] + reportData[key].dealerData.weekend['13'],
            pm14: reportData[key].aaaData.weekend['14'] + reportData[key].dealerData.weekend['14'],
            pm15: reportData[key].aaaData.weekend['15'] + reportData[key].dealerData.weekend['15'],
            pm16: reportData[key].aaaData.weekend['16'] + reportData[key].dealerData.weekend['16'],
            pm17: reportData[key].aaaData.weekend['17'] + reportData[key].dealerData.weekend['17'],
            pm18: reportData[key].aaaData.weekend['18'] + reportData[key].dealerData.weekend['18'],
            pm19: reportData[key].aaaData.weekend['19'] + reportData[key].dealerData.weekend['19'],
            pm20: reportData[key].aaaData.weekend['20'] + reportData[key].dealerData.weekend['20'],
            pm21: reportData[key].aaaData.weekend['21'] + reportData[key].dealerData.weekend['21'],
            pm22: reportData[key].aaaData.weekend['22'] + reportData[key].dealerData.weekend['22'],
            pm23: reportData[key].aaaData.weekend['23'] + reportData[key].dealerData.weekend['23'],
            total: aaaWeekendTotal + dealerWeekendTotal
          }
        ]
      })

      doc.text('Grand total', 10, 155)
      autoTable(doc, {
        head: [
          {
            empty: '',
            total: 'Total'
          }
        ],
        headStyles: {
          halign: 'left',
          valign: 'middle',
          cellWidth: 20,
          textColor: '#000000',
          fillColor: '#648EAE',
          fontSize: 8,
          fontStyle: 'bold',
          lineColor: '#EDF2F6',
          lineWidth: 0.1
        },
        bodyStyles: {
          halign: 'right',
          valign: 'middle',
          fontSize: 9,
          textColor: '#000000',
          fillColor: '#FEFEFE',
          lineColor: '#EDF2F6',
          lineWidth: 0.1
        },
        alternateRowStyles: {
          fillColor: '#FEFEFE'
        },
        columnStyles: {
          empty: {
            halign: 'left'
          }
        },
        startY: 160,
        body: [
          {
            empty: 'AAA',
            total: aaaWeekendTotal + aaaWeekdaysTotal
          },
          {
            empty: 'DEALER',
            total: dealerWeekendTotal + dealerWeekdaysTotal
          },
          {
            empty: 'Total',
            total: aaaWeekendTotal + dealerWeekendTotal + aaaWeekdaysTotal + dealerWeekdaysTotal
          }
        ]
      })

      doc.addPage(docConfig.format, docConfig.orientation)
      doc.setPage(doc.getNumberOfPages())
    })

    doc.deletePage(doc.getNumberOfPages())
    doc.save(`${today.toISOString()}-lra-report.pdf`)
  }

  const getEmptyRow = (head: string[]) => Object.values(head).map(() => '')

  const handleDownloadCSV = () => {
    const miles = 10
    const today = new Date()
    const head = [
      '',
      '12 AM',
      '1 AM',
      '2 AM',
      '3 AM',
      '4 AM',
      '5 AM',
      '6 AM',
      '7 AM',
      '8 AM',
      '9 AM',
      '10 AM',
      '11 AM',
      '12 PM',
      '1 PM',
      '2 PM',
      '3 PM',
      '4 PM',
      '5 PM',
      '6 PM',
      '7 PM',
      '8 PM',
      '9 PM',
      '10 PM',
      '11 PM',
      'Total'
    ]
    const csvData = [getEmptyRow(head)]

    Object.keys(reportData).forEach((key) => {
      const { aaaWeekdaysTotal, dealerWeekdaysTotal, aaaWeekendTotal, dealerWeekendTotal } =
        getTotals(
          reportData[key].aaaData.weekdays,
          reportData[key].aaaData.weekend,
          reportData[key].dealerData.weekdays,
          reportData[key].dealerData.weekend
        )

      const title = getEmptyRow(head)
      title[0] = `Dealer: ${reportData[key].dealerName}`
      csvData.push(title)

      const subtitle = getEmptyRow(head)
      subtitle[0] = `Provided Services within ${miles} miles of Dealership`
      csvData.push(subtitle)

      const createdAt = getEmptyRow(head)
      createdAt[0] = `Created at: ${today.toLocaleDateString()} ${today.toLocaleTimeString()}`
      csvData.push(createdAt)

      const date = getEmptyRow(head)
      date[0] = `From: ${fromDate.toLocaleDateString()} ${fromDate.toLocaleTimeString()} - To: ${toDate.toLocaleDateString()} ${toDate.toLocaleTimeString()}`
      csvData.push(date)

      csvData.push(getEmptyRow(head))

      const tableTitle = getEmptyRow(head)
      tableTitle[0] = 'Light Service Opportunities'
      csvData.push(tableTitle)

      csvData.push(getEmptyRow(head))

      // Weekdays
      const weekdaysTitle = getEmptyRow(head)
      weekdaysTitle[0] = 'Monday - Friday'
      csvData.push(weekdaysTitle)

      csvData.push(head)

      const aaaWeekdaysData = getEmptyRow(head)
      aaaWeekdaysData[0] = 'AAA'
      Object.values(reportData[key].aaaData.weekdays).forEach((value, index) => {
        aaaWeekdaysData[index + 1] = value.toString()
      })
      aaaWeekdaysData[aaaWeekdaysData.length - 1] = aaaWeekdaysTotal.toString()
      csvData.push(aaaWeekdaysData)

      const dealerWeekdaysData = getEmptyRow(head)
      dealerWeekdaysData[0] = 'DEALER'
      Object.values(reportData[key].dealerData.weekdays).forEach((value, index) => {
        dealerWeekdaysData[index + 1] = value.toString()
      })
      dealerWeekdaysData[dealerWeekdaysData.length - 1] = dealerWeekdaysTotal.toString()
      csvData.push(dealerWeekdaysData)

      const weekdaysTotal = getEmptyRow(head)
      weekdaysTotal[0] = 'Total'
      Object.values(reportData[key].aaaData.weekdays).forEach((value, index) => {
        weekdaysTotal[index + 1] = (value + reportData[key].dealerData.weekdays[index]).toString()
      })
      weekdaysTotal[weekdaysTotal.length - 1] = (aaaWeekdaysTotal + dealerWeekdaysTotal).toString()
      csvData.push(weekdaysTotal)
      //

      csvData.push(getEmptyRow(head))

      // Weekend
      const weekendTitle = getEmptyRow(head)
      weekendTitle[0] = 'Weekend'
      csvData.push(weekendTitle)

      csvData.push(head)

      const aaaWeekendData = getEmptyRow(head)
      aaaWeekendData[0] = 'AAA'
      Object.values(reportData[key].aaaData.weekend).forEach((value, index) => {
        aaaWeekendData[index + 1] = value.toString()
      })
      aaaWeekendData[aaaWeekendData.length - 1] = aaaWeekendTotal.toString()
      csvData.push(aaaWeekendData)

      const dealerWeekendData = getEmptyRow(head)
      dealerWeekendData[0] = 'DEALER'
      Object.values(reportData[key].dealerData.weekend).forEach((value, index) => {
        dealerWeekendData[index + 1] = value.toString()
      })
      dealerWeekendData[dealerWeekendData.length - 1] = dealerWeekendTotal.toString()
      csvData.push(dealerWeekendData)

      const weekendTotal = getEmptyRow(head)
      weekendTotal[0] = 'Total'
      Object.values(reportData[key].aaaData.weekend).forEach((value, index) => {
        weekendTotal[index + 1] = (value + reportData[key].dealerData.weekend[index]).toString()
      })
      weekendTotal[weekendTotal.length - 1] = (aaaWeekendTotal + dealerWeekendTotal).toString()
      csvData.push(weekendTotal)
      //

      csvData.push(getEmptyRow(head))

      // Total
      const totalTitle = getEmptyRow(head)
      totalTitle[0] = 'Grand total'
      csvData.push(totalTitle)

      const totalHeader = getEmptyRow(head)
      totalHeader[1] = 'Total'
      csvData.push(totalHeader)

      const aaaTotalData = getEmptyRow(head)
      aaaTotalData[0] = 'AAA'
      aaaTotalData[1] = (aaaWeekdaysTotal + aaaWeekendTotal).toString()
      csvData.push(aaaTotalData)

      const dealerTotalData = getEmptyRow(head)
      dealerTotalData[0] = 'DEALER'
      dealerTotalData[1] = (dealerWeekdaysTotal + dealerWeekendTotal).toString()
      csvData.push(dealerTotalData)

      const grandTotal = getEmptyRow(head)
      grandTotal[0] = 'Total'
      grandTotal[1] = (
        aaaWeekdaysTotal +
        aaaWeekendTotal +
        dealerWeekdaysTotal +
        dealerWeekendTotal
      ).toString()
      csvData.push(grandTotal)
      //

      csvData.push(getEmptyRow(head))
      csvData.push(getEmptyRow(head))
      csvData.push(getEmptyRow(head))
    })

    return csvData
  }

  return (
    <Container
      title="LRA Report"
      subtitle="Use this screen to choose a dealership for which you want to view their report. This is a quarterly summary of cases, by day of the week and time of day.">
      {user?.role === ROLES.DEALER ? (
        <ReportFormDealer
          title="Select Dealership for LRA Opportunity Report"
          reportName="LRA Report"
          handleSubmit={handleSubmit}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          reportData={reportData}
          isLoadingReport={isLoadingReport}
          isErrorReport={isErrorReport}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadCSV={handleDownloadCSV}
          handleClearInputs={handleClearInputs}
        />
      ) : (
        <ReportForm
          title="Select Dealership for LRA Opportunity Report"
          reportName="LRA Report"
          handleSubmit={handleSubmit}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          area={area}
          setArea={setArea}
          district={district}
          setDistrict={setDistrict}
          dealerId={dealerId}
          setDealer={setDealer}
          reportData={reportData}
          isLoadingReport={isLoadingReport}
          isErrorReport={isErrorReport}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadCSV={handleDownloadCSV}
          handleClearInputs={handleClearInputs}
        />
      )}
      <NotFoundDialog open={showNotFoundDialog} onClose={() => setShowNotFoundDialog(false)} />
      <ErrorDialog
        content={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
    </Container>
  )
}
