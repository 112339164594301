import React from 'react'
import { Card, Container } from 'components'
import { Box, Divider } from '@mui/material'
import { MANAGERS_INFO } from './managers-info.js'

const ManagerBlock = ({ title, information }) => (
  <Card title={title}>
    {information.map((manager, i) => (
      <Box key={i}>
        <p>Name: {manager.name}</p>
        <p>Email: {manager.email}</p>
        {i < information.length - 1 && <Divider />}
      </Box>
    ))}
  </Card>
)

export function Managers() {
  return (
    <>
      <Container title="Area Managers">
        <ManagerBlock title="Western Area" information={MANAGERS_INFO.WESTERN} />
        <ManagerBlock title="Central Area" information={MANAGERS_INFO.CENTRAL} />
        <ManagerBlock title="Eastern Area" information={MANAGERS_INFO.EASTERN} />
        <ManagerBlock title="Southern Area" information={MANAGERS_INFO.SOUTHERN} />
      </Container>
    </>
  )
}
