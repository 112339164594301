import { useQuery } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { GetCasesResponse } from './GetCasesResponse'

export interface GetCasesParams {
  status?: string
  offset?: number
  limit?: number
  caseNumber?: string | number
  region?: string
  districtId?: string
  fromDate?: string
  toDate?: string
  dealerName?: string
  dealerCode?: string
  warrantyClaimId?: string
}

export function useGetCases(params: GetCasesParams = {}) {
  const client = useClient()

  if (!params?.status) delete params.status
  if (!params?.offset) delete params.offset
  if (!params?.limit) delete params.limit
  if (!params?.region) delete params.region
  if (!params?.caseNumber) delete params.caseNumber
  if (!params?.fromDate) delete params.fromDate
  if (!params?.toDate) delete params.toDate
  if (!params?.dealerName) delete params.dealerName
  if (!params?.districtId) delete params.districtId
  if (!params?.dealerCode) delete params.dealerCode
  if (!params?.warrantyClaimId) delete params.warrantyClaimId

  if (params?.limit) {
    params.limit = parseInt(params?.limit.toString())
  }

  if (params?.caseNumber) {
    if (isNaN(params?.caseNumber as number)) {
      params.dealerName = params?.caseNumber.toString()
      delete params.caseNumber
    }
  }

  if (params?.offset) {
    params.offset = parseInt(params?.offset.toString())
  }

  return useQuery(['case', { params }], async () => {
    try {
      return (await client.get('/case', {
        params
      })) as GetCasesResponse
    } catch (error) {
      throw new Error(error)
    }
  })
}
