import React, { useId } from 'react'
import { styled, useTheme } from '@mui/material'
import { TimeField as XTimeField } from '@mui/x-date-pickers/TimeField'
import { UseFormRegisterReturn } from 'react-hook-form'
import { DateTimeInputLabel } from 'components'

interface TextFieldInputProps {
  inputLabel?: string
  required?: boolean
  placeholder?: string
  helperText?: string
  register?: UseFormRegisterReturn
}

const options = {
  shouldForwardProp: (prop) => prop !== 'inputLabel'
}
const StyledTimeField = styled(XTimeField, options)(() => ({}))

export const TimeField = (props: TextFieldInputProps) => {
  const id = useId()
  const theme = useTheme()

  return (
    <>
      <DateTimeInputLabel htmlFor={id}>
        {props.inputLabel}
        {props.required && '*'}
      </DateTimeInputLabel>
      <StyledTimeField
        id={id}
        variant="standard"
        inputProps={{
          sx: {
            '&::placeholder': {
              color: theme.palette.primary.dark,
              opacity: 1
            }
          }
        }}
        {...props}
        {...props.register}
      />
    </>
  )
}
