import React, { useState } from 'react'
import { Box, CircularProgress, Divider, Grid, styled, Typography, useTheme } from '@mui/material'
import { format, isValid } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, Button, Container, Loading } from 'components'
import { ROUTE_PATHS } from 'constants/Routing'
import { SERVICES_OPTIONS } from 'constants/Services'
import { ServiceForm } from './form'
import { UpdateCaseDialog } from './dialogs'
import { useGetCase, useUpdateCase } from 'hooks'

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  border: `1px solid ${theme.palette.primary.light}`,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  maxWidth: 576
}))

export function CaseDetails() {
  const { id } = useParams()
  const history = useHistory()
  const [showUpdateCaseDialog, setShowUpdateCaseDialog] = useState(false)
  const { data, isLoading, isError } = useGetCase({ id })
  const {
    mutateAsync: updateCase,
    isError: isErrorUpdateCase,
    isLoading: isLoadingUpdateCase
  } = useUpdateCase()
  const theme = useTheme()

  const closedStatuses = ['CLOSED', 'CANCELLED', 'COMPLETED']

  const isClosed = data?.caseStatuses.some((element) => closedStatuses.includes(element.status))
  const latestStatus = data?.caseStatuses.find((element) => closedStatuses.includes(element.status))

  const dispatchedStatus = data?.caseStatuses?.find(
    (status) => status.status === 'CREATED' || status.status === 'ACCEPTED'
  )

  const dispatchedAt = new Date(dispatchedStatus?.statusTime)

  const serviceFormInitialValues = isClosed
    ? {
        odometer: data?.odometer,
        latestStatus: latestStatus?.statusTime,
        service: data?.providedServiceCode
      }
    : null

  const caseInformation = () => {
    if (isLoading) return <Loading />

    if (isError || !data) return 'Error'

    const dispatchedAtDate = isValid(dispatchedAt) && format(dispatchedAt, 'MM/dd/yyyy')
    const dispatchedAtTime = isValid(dispatchedAt) && format(dispatchedAt, 'hh:mm a').toUpperCase()

    return (
      <>
        <Typography
          fontSize={18}
          fontWeight="bold"
          color="secondary"
          sx={{ textDecoration: 'underline' }}
          mb={1}
          component="div">
          Details
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Warranty Claim ID: <Typography>{data.warrantyClaimId}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Case Number: <Typography>{data.externalId || '-'}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Guest Name:{' '}
          <Typography>
            {data.firstName} {data.lastName}
          </Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Dispatched:{' '}
          <Typography>
            {dispatchedAtDate} - Time: {dispatchedAtTime}
          </Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Requested Service:{' '}
          <Typography>
            {SERVICES_OPTIONS.find((service) => service.value === data.requestedServiceCode)
              ?.label || 'N/A'}
          </Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Opened By: <Typography>{data.creatorName || '-'}</Typography>
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        <Typography
          fontSize={18}
          fontWeight="bold"
          color="secondary"
          sx={{ textDecoration: 'underline' }}
          mb={1}
          component="div">
          Breakdown Location
        </Typography>

        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Street Address:{' '}
          <Typography>
            {data.street} {data.streetNumber}
          </Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          City: <Typography>{data.city}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          State: <Typography>{data.state}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          ZIP Code: <Typography>{data.zipcode}</Typography>
        </Typography>

        <Divider sx={{ marginY: 2 }} />
        <Typography
          fontSize={18}
          fontWeight="bold"
          color="secondary"
          sx={{ textDecoration: 'underline' }}
          mb={1}
          component="div">
          Vehicle Information
        </Typography>

        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Make: <Typography>{data.vehicleMake}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Year: <Typography>{data.vehicleYear}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Model: <Typography>{data.vehicleModel}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          Color: <Typography>{data.vehicleColor}</Typography>
        </Typography>
        <Typography display="flex" gap={1} fontWeight="semi-bold" color="secondary" component="div">
          VIN: <Typography>{data.vin}</Typography>
        </Typography>
      </>
    )
  }

  const serviceInformation = () => {
    return (
      <>
        <Typography
          color="inherit"
          fontWeight="bolder"
          sx={{ fontSize: 18, paddingY: 1, color: theme.palette.secondary.dark }}>
          Service Information
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={16}
          color={theme.palette.primary.dark}
          sx={{
            mb: 1
          }}>
          Fill in the information and submit the case.
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={12}
          color={theme.palette.secondary.dark}
          marginBottom={2}>
          * Required fields
        </Typography>
        <ServiceForm
          isClosed={isClosed}
          onSubmit={handleUpdateCase}
          dispatchDate={dispatchedAt}
          initialValues={serviceFormInitialValues}
          actions={
            isClosed ? null : (
              <Grid display="flex" justifyContent="space-between" mt={4}>
                <Button
                  onClick={() => history.push(ROUTE_PATHS.CASES)}
                  variant="text"
                  color="secondary">
                  GO BACK
                </Button>

                <Button
                  disabled={isLoadingUpdateCase}
                  type="submit"
                  variant="contained"
                  color="secondary">
                  {isLoadingUpdateCase ? <CircularProgress size={22} color="primary" /> : 'SUBMIT'}
                </Button>
              </Grid>
            )
          }
        />
      </>
    )
  }

  const handleUpdateCase = async (params) => {
    if (!params) return

    const data = {
      id: Number(id),
      status: 'CLOSED',
      service: {
        code: params.serviceType,
        date: params.serviceDateAndTime
      },
      odometer: params.odometer
    }

    try {
      await updateCase(data)
    } catch (error) {
      console.log(error)
    } finally {
      setShowUpdateCaseDialog(true)
    }
  }

  return (
    <Container
      title={
        <Breadcrumb
          paths={[
            { label: 'Update & Close Cases', path: ROUTE_PATHS.CASES },
            { label: 'Case Details', path: ROUTE_PATHS.CASE_DETAILS }
          ]}
        />
      }>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Case Details
      </Typography>
      <StyledBox maxWidth={576}>{caseInformation()}</StyledBox>
      <StyledBox>{isLoading ? <Loading /> : serviceInformation()}</StyledBox>

      <UpdateCaseDialog
        open={showUpdateCaseDialog}
        error={isErrorUpdateCase}
        onConfirm={() => {
          setShowUpdateCaseDialog(false)
          if (!isErrorUpdateCase) {
            history.push(ROUTE_PATHS.CASES)
          }
        }}
        onClose={() => setShowUpdateCaseDialog(false)}
      />
    </Container>
  )
}
