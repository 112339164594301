import { Box } from '@mui/material'
import { TextFieldInput } from 'components'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const vehicleSchema = yup.object({
  year: yup.string().length(4).optional(),
  color: yup.string().optional(),
  model: yup.string().optional()
})

export type VehicleData = yup.InferType<typeof vehicleSchema>

type VehicleFormProps = {
  onSubmit: (data: VehicleData) => void
  actions?: React.ReactNode
}

export const VehicleForm = ({ onSubmit, actions }: VehicleFormProps) => {
  const useYupValidationResolver =
    (validationSchema: yup.ObjectSchema<VehicleData>) => async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
    }

  const resolver = useYupValidationResolver(vehicleSchema)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver })

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <TextFieldInput
        fullWidth
        error={!!errors['year']}
        placeholder="Year"
        inputLabel="Year"
        id="year"
        register={{ ...register('year') }}
      />
      <Box mt={4}>
        <TextFieldInput
          fullWidth
          error={!!errors['color']}
          placeholder="Color"
          inputLabel="Color"
          id="color"
          register={{ ...register('color') }}
        />
      </Box>
      <Box mt={4}>
        <TextFieldInput
          fullWidth
          error={!!errors['model']}
          placeholder="Model"
          inputLabel="Model"
          id="model"
          register={{ ...register('model') }}
        />
      </Box>
      {actions}
    </form>
  )
}
