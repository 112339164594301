import React from 'react'
import { ConfirmationDialog } from 'components'

export const NotEligibleDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <ConfirmationDialog
      icon="error"
      open={open}
      onConfirm={onClose}
      onConfirmLabel="CLOSE"
      title="This vehicle is not eligible for the program."
      content="At this moment this vehicle is not eligible for the program, please contact us."
    />
  )
}
