import { add, format } from 'date-fns'
import { ReactElement } from 'react'

export const valueGetter = (params) => {
  if (!params.value) {
    return '-'
  } else {
    return params.value
  }
}

export const renderRegion = (regionCode: string) => {
  if (!regionCode) return ''
  switch (regionCode) {
    case '31':
      return 'Western'
    case '32':
      return 'Central'
    case '33':
      return 'Eastern'
    case '34':
      return 'Southern'
  }
}

export const renderTelephones = (phoneNumbers) => {
  if (!phoneNumbers || phoneNumbers.length === 0) {
    return '-'
  } else if (phoneNumbers.length === 1) {
    return phoneNumbers[0].phoneNumber
  } else {
    const formattedNumbers = phoneNumbers.map((obj) => obj.phoneNumber)
    return formattedNumbers.join(', ')
  }
}

export const renderEmails = (emails) => {
  if (!emails || emails.length === 0) {
    return '-'
  } else if (emails.length === 1) {
    return emails[0].address
  } else {
    const formattedNumbers = emails.map((email) => email.address)
    return formattedNumbers.join(', ')
  }
}

export function deepEqual(obj1, obj2) {
  const str1 = JSON.stringify(obj1)
  const str2 = JSON.stringify(obj2)

  return str1 === str2
}

export async function blobUrlToBase64(blobUrl) {
  return await fetch(blobUrl)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
}

export function getDifferentAttributes(obj1, obj2) {
  const result = {}

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      result[key] = obj1[key]
    }
  }

  return { id: obj2.id, ...result }
}

export function formatTime(time: string | Date) {
  const date = format(new Date(time), 'MM/dd/yyyy')
  const hours = format(new Date(time), 'hh:mm a')
  return `${date} - ${hours}`
}

export function localDateToUtc(date: Date) {
  const utcDate = add(date, {
    minutes: date.getTimezoneOffset()
  })

  return utcDate
}

export function RenderData({
  data,
  loading,
  error,
  SuccessState,
  LoadingState,
  ErrorState,
  EmptyState
}: {
  data: { length: unknown } | null
  loading: boolean
  error: unknown
  SuccessState: ReactElement
  LoadingState: ReactElement
  ErrorState: ReactElement
  EmptyState: ReactElement
}) {
  if (data && !data?.length) return EmptyState
  if (data) return SuccessState
  if (loading) return LoadingState
  if (error) return ErrorState
}
