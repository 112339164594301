import { CloseOutlined } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, Divider } from '@mui/material'
import React from 'react'
import theme from 'theme'

interface VehiclePhotoDialogProps {
  setOpenImage: React.Dispatch<React.SetStateAction<boolean>>
  openImage: boolean
  image: string
  DialogToggler: React.ReactNode
}

export function VehiclePhotoDialog({
  setOpenImage,
  openImage,
  image,
  DialogToggler
}: VehiclePhotoDialogProps) {
  return (
    <>
      {DialogToggler}
      <Dialog maxWidth="sm" fullWidth onClose={() => setOpenImage(false)} open={openImage}>
        <Box
          sx={{ pt: 2, px: 3 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <DialogTitle sx={{ p: 0 }}>Vehicle Photo</DialogTitle>
          <Divider />
          <CloseOutlined
            sx={{
              '&:hover': {
                cursor: 'pointer',
                transform: 'scale(1.2)',
                transition: 'transform 0.3s'
              }
            }}
            onClick={() => setOpenImage(false)}
          />
        </Box>
        <DialogContent>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              border: `2px solid ${theme.palette.primary.light}`
            }}
            src={image}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
