import React from 'react'
import { Button as MaterialButton, styled } from '@mui/material'

const StyledAction = styled(MaterialButton)(({ theme }) => ({
  borderRadius: 0,
  textTransform: 'unset',
  borderColor: theme.palette.secondary.main,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  fontSize: 14
}))

export const Button = (props: React.ComponentProps<typeof MaterialButton>) => (
  <StyledAction {...props} />
)
