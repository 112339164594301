import { useEffect, useState } from 'react'
import { option } from 'components'
import { ROLES } from 'constants/Roles'
import { Dealer, useGetDealers, getSessionUser } from 'hooks'

const dealerComparator = (a: Dealer, b: Dealer) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()

  if (nameA > nameB) {
    return 1
  }
  if (nameA < nameB) {
    return -1
  }
  return 0
}

const initialDealerList = [
  {
    label: 'Select a dealer',
    value: ''
  }
]

interface GetFilteredDealers {
  area: string
  district: string
}

export function useGetFilteredDealers({ area, district }: GetFilteredDealers): option[] {
  const user = getSessionUser()
  const [filteredDealers, setFilteredDealers] = useState<option[]>(initialDealerList)
  const { data, isLoading, isError } = useGetDealers({ pageSize: 0 })

  useEffect(() => {
    if ((isLoading && !data) || isError) {
      setFilteredDealers(initialDealerList)
      return
    }

    data.foi.sort(dealerComparator)

    if (user?.role === ROLES.DEALER) {
      const dealer = data.foi.find((dealer) => dealer.id === user?.dealerCode)
      setFilteredDealers([...initialDealerList, { label: dealer?.name, value: dealer?.id }])
      return
    }

    if (user?.role === ROLES.MANAGER) {
      const areaDealers = data.foi.filter((dealer) => dealer.divisionCode === user?.region)

      setFilteredDealers([
        ...initialDealerList,
        ...areaDealers.map((dealer) => {
          return { label: dealer.name, value: dealer.id }
        })
      ])
      return
    }

    let dealers = data.foi

    if (area) {
      dealers = dealers.filter((dealer) => dealer.divisionCode === area)
    }

    if (district) {
      dealers = dealers.filter((dealer) => dealer.districtID === district)
    }

    setFilteredDealers([
      ...initialDealerList,
      ...dealers.map((dealer) => {
        return { label: dealer.name, value: dealer.id }
      })
    ])
  }, [data, user?.role, area, district])

  return filteredDealers
}
