import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js/types/lib/oidc/types/UserClaims'
import { Container } from 'components'
import { useUser } from 'hooks'
import { GetHelpContent } from 'pages'
import { ROLES } from 'constants/Roles'
import {
  OpenCaseAlertWidget,
  ClosedCaseAlertWidget,
  CreateCaseWidget,
  DealersWidget,
  EnrollmentStatusWidget,
  SubsidyProgramWidget,
  ManagerSubsidyAlertWidget,
  AdminSubsidyAlertWidget
} from './Widgets'

export function Home() {
  const [user] = useUser()
  const { oktaAuth } = useOktaAuth()
  const [userInfo, setUserInfo] = useState({} as UserClaims<CustomUserClaims>)
  const isManager = user?.role === ROLES.MANAGER
  const isAdmin = user?.role === ROLES.ADMIN
  const isDealer = user?.role === ROLES.DEALER

  useEffect(() => {
    if (Boolean(user) && Object.keys(user).length > 0) {
      oktaAuth.token.getUserInfo().then((info) => {
        setUserInfo(info)
      })
    }
  }, [user])

  return (
    <>
      <Container title={Boolean(userInfo.given_name) ? `Hello ${userInfo.given_name}` : 'Hello'}>
        {isManager && <ManagerSubsidyAlertWidget />}
        {isAdmin && <AdminSubsidyAlertWidget />}
        {(isAdmin || isManager) && <ClosedCaseAlertWidget />}
        {isDealer && <OpenCaseAlertWidget />}
        {isDealer && (
          <CreateCaseWidget
            subtitle={
              isDealer
                ? 'Open the case to support your client.'
                : 'Fill in the information to check the eligibility of the vehicle.'
            }
          />
        )}
        <SubsidyProgramWidget
          subtitle={
            isDealer
              ? 'Check your information or submit a new request on the Subsidy Program Section. '
              : 'Manage the subsidy requests'
          }
        />
        {isDealer && <EnrollmentStatusWidget />}
        {(isAdmin || isManager) && <DealersWidget />}
        <GetHelpContent />
      </Container>
    </>
  )
}
