import React from 'react'
import { useTheme, styled } from '@mui/material'
import { DatePicker as XDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { isEqual, isWithinInterval } from 'date-fns'
import { DateField } from './DateField'
import { TextFieldInputProps } from 'components'

interface DateFieldInputProps {
  ownerState?: TextFieldInputProps
}

interface CustomPickerDayProps {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'
interface CustomDatePickerProps extends DatePickerProps<unknown> {
  dateFrom?: object
  dateTo?: object
  closeOnSelect?: boolean
  placement?: Placement
}

const CustomDateField = (props: DateFieldInputProps & DatePickerProps<unknown>) => (
  <DateField
    inputLabel={props.ownerState?.inputLabel}
    required={props.ownerState?.required}
    register={props.ownerState?.register}
    placeholder="Date"
    format="MM-DD-YYYY"
    {...props}
  />
)

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...((dayIsBetween || isFirstDay || isLastDay) && {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white
    }
  })
})) as React.ComponentType<CustomPickerDayProps>

const CustomDay = (props: any) => {
  const { day, dateFrom, dateTo, ...other } = props

  if (!dateFrom || !dateTo || !(dateFrom <= dateTo)) {
    return <PickersDay day={day} {...other} />
  }

  const dayIsBetween = isWithinInterval(day, { start: dateFrom, end: dateTo })
  const isFirstDay = isEqual(day, dateFrom)
  const isLastDay = isEqual(day, dateTo)

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  )
}

export const DatePicker = (props: TextFieldInputProps & CustomDatePickerProps) => {
  const theme = useTheme()
  const { closeOnSelect, dateFrom, dateTo, placement, open } = props
  return (
    <XDatePicker
      open={open}
      {...props}
      slots={{ field: CustomDateField, day: CustomDay, ...props }}
      slotProps={{
        day: {
          dateFrom,
          dateTo
        } as unknown,
        popper: {
          placement
        }
      }}
      sx={{ svg: { color: theme.palette.primary.contrastText } }}
      closeOnSelect={Boolean(closeOnSelect)}
    />
  )
}
