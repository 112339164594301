import React, { useState } from 'react'
import jsPDF, { jsPDFOptions } from 'jspdf'
import autoTable, { UserOptions } from 'jspdf-autotable'
import { Container } from 'components'
import { ReportForm } from './ReportForm'
import { NotFoundDialog } from './NotFoundDialog'
import {
  ReportDailyServiceResponse,
  useReportDailyService,
  ReportRequest,
  getSessionUser
} from 'hooks'
import { ROLES } from 'constants/Roles'
import { startOfYesterday } from 'date-fns'
import { ErrorDialog } from './ErrorDialog'
import { ReportFormDealer } from './ReportFormDealer'

const defaultDate = startOfYesterday().toString()

export function ReportsDailyService() {
  const user = getSessionUser()
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [area, setArea] = useState('')
  const [district, setDistrict] = useState('')
  const [dealerId, setDealer] = useState('')
  const [completedOnly, setCompletedOnly] = useState(true)
  const [reportData, setReportData] = useState<ReportDailyServiceResponse[]>()
  const [showNotFoundDialog, setShowNotFoundDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    mutateAsync: getDailyService,
    isLoading: isLoadingReport,
    isError: isErrorReport
  } = useReportDailyService()

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    const request: ReportRequest = {
      fromDate: fromDate?.toString() || defaultDate,
      toDate: toDate?.toString() || defaultDate,
      completed: completedOnly
    }

    if (user?.role === ROLES.ADMIN) {
      request.area = area
      request.district = district
      request.dealerId = dealerId
    } else if (user?.role === ROLES.MANAGER) {
      request.area = String(user?.region)
      request.dealerId = dealerId
    } else if (user?.role === ROLES.DEALER) {
      request.dealerId = String(user?.dealerCode)
    }

    try {
      const response = await getDailyService(request)
      if (!isErrorReport && Object.keys(response).length > 0) {
        setReportData(response)
      } else {
        setShowNotFoundDialog(true)
      }
    } catch (error) {
      setErrorMessage(error.response.data.message)
    }
  }

  const handleClearInputs = () => {
    setFromDate(null)
    setToDate(null)
    setArea('')
    setDistrict('')
    setDealer('')
    setReportData(undefined)
  }

  const handleDownloadPDF = () => {
    const docConfig: jsPDFOptions = {
      format: 'a4',
      orientation: 'landscape'
    }
    const head = [
      {
        id: 'Case #',
        lastName: 'Last Name',
        firstName: 'First Name',
        vehicleYear: 'Year',
        vehicleModel: 'Model',
        vin: 'VIN',
        createdAt: 'Dispatch Date',
        requestedServiceCode: 'Requested Service',
        statusTime: 'Completed Date',
        providedServiceCode: 'Provided Service',
        dealerName: 'Dealer Name',
        region: 'Area',
        districtId: 'District'
      }
    ]
    const options: Partial<UserOptions> = {
      head,
      startY: 10,
      headStyles: {
        halign: 'center',
        valign: 'middle',
        minCellWidth: 5,
        fillColor: '#648EAE',
        fontSize: 8,
        fontStyle: 'bold',
        lineWidth: 0.1
      },
      bodyStyles: {
        halign: 'left',
        valign: 'middle',
        fontSize: 9
      }
    }
    const doc = new jsPDF(docConfig)
    autoTable(doc, {
      ...options,
      body:
        reportData &&
        reportData.map((row) => {
          return {
            id: row.id,
            lastName: row.lastName,
            firstName: row.firstName,
            vehicleYear: row.vehicleYear,
            vehicleModel: row.vehicleModel,
            vin: row.vin,
            createdAt: new Date(row.createdAt).toLocaleString(),
            requestedServiceCode: row.requestedServiceCode,
            statusTime: new Date(row['caseStatuses.statusTime']).toLocaleString(),
            providedServiceCode: row.providedServiceCode,
            dealerName: row.dealerName,
            region: row.region,
            districtId: row.districtId
          }
        })
    })
    doc.save(`${new Date().toISOString()}-daily-service-report.pdf`)
  }

  const handleDownloadCSV = () => {
    const csvData = [
      [
        'Case #',
        'Last Name',
        'First Name',
        'Year',
        'Model',
        'VIN',
        'Dispatch Date',
        'Requested Service',
        'Completed Date',
        'Provided Service',
        'Dealer Name',
        'Area',
        'District'
      ]
    ]

    reportData.map((row) =>
      csvData.push([
        row.id.toString(),
        row.lastName,
        row.firstName,
        row.vehicleYear,
        row.vehicleModel,
        row.vin,
        new Date(row.createdAt).toLocaleString(),
        row.requestedServiceCode,
        new Date(row['caseStatuses.statusTime']).toLocaleString(),
        row.providedServiceCode,
        row.dealerName,
        row.region,
        row.districtId
      ])
    )

    return csvData
  }

  return (
    <Container
      title="Daily Service Manager Report"
      subtitle="Use this screen to determine which cases to display in the report. Use any combination of the fields below. Some or all can be left blank. Default date range is yesterday.">
      {user?.role === ROLES.DEALER ? (
        <ReportFormDealer
          title="Search for Service Manager Report"
          reportName="Daily Service Report"
          handleSubmit={handleSubmit}
          dateRequired={false}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          completedOnly={completedOnly}
          setCompletedOnly={setCompletedOnly}
          reportData={reportData}
          isLoadingReport={isLoadingReport}
          isErrorReport={isErrorReport}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadCSV={handleDownloadCSV}
          handleClearInputs={handleClearInputs}
        />
      ) : (
        <ReportForm
          title="Search for Service Manager Report"
          reportName="Daily Service Report"
          handleSubmit={handleSubmit}
          dateRequired={false}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          area={area}
          setArea={setArea}
          district={district}
          setDistrict={setDistrict}
          dealerId={dealerId}
          setDealer={setDealer}
          completedOnly={completedOnly}
          setCompletedOnly={setCompletedOnly}
          reportData={reportData}
          isLoadingReport={isLoadingReport}
          isErrorReport={isErrorReport}
          handleDownloadPDF={handleDownloadPDF}
          handleDownloadCSV={handleDownloadCSV}
          handleClearInputs={handleClearInputs}
        />
      )}
      <NotFoundDialog open={showNotFoundDialog} onClose={() => setShowNotFoundDialog(false)} />
      <ErrorDialog
        content={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
    </Container>
  )
}
