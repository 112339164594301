import React from 'react'
import { Box, CircularProgress, Typography, BoxProps } from '@mui/material'

export function Loading({ sx, ...props }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 25,
        ...sx
      }}
      {...props}>
      <CircularProgress color="secondary" />
      <Typography variant="body1" sx={{ marginLeft: 2 }}>
        Loading...
      </Typography>
    </Box>
  )
}
