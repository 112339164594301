export const REGION_MAP = {
  '31': 'Western',
  '32': 'Central',
  '33': 'Eastern',
  '34': 'Southern'
}

export const CORP_REGION_MAP = {
  '310': '31', //'Lexus Western'
  '320': '32', //'Lexus Central'
  '330': '33', //'Lexus Eastern'
  '340': '34' //'Lexus Southern'
}
