import { useQuery } from '@tanstack/react-query'
import { useClient, RequestStatus, Vehicle } from 'hooks'
import { GetSubsidyRequestsResponse } from './GetSubsidyRequestsResponse'
import { format } from 'date-fns'

export interface GetSubsidyRequests {
  id?: number
  type?: string
  region?: string
  district?: string
  dealerCode?: string
  dealerName?: string
  limit?: number
  offset?: number
  fromDate?: string | Date
  toDate?: string | Date
  mainVehicleId?: string | number
  mainVehicle?: Vehicle
  createdAt?: string | Date
  requestStatuses?: RequestStatus
  status?: string
}

export function useGetSubsidyRequests(params?: GetSubsidyRequests) {
  const client = useClient()

  return useQuery(['subsidyRequests', { params }], async () => {
    if (params.fromDate && params.toDate) {
      params.fromDate = format(new Date(params.fromDate), 'yyyy-MM-dd')
      params.toDate = format(new Date(params.toDate), 'yyyy-MM-dd')
    }

    for (const key in params) {
      if (params[key] === '') {
        delete params[key]
      }
    }
    try {
      return (await client.get('/subsidy-requests', { params })) as GetSubsidyRequestsResponse
    } catch (error) {
      throw new Error(error)
    }
  })
}
