import React, { useState } from 'react'
import {
  Backdrop,
  Box,
  Drawer,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
  DrawerProps
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'components'

interface Option {
  label: string
  value: unknown
}

interface ExportDrawerProps {
  open: boolean
  title: string
  confirmText: string
  onClose: () => void
  onConfirm: (value: unknown) => void
  options: Option[]
}

const StyledSidebar = styled(Drawer)(() => ({
  '.MuiDrawer-paperAnchorRight': {
    width: 400,
    zIndex: 1402
  }
}))

const StyledBackdrop = styled(Backdrop)(() => ({
  zIndex: 1402
}))

export const ExportDrawer = ({
  onConfirm,
  open,
  title,
  onClose,
  content,
  options,
  confirmText,
  ...props
}: ExportDrawerProps & DrawerProps) => {
  const [selectedOption, setSelectedOption] = useState({} as any)

  const onClickConfirmation = () => {
    const { value } = selectedOption
    onConfirm && onConfirm(value)
  }

  return (
    <>
      <StyledBackdrop open={open} />
      <StyledSidebar variant="persistent" anchor="right" open={open} {...props}>
        <Box sx={{ mx: 4, my: 2, display: 'flex', justifyContent: 'space-between', height: 32 }}>
          <Box>
            <Typography color="inherit" variant="h5">
              {title}
            </Typography>
          </Box>
          <Box sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex' }} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mx: 4, my: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography color="inherit" variant="body1">
              {content}
            </Typography>
            <FormControl>
              <RadioGroup>
                {options?.map((op) => (
                  <FormControlLabel
                    key={`${op.value}-${op.label}`}
                    value={op.value}
                    control={
                      <Radio color="secondary" onClick={(e) => setSelectedOption(e.target)} />
                    }
                    label={op.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Button fullWidth color="secondary" variant="contained" onClick={onClickConfirmation}>
            {confirmText}
          </Button>
        </Box>
      </StyledSidebar>
    </>
  )
}
