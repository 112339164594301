import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'

export const Logo = () => {
  return (
    <Box sx={{ userSelect: 'none' }}>
      <Grid container spacing={1} display="flex" alignItems="center">
        <Grid item display="flex" flexDirection="column" alignItems="center">
          <Typography
            color="inherit"
            variant="h6"
            fontWeight={800}
            component="span"
            sx={{ lineHeight: 1 }}
          >
            SERVICE
          </Typography>
          <Typography
            color="inherit"
            sx={{ lineHeight: 1 }}
            variant="body2"
            fontWeight={100}
            component="span"
          >
            BY LEXUS
          </Typography>
        </Grid>
        <Grid width="full" item>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ height: 42 }} />
        </Grid>
        <Grid item display="flex" flexDirection="column" alignItems="start">
          <Typography color="inherit" fontWeight={800} component="span" sx={{ lineHeight: 1 }}>
            ROADSIDE
          </Typography>
          <Typography color="inherit" fontWeight={800} component="span" sx={{ lineHeight: 1 }}>
            ASSISTANCE
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
