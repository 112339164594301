import { GetHelp } from 'pages/GetHelp'
import { ROLES } from './Roles'
import {
  Cases,
  CaseDetails,
  Home,
  NewCase,
  Notifications,
  Profile,
  ReportsAcceptanceRate,
  ReportsDailyService,
  ReportsLRA,
  SubsidyRequests,
  SubsidySubmit,
  SubsidyManage,
  Vehicles,
  DealersSummary,
  DealerDetails,
  DealersEnrollment,
  VehicleDetails,
  SubsidyRequestDetails,
  Managers
} from 'pages'

export const ROUTE_PATHS = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  HOME: '/home',
  REPORTS_ACCEPTANCE_RATE: '/reports/acceptance-rate',
  REPORTS_DAILY_SERVICE_MANAGER: '/reports/daily-service-manager',
  REPORTS_LRA: '/reports/lra',
  DEALERS_SUMMARY: '/dealers/summary',
  DEALER_DETAILS: '/dealers/:id',
  DEALERS_ENROLLMENT: '/dealers/enrollment',
  CASES: '/cases',
  NEW_CASE: '/case/new',
  CASE_DETAILS: '/case/:id',
  MANAGERS: '/managers',
  NOTIFICATIONS: '/notifications',
  GET_HELP: '/gethelp',
  PROFILE: '/profile',
  SUBSIDIES: '/subsidies',
  SUBSIDY_VEHICLES: '/subsidy/vehicles',
  VEHICLE_DETAILS: '/subsidy/vehicles/:id',
  SUBSIDY_REQUESTS: '/subsidy/requests',
  SUBSIDY_REQUEST_DETAILS: '/subsidy/requests/:id',
  SUBSIDY_SUBMIT: '/subsidy/submit',
  SUBSIDY_MANAGE: '/subsidy/manage'
}

export const ROUTES = [
  {
    path: ROUTE_PATHS.HOME,
    Component: Home,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.REPORTS_ACCEPTANCE_RATE,
    Component: ReportsAcceptanceRate,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN],
    secure: true
  },
  {
    path: ROUTE_PATHS.REPORTS_DAILY_SERVICE_MANAGER,
    Component: ReportsDailyService,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.REPORTS_LRA,
    Component: ReportsLRA,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.CASES,
    Component: Cases,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.NEW_CASE,
    Component: NewCase,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER],
    secure: true
  },
  {
    path: ROUTE_PATHS.CASE_DETAILS,
    Component: CaseDetails,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.MANAGERS,
    Component: Managers,
    requiredRoles: [ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.SUBSIDY_VEHICLES,
    Component: Vehicles,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.VEHICLE_DETAILS,
    Component: VehicleDetails,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN],
    secure: true
  },
  {
    path: ROUTE_PATHS.SUBSIDY_REQUESTS,
    Component: SubsidyRequests,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.SUBSIDY_REQUEST_DETAILS,
    Component: SubsidyRequestDetails,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN],
    secure: true
  },
  {
    path: ROUTE_PATHS.SUBSIDY_SUBMIT,
    Component: SubsidySubmit,
    requiredRoles: [ROLES.DEALER]
  },
  {
    path: ROUTE_PATHS.SUBSIDY_MANAGE,
    Component: SubsidyManage,
    requiredRoles: [ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.NOTIFICATIONS,
    Component: Notifications,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.GET_HELP,
    Component: GetHelp,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.PROFILE,
    Component: Profile,
    requiredRoles: [ROLES.DEALER, ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.DEALERS_SUMMARY,
    Component: DealersSummary,
    requiredRoles: [ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.DEALERS_ENROLLMENT,
    Component: DealersEnrollment,
    requiredRoles: [ROLES.MANAGER, ROLES.ADMIN]
  },
  {
    path: ROUTE_PATHS.DEALER_DETAILS,
    Component: DealerDetails,
    requiredRoles: [ROLES.MANAGER, ROLES.ADMIN]
  }
]
