import { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import {
  CustomUserClaim,
  CustomUserClaims,
  UserClaims
} from '@okta/okta-auth-js/types/lib/oidc/types/UserClaims'
import { ROLES } from 'constants/Roles'
import { CORP_REGION_MAP } from 'constants/Region'

export interface UserData {
  claims?: UserClaims<CustomUserClaims>
  role?: CustomUserClaim | CustomUserClaim[]
  dealerCode?: CustomUserClaim | CustomUserClaim[]
  region?: CustomUserClaim | CustomUserClaim[]
}

const getRegionCode = (claims) => {
  //will return regionCodeDLS if dealer, map corpRegionCodeDLS otherwise
  if (claims.userRoleDLS === ROLES.DEALER) {
    return claims.regionCodeDLS
  } else {
    return CORP_REGION_MAP[claims.corpRegionCodeDLS] //mapped to DLS region
  }
}

const useUser = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [userData, setUserData] = useState({} as UserData)

  const logout = async () => {
    if (authState.isAuthenticated) {
      sessionStorage.setItem('userData', 'logout')
      await oktaAuth.signOut()
    }
  }

  useEffect(() => {
    if (!authState.isAuthenticated) {
      sessionStorage.removeItem('userData')
      setUserData({})
    } else {
      const claims = authState.accessToken.claims
      const data = {
        claims,
        role: claims.userRoleDLS, //Should be: DLS_Admin , DLS_Manager or DLS_Dealer
        dealerCode: claims.dealerCodeDLS, //only valid for DLS_Dealer
        region: getRegionCode(claims) //regionCodeDLS if dealer, map corpRegionCodeDLS otherwise
      }
      if (claims.userRoleDLS === ROLES.MANAGER && !data.region) logout()
      setUserData(data)
      sessionStorage.setItem('userData', JSON.stringify(data))
    }
  }, [authState, oktaAuth])

  return [userData]
}

export { useUser }

export const getSessionUser = () => {
  const { oktaAuth } = useOktaAuth()
  try {
    const user = JSON.parse(sessionStorage.getItem('userData')) as UserData
    if (!user || Object.keys(user).length === 0) {
      oktaAuth.signOut()
    }
    return user
  } catch (error) {
    oktaAuth.signOut()
  }
}
