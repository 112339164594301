import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { DatePicker, Select, TextFieldInput, TimePicker } from 'components'
import { SERVICES_OPTIONS } from 'constants/Services'
import { isBefore, isSameDay } from 'date-fns'

type ServiceFormProps = {
  onSubmit: (data) => void
  actions?: React.ReactNode
  isClosed?: boolean
  initialValues?: InitialValues //only used if case isClosed
  dispatchDate?: string | Date
}

type InitialValues = {
  odometer: number
  latestStatus: string | Date
  service: string
}

export const ServiceForm = ({
  onSubmit,
  actions,
  initialValues,
  isClosed = false,
  dispatchDate
}: ServiceFormProps) => {
  const [arrivalDate, setArrivalDate] = useState<any>(
    isClosed ? new Date(initialValues?.latestStatus) : null
  )
  const [arrivalTime, setArrivalTime] = useState<any>(
    isClosed ? new Date(initialValues?.latestStatus) : null
  )
  const [odometer, setOdometer] = useState<number>(null)
  const [serviceType, setServiceType] = useState<string>(null)

  //min service date is the dispatch date
  const minServiceDate = dispatchDate ? new Date(dispatchDate) : null

  let checkMinServiceTime = null

  const handleCustomSubmit = (e) => {
    e.preventDefault()

    if (isBefore(arrivalTime, new Date(dispatchDate))) {
      return
    }

    onSubmit({
      odometer,
      serviceDateAndTime: arrivalTime,
      serviceType
    })
  }

  //disable time before dispatch date if choosing service on the same day as dispatch
  checkMinServiceTime =
    Boolean(arrivalDate) &&
    Boolean(dispatchDate) &&
    isSameDay(new Date(arrivalDate), new Date(dispatchDate))

  return (
    <form onSubmit={handleCustomSubmit}>
      <Box display="flex" mt={4}>
        <Box flex={1}>
          <DatePicker
            minDate={minServiceDate}
            disabled={isClosed}
            required
            inputLabel="Arrival Date"
            onChange={(value) => {
              setArrivalDate(value)

              let timeDate = new Date(value as string)
              timeDate.setHours(new Date().getHours())
              timeDate.setMinutes(new Date().getMinutes())
              timeDate.setSeconds(new Date().getSeconds())
              setArrivalTime(timeDate) //set arrival time to selected date and current time
            }}
            value={arrivalDate}
          />
        </Box>
        <Box flex={1} ml={3}>
          <TimePicker
            disabled={isClosed || !arrivalDate}
            minTime={checkMinServiceTime ? minServiceDate : null}
            required
            value={arrivalTime}
            onChange={(value) => {
              setArrivalTime(value)
            }}
            inputLabel="Time"
            helperText={isClosed ? null : !arrivalDate ? 'Choose a date first ' : null}
          />
        </Box>
      </Box>
      <Box mt={4} display="flex">
        <Box flex={1}>
          <TextFieldInput
            disabled={isClosed}
            fullWidth
            placeholder="Odometer"
            required
            inputLabel="Odometer"
            onChange={(event) => {
              setOdometer(Number(event.target.value))
            }}
            id="odometer"
            defaultValue={initialValues?.odometer}
          />
        </Box>
        <Box flex={1} ml={3}>
          <Select
            disabled={isClosed}
            required
            inputlabel="Service Provided"
            placeholder="Select a Service"
            fullWidth
            options={SERVICES_OPTIONS}
            onChange={(event) => {
              setServiceType((event as React.ChangeEvent<{ value: string }>).target.value)
            }}
            id="service"
            defaultValue={initialValues?.service || ''}
          />
        </Box>
      </Box>
      {actions}
      {isClosed && (
        <Typography sx={{ mt: 4 }} fontStyle="bolder">
          Case status is closed
        </Typography>
      )}
    </form>
  )
}
