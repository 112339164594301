import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Box } from '@mui/material'
import { TextFieldInput } from 'components'

const eligibilitySchema = yup.object({
  vin: yup.string().required('Required'),
  odometer: yup.number().required('Required')
})

export type EligibilityData = yup.InferType<typeof eligibilitySchema>

type EligibilityFormProps = {
  onSubmit: (data: EligibilityData) => void
  actions?: React.ReactNode
}

export const EligibilityForm = ({ onSubmit, actions }: EligibilityFormProps) => {
  const useYupValidationResolver =
    (validationSchema: yup.ObjectSchema<EligibilityData>) => async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
    }

  const resolver = useYupValidationResolver(eligibilitySchema)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver })

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}>
      <TextFieldInput
        fullWidth
        error={!!errors['vin']}
        placeholder="VIN Number"
        inputLabel="Vehicle Identification Number*"
        id="vin"
        register={{ ...register('vin') }}
      />
      <Box mt={4}>
        <TextFieldInput
          fullWidth
          error={!!errors['odometer']}
          placeholder="Odometer Number"
          inputLabel="Odometer*"
          id="odometer"
          register={{ ...register('odometer') }}
        />
      </Box>
      {actions}
    </form>
  )
}
