import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useClient } from 'hooks'
import { UpdateRequestStatusResponse } from './UpdateRequestStatusResponse'
import { UpdateRequestStatusRequest } from './UpdateRequestStatusRequest'

export function useUpdateRequestStatus() {
  const queryClient = useQueryClient()
  const client = useClient()

  return useMutation(
    async (data: UpdateRequestStatusRequest) => {
      return (await client.post('/subsidy-request-status', data)) as UpdateRequestStatusResponse
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['updateRequestStatus'])
        queryClient.setQueryData(['updateRequestStatus'], result)
        return result
      },
      onError: () => {
        queryClient.setQueryData(['updateRequestStatus'], false)
        return false
      }
    }
  )
}
