import React, { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { ErrorOutline, WarningOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { Breadcrumb, Container, Select, TableContainer } from 'components'
import { ROUTE_PATHS } from 'constants/Routing'
import { Vehicle, getSessionUser, useGetVehicles, useUpdateVehicle } from 'hooks'
import { VehiclePhotoDialog } from 'pages/SubsidyProgram/Vehicles/VehiclePhotoDialog'
import { format } from 'date-fns'
import { ErrorDialog } from 'pages/Reports/ErrorDialog'

export function SubmitRemoval() {
  const history = useHistory()
  const user = getSessionUser()
  const [openImage, setOpenImage] = useState(false)
  const [vehicleToRemove, setVehicleToRemove] = useState<Vehicle | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [requestError, setRequestError] = useState<string | null>(null)
  const { data: vehicles } = useGetVehicles({
    active: true,
    dealerCode: user?.dealerCode.toString()
  })
  const { mutateAsync: updateVehicle, isLoading } = useUpdateVehicle()

  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))

  const vehicleOptions = vehicles?.data
    ? vehicles?.data.length
      ? vehicles?.data?.map((car) => ({
          label: `${car.make} ${car.model}, ${car.year}  `,
          value: car.id
        }))
      : [{ label: 'No vehicles found.', value: null }]
    : [{ label: 'Loading...', value: null }]

  async function handleVehicleRemoval() {
    if (!vehicleToRemove) {
      return setError('Please select a vehicle to remove.')
    } else {
      setError(null)
    }

    try {
      const updateRequest = await updateVehicle({
        id: vehicleToRemove?.id,
        active: false
      })

      if (updateRequest.status === 'ok') {
        history.push(ROUTE_PATHS.VEHICLE_DETAILS.replace(':id', vehicleToRemove?.id.toString()))
      }
    } catch (error) {
      setRequestError(error?.response?.data?.message)
    }
  }

  return (
    <Container
      title={
        <Box>
          <Breadcrumb
            paths={[
              { label: 'Submit a Request', path: ROUTE_PATHS.SUBSIDY_SUBMIT },
              { label: 'Vehicle Removal', path: ROUTE_PATHS.CASE_DETAILS + '?type=removal' }
            ]}
          />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Vehicle Removal
          </Typography>
        </Box>
      }>
      <TableContainer>
        <>
          <Typography variant="h5" fontWeight={700} sx={{ mb: 0.5 }}>
            Which vehicle is this request for?
          </Typography>

          <Typography fontWeight={400} fontSize={16} color={theme.palette.primary.dark}>
            Choose a vehicle to proceed with the removal.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              backgroundColor: theme.palette.warning.light,
              p: 2,
              border: `1px solid ${theme.palette.warning.dark}`,
              mt: 1,
              mb: 2
            }}>
            <WarningOutlined sx={{ fontSize: 30 }} />
            <Typography>
              Removals should only be submitted if the vehicle is not being used for the program
              anymore, otherwise you can submit a replacement request.
            </Typography>
          </Box>
          <Box flex={1} marginBottom={3}>
            <Select
              sx={{ width: '100%' }}
              inputlabel="Choose"
              value={vehicleToRemove?.id || ''}
              options={vehicleOptions}
              onChange={(e) => {
                setVehicleToRemove(vehicles?.data?.find((car) => car.id === e.target.value))
                setError(null)
              }}
            />
          </Box>
          {vehicleToRemove && (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                flexDirection={isDownMediumWidth ? 'column' : 'row'}
                sx={{
                  border: `1px solid ${theme.palette.primary.light}`,
                  p: 2,
                  borderRadius: 1,
                  gap: 4,
                  backgroundColor: theme.palette.secondary.light
                }}>
                <VehiclePhotoDialog
                  DialogToggler={
                    <Box
                      onClick={() => setOpenImage(true)}
                      height={200}
                      width={200}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        border: `1px solid ${theme.palette.primary.light}`,
                        backgroundColor: theme.palette.secondary.light,
                        backgroundImage: `url(${vehicleToRemove?.imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        cursor: 'pointer'
                      }}
                    />
                  }
                  setOpenImage={setOpenImage}
                  openImage={openImage}
                  image={vehicleToRemove?.imageUrl}
                />
                <Box flex={1} marginLeft={4} display="flex" flexDirection="column" gap={1.5}>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">VIN:</Typography>
                    <Typography>{vehicleToRemove.vin}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Make:</Typography>
                    <Typography>{vehicleToRemove.make}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Model:</Typography>
                    <Typography>{vehicleToRemove.model}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Year:</Typography>
                    <Typography>{vehicleToRemove.year}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Miles:</Typography>
                    <Typography>{vehicleToRemove.miles}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">DOFU:</Typography>
                    <Typography>{format(new Date(vehicleToRemove.dofu), 'MM-dd-yyyy')}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {error && (
            <Typography display="flex" alignItems="center" marginTop={2} color="red">
              <ErrorOutline sx={{ mr: 1 }} />
              {error}
            </Typography>
          )}
          {vehicleToRemove && <Divider sx={{ my: 4 }} />}

          <Box display="flex" justifyContent="space-between">
            <NavLink
              to={ROUTE_PATHS.SUBSIDY_SUBMIT}
              style={{
                color: theme.palette.primary.dark,
                fontSize: 16,
                textDecorationLine: 'underline',
                display: 'flex',
                alignItems: 'center'
              }}>
              BACK
            </NavLink>
            <Button
              sx={{
                px: 2,
                borderRadius: 0
              }}
              color="secondary"
              variant="contained"
              onClick={handleVehicleRemoval}>
              {isLoading ? <CircularProgress size={22} color="primary" /> : 'Remove'}
            </Button>
          </Box>
        </>
      </TableContainer>
      <ErrorDialog
        content={requestError}
        open={!!requestError}
        onClose={() => setRequestError(null)}
      />
    </Container>
  )
}
