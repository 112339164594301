import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  Loading,
  NoDataFoundMessage,
  Table,
  TableContainer,
  UnexpectedErrorMessage
} from 'components'
import { RequestsFilters } from './ViewRequests/RequestsFilters'
import theme from 'theme'
import { GetSubsidyRequests, useDebounce, useGetSubsidyRequests, getSessionUser } from 'hooks'
import { Box, Typography } from '@mui/material'
import { ROLES } from 'constants/Roles'
import { renderRegion, valueGetter } from 'utils'
import { ROUTE_PATHS } from 'constants/Routing'
import { format } from 'date-fns'
import { vehicleValueGetter } from './ViewRequests/SubsidyRequests'

const typeColumn = ({ row }) => {
  return (
    <Link
      style={{ color: 'inherit', fontWeight: 'bold' }}
      to={ROUTE_PATHS.SUBSIDY_REQUEST_DETAILS.replace(':id', row.id)}>
      {row.type.toUpperCase()}
    </Link>
  )
}

const vehicleColumn = ({ row }) => {
  return (
    <Link
      style={{ color: 'inherit' }}
      to={ROUTE_PATHS.VEHICLE_DETAILS.replace(':id', row?.mainVehicle?.id)}>
      <Typography
        variant="body2"
        sx={{ textDecoration: 'underline', cursor: 'pointer', ':hover': { color: 'black' } }}>
        {`${row?.mainVehicle?.make} ${row?.mainVehicle?.model}`}
      </Typography>
    </Link>
  )
}

const managerColumns = [
  {
    flex: 1,
    field: 'type',
    valueGetter,
    renderCell: typeColumn,
    headerName: 'Type',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'dealerCode',
    valueGetter,
    headerName: 'Dealer Code',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'mainVehicle',
    valueGetter: vehicleValueGetter,
    renderCell: vehicleColumn,
    headerName: 'Vehicle',
    headerClassName: 'dealersHeaderRow',
    sortComparator: (v1, v2) => v1.localeCompare(v2)
  },
  {
    flex: 1,
    field: 'createdAt',
    valueGetter,
    headerName: 'Date',
    renderCell: ({ row }) => format(new Date(row.createdAt), "MM-dd-yyyy 'at' hh:mm a"),
    headerClassName: 'dealersHeaderRow'
  }
]

const adminColumns = [
  {
    flex: 1,
    field: 'type',
    valueGetter,
    renderCell: typeColumn,
    headerName: 'Type',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'dealerCode',
    valueGetter,
    headerName: 'Dealer Code',
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'region',
    valueGetter,
    headerName: 'Region',
    renderCell: (params) => renderRegion(params.value),
    headerClassName: 'dealersHeaderRow'
  },
  {
    flex: 1,
    field: 'mainVehicle',
    valueGetter: vehicleValueGetter,
    renderCell: vehicleColumn,
    headerName: 'Vehicle',
    headerClassName: 'dealersHeaderRow',
    sortComparator: (v1, v2) => v1.localeCompare(v2)
  },
  {
    flex: 1,
    field: 'createdAt',
    valueGetter,
    headerName: 'Date',
    renderCell: ({ row }) => format(new Date(row.createdAt), "MM-dd-yyyy 'at' hh:mm a"),
    headerClassName: 'dealersHeaderRow'
  }
]

export function SubsidyManage() {
  const user = getSessionUser()
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [params, setParams]: [
    GetSubsidyRequests,
    React.Dispatch<React.SetStateAction<GetSubsidyRequests>>
  ] = useState({})
  const debouncedSearchParams = useDebounce(params)

  function displayColumns() {
    if (user?.role == ROLES.ADMIN) {
      return adminColumns
    } else if (user?.role == ROLES.MANAGER) {
      return managerColumns
    } else {
      return []
    }
  }

  function handlePaginationChange(params) {
    setLimit(params.pageSize)
    setOffset(params.page * params.pageSize)
    setPaginationMode({ page: params.page, pageSize: params.pageSize })
    refetch()
  }

  const { data, isLoading, isError, refetch } = useGetSubsidyRequests({
    limit,
    offset,
    status: user.role == ROLES.MANAGER ? 'pending' : 'managerApproved',
    region: user?.role === ROLES.MANAGER ? user?.region?.toString() : null,
    ...debouncedSearchParams
  })

  const [paginationModel, setPaginationMode] = useState({
    page: offset / limit,
    pageSize: limit
  })
  return (
    <Container
      fullWidth
      title="Manage Requests"
      subtitle="Use this section to manage open requests that require your action.">
      <TableContainer>
        <RequestsFilters
          params={params}
          setParams={setParams}
          data={data?.data}
          disabled={isLoading}
          managing={true}
        />

        {isLoading && !data && <Loading />}
        {!isLoading && !data && !isError && <NoDataFoundMessage />}
        {!isLoading && data && (
          <Box sx={{ border: `1px solid ${theme.palette.primary.light}` }}>
            <Table
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              rowCount={data.itemsCount}
              isLoading={isLoading}
              columns={displayColumns()}
              rows={data.data}
            />
          </Box>
        )}
        {!isLoading && isError && <UnexpectedErrorMessage />}
      </TableContainer>
    </Container>
  )
}
