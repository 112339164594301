import React from 'react'
import { ConfirmationDialog } from 'components'

export const ErrorDialog = ({
  content,
  open,
  onClose
}: {
  content: string
  open: boolean
  onClose: () => void
}) => {
  return (
    <ConfirmationDialog
      icon="error"
      open={open}
      onConfirm={onClose}
      onConfirmLabel="CLOSE"
      title="Something went wrong"
      content={content}
    />
  )
}
