import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material'
import { LockClockOutlined, AccessTimeOutlined, CancelOutlined } from '@mui/icons-material'
import { chipColor, chipLabel } from '../../shared/subsidyUtils'
import theme from 'theme'
import { useOktaAuth } from '@okta/okta-react'
import { ROLES } from 'constants/Roles'
import { CustomUserClaim } from '@okta/okta-auth-js'
import { formatTime } from 'utils'
import { RequestStatus, useUpdateRequestStatus } from 'hooks'

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 1,
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.dark}`
    }
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.primary.contrastText
  }
}))

interface EmptyStatusProps {
  title: string | React.ReactNode
  content: string | React.ReactNode
  icon: React.ReactNode
}

function EmptyStatus({ title, icon, content }: EmptyStatusProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.light,
        justifyContent: 'center',
        py: 10,
        px: 4,
        height: '100%',
        border: `1px solid ${theme.palette.primary.light}`
      }}>
      {icon}
      <Typography variant="body1" fontSize={24} fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body2" fontSize={16}>
        {content}
      </Typography>
    </Box>
  )
}

function renderAdminStatus(
  managerStatus: string,
  adminStatus: string,
  adminTable: React.ReactNode
) {
  if (!adminStatus) {
    switch (managerStatus) {
      case 'managerApproved':
        return (
          <EmptyStatus
            title="Admin Reviewing"
            icon={<AccessTimeOutlined sx={{ fontSize: 36 }} />}
            content="Waiting for Admin review."
          />
        )
      case 'managerDenied':
        return (
          <EmptyStatus
            title="Manager Denied"
            icon={<CancelOutlined sx={{ fontSize: 36 }} />}
            content="Request denied by Manager."
          />
        )
      default:
        return (
          <EmptyStatus
            title="Review blocked"
            icon={<LockClockOutlined sx={{ fontSize: 36 }} />}
            content="Waiting for Manager approval."
          />
        )
    }
  }

  return adminTable
}

const StyledTable = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    fontSize: 16
  }
}))

interface StatusProp {
  statuses: RequestStatus[]
  user: string | CustomUserClaim | CustomUserClaim[]
  refetch: () => void
  isRefetching: boolean
}

export function ReviewWorkflowDetails({ statuses, user, refetch, isRefetching }: StatusProp) {
  const [comments, setComments] = useState('')
  const { id } = useParams()
  const { mutateAsync: updateRequestStatus, isLoading: isUpdating } = useUpdateRequestStatus()
  const { oktaAuth } = useOktaAuth()
  const [username, setUsername] = useState('')
  const managerStatus = statuses.find(
    (status) => status.status === 'managerApproved' || status.status === 'managerDenied'
  )
  const adminStatus = statuses.find(
    (status) => status.status === 'adminApproved' || status.status === 'adminDenied'
  )

  oktaAuth.token.getUserInfo().then((info) => {
    setUsername(info?.given_name && `${info?.given_name} ${info?.family_name}`)
  })

  async function updateStatus(status: string, setter: string) {
    await updateRequestStatus({
      subsidyRequestId: Number(id),
      setterName: setter,
      status,
      comments
    })
    refetch()
  }

  const theme = useTheme()
  const isDownMediumWidth = useMediaQuery(theme.breakpoints.down('md'))

  const reviewStyle = isDownMediumWidth
    ? { display: 'flex', gap: 4, flexWrap: 'wrap' }
    : { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }

  switch (user) {
    case ROLES.DEALER:
      return (
        <Box sx={reviewStyle}>
          <Box height="100%" width="100%">
            {managerStatus ? (
              <TableContainer
                sx={{ border: `1px solid ${theme.palette.primary.light}` }}
                elevation={0}
                component={Paper}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                            whiteSpace: 'nowrap'
                          }}>
                          Manager Review Details
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell>
                        {managerStatus?.setterName ? managerStatus.setterName : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Decision</TableCell>
                      <TableCell>
                        {managerStatus?.status ? (
                          <Chip
                            sx={{
                              borderRadius: 0.5,
                              pb: 0,
                              fontSize: 16,
                              ...chipColor(managerStatus?.status)
                            }}
                            label={chipLabel(managerStatus?.status)}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Date</TableCell>
                      <TableCell>
                        {managerStatus?.statusTime ? formatTime(managerStatus?.statusTime) : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, borderBottom: 'none' }}>Comments</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {managerStatus?.comments ? managerStatus?.comments : '-'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </StyledTable>
              </TableContainer>
            ) : (
              <EmptyStatus
                title="Manager Reviewing"
                icon={<AccessTimeOutlined sx={{ fontSize: 36 }} />}
                content="Waiting Manager Review."
              />
            )}
          </Box>
          <Box height="100%" width="100%">
            {renderAdminStatus(
              managerStatus?.status,
              adminStatus?.status,
              <TableContainer
                sx={{ border: `1px solid ${theme.palette.primary.light}` }}
                elevation={0}
                component={Paper}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                            whiteSpace: 'nowrap'
                          }}>
                          Admin Review Details
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell>{adminStatus?.setterName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Decision</TableCell>
                      <TableCell>
                        <Chip
                          sx={{
                            borderRadius: 0.5,
                            pb: 0,
                            fontSize: 16,
                            ...chipColor(adminStatus?.status)
                          }}
                          label={chipLabel(adminStatus?.status)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Date</TableCell>
                      <TableCell>
                        {adminStatus?.statusTime ? formatTime(adminStatus?.statusTime) : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, borderBottom: 'none' }}>Comments</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {adminStatus?.comments ? adminStatus?.comments : '-'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </StyledTable>
              </TableContainer>
            )}
          </Box>
        </Box>
      )
    case ROLES.MANAGER:
      return (
        <Box sx={reviewStyle}>
          <Box height="100%" width="100%">
            {managerStatus ? (
              <TableContainer
                sx={{ border: `1px solid ${theme.palette.primary.light}` }}
                elevation={0}
                component={Paper}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                            whiteSpace: 'nowrap'
                          }}>
                          Manager Review Details
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell>
                        {managerStatus?.setterName ? managerStatus.setterName : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Decision</TableCell>
                      <TableCell>
                        {managerStatus?.status ? (
                          <Chip
                            sx={{
                              borderRadius: 0.5,
                              pb: 0,
                              fontSize: 16,
                              ...chipColor(managerStatus?.status)
                            }}
                            label={chipLabel(managerStatus?.status)}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Date</TableCell>
                      <TableCell>
                        {managerStatus?.statusTime ? formatTime(managerStatus?.statusTime) : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, borderBottom: 'none' }}>Comments</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {managerStatus?.comments ? managerStatus?.comments : '-'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </StyledTable>
              </TableContainer>
            ) : (
              <Box>
                <Typography variant="h6">Review Workflow Details</Typography>
                <Typography variant="body1" mb={2}>
                  Use this space to approve/deny the request.
                </Typography>
                <StyledTextField
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                  type="text"
                  multiline
                  rows={4}
                  sx={{ width: '100%', mb: 2 }}
                  label="Comments"
                  id="comments"
                />
                <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
                  {!isUpdating && !isRefetching ? (
                    <Button
                      onClick={() => updateStatus('managerDenied', username)}
                      sx={{ borderRadius: 0 }}
                      variant="outlined"
                      color="secondary">
                      Deny
                    </Button>
                  ) : null}

                  <Button
                    onClick={() => updateStatus('managerApproved', username)}
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: 0 }}
                    color="secondary">
                    {isUpdating || isRefetching ? (
                      <CircularProgress size={22} color="primary" />
                    ) : (
                      'Approve'
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box height="100%" width="100%">
            {renderAdminStatus(
              managerStatus?.status,
              adminStatus?.status,
              <TableContainer
                sx={{ border: `1px solid ${theme.palette.primary.light}` }}
                elevation={0}
                component={Paper}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                            whiteSpace: 'nowrap'
                          }}>
                          Admin Review Details
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell>{adminStatus?.setterName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Decision</TableCell>
                      <TableCell>
                        <Chip
                          sx={{
                            borderRadius: 0.5,
                            pb: 0,
                            fontSize: 16,
                            ...chipColor(adminStatus?.status)
                          }}
                          label={chipLabel(adminStatus?.status)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Date</TableCell>
                      <TableCell>
                        {adminStatus?.statusTime ? formatTime(adminStatus?.statusTime) : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, borderBottom: 'none' }}>Comments</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {adminStatus?.comments ? adminStatus?.comments : '-'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </StyledTable>
              </TableContainer>
            )}
          </Box>
        </Box>
      )
    case ROLES.ADMIN:
      return (
        <Box sx={reviewStyle}>
          <Box height="100%" width="100%">
            {managerStatus ? (
              <TableContainer
                sx={{ border: `1px solid ${theme.palette.primary.light}` }}
                elevation={0}
                component={Paper}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            textDecoration: 'underline',
                            textUnderlineOffset: 3,
                            whiteSpace: 'nowrap'
                          }}>
                          Manager Review Details
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell>
                        {managerStatus?.setterName ? managerStatus.setterName : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Decision</TableCell>
                      <TableCell>
                        {managerStatus?.status ? (
                          <Chip
                            sx={{
                              borderRadius: 0.5,
                              pb: 0,
                              fontSize: 16,
                              ...chipColor(managerStatus?.status)
                            }}
                            label={chipLabel(managerStatus?.status)}
                          />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Review Date</TableCell>
                      <TableCell>
                        {managerStatus?.statusTime ? formatTime(managerStatus?.statusTime) : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, borderBottom: 'none' }}>Comments</TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {managerStatus?.comments ? managerStatus?.comments : '-'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </StyledTable>
              </TableContainer>
            ) : (
              <EmptyStatus
                title="Manager Reviewing"
                icon={<AccessTimeOutlined sx={{ fontSize: 36 }} />}
                content="Waiting Manager Review."
              />
            )}
          </Box>
          {adminStatus || managerStatus?.status === 'managerDenied' ? (
            <Box height="100%" width="100%">
              {renderAdminStatus(
                managerStatus?.status,
                adminStatus?.status,
                <TableContainer
                  sx={{ border: `1px solid ${theme.palette.primary.light}` }}
                  elevation={0}
                  component={Paper}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>
                          <Typography
                            variant="h6"
                            sx={{
                              textDecoration: 'underline',
                              textUnderlineOffset: 3,
                              whiteSpace: 'nowrap'
                            }}>
                            Admin Review Details
                          </Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                        <TableCell>{adminStatus?.setterName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Review Decision</TableCell>
                        <TableCell>
                          <Chip
                            sx={{
                              borderRadius: 0.5,
                              pb: 0,
                              fontSize: 16,
                              ...chipColor(adminStatus?.status)
                            }}
                            label={chipLabel(adminStatus?.status)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Review Date</TableCell>
                        <TableCell>
                          {adminStatus?.statusTime ? formatTime(adminStatus?.statusTime) : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600, borderBottom: 'none' }}>
                          Comments
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }}>
                          {adminStatus?.comments ? adminStatus?.comments : '-'}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </StyledTable>
                </TableContainer>
              )}
            </Box>
          ) : (
            <Box>
              <Typography variant="h6">Review Workflow Details</Typography>
              <Typography variant="body1" mb={2}>
                Use this space to approve/deny the request.
              </Typography>
              <StyledTextField
                onChange={(e) => setComments(e.target.value)}
                value={comments}
                type="text"
                multiline
                rows={6}
                sx={{ width: '100%', mb: 2 }}
                label="Comments"
                id="comments"
              />
              <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
                {!isUpdating && !isRefetching ? (
                  <Button
                    onClick={() => updateStatus('adminDenied', username)}
                    sx={{ borderRadius: 0 }}
                    variant="outlined"
                    color="secondary">
                    Deny
                  </Button>
                ) : null}
                <Button
                  onClick={() => updateStatus('adminApproved', username)}
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: 0 }}
                  color="secondary">
                  {isUpdating || isRefetching ? (
                    <CircularProgress size={22} color="primary" />
                  ) : (
                    'Approve'
                  )}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )
  }
}
